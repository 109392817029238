import { environment } from '@env/environment';
import { Injectable, OnDestroy } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, NavigationEnd } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil, distinctUntilChanged, bufferWhen, delay, filter, map } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';
import { ApolloError } from '@apollo/client/core';
import { gql } from 'apollo-angular';
import * as Sentry from '@sentry/browser';

import { UserService } from '../../auth0/services/user.service';
import { mixpanelEngage, mixpanelEngageVariables } from '../../../generated/mixpanelEngage';
import { mixpanelTrack, mixpanelTrackVariables } from '../../../generated/mixpanelTrack';
import { UserFull } from '../../../generated/UserFull';
import { roleLevel } from './permission.service';
import { ConfigService, EnvironmentConfig } from '../../auth0/services/config.service';

@Injectable({
  providedIn: 'root',
})
export class MixpanelService implements OnDestroy {
  private mixpanel = environment.mixpanel;

  private subscription = new Subject<void>();
  private action$ = new Subject<any>();
  private engagements$ = new Subject<any>();

  constructor(private apollo: Apollo, private userService: UserService, private router: Router, private configService: ConfigService,) {
    this.configService.getConfig().subscribe((config) => {
      userService.currentUser$
      .pipe(
        takeUntil(this.subscription),
        distinctUntilChanged((a, b) => {
          if (a !== undefined && b !== undefined) {
            return a.email === b.email && a.firstName === b.firstName && a.lastName === b.lastName;
          }

          return false;
        })
      )
      .subscribe((user) => {
        if (user) {
          this.identifyUser(user, config);
        }
      });

      if (config.mixpanelEnabled && this.mixpanel.enable) {
        this.initializeService(config);
      }
    });
  }

  ngOnDestroy() {
    this.subscription.next();
    this.subscription.complete();
  }

  public getDefaultData() {
    const now = new Date();
    const executionInfo = {
      time: Math.round(now.getTime() / 1000),
      ExecutedTimestamp: now.toISOString(),
      $browser: window.navigator.userAgent,
      $current_url: window.location.href,
      $screen_height: screen.height,
      $screen_width: screen.width,
      project: 'FAMILY_CONNECTIONS',
      instance: this.configService.getConfigSync().instanceName,
    };

    const user = this.userService.currentUser;

    const userInfo = user
      ? {
          distinct_id: user.email,
          ExecutorID: user.id,
          ExecutorName: `${user.firstName} ${user.lastName}`.trim(),
        }
      : {};

    return { ...executionInfo, ...userInfo };
  }

  public parseError(error: HttpErrorResponse): object {
    if (error.status >= 400 && error.status < 500) {
      return {
        ErrorID: error.status,
        ErrorDescription: JSON.stringify(error.error),
      };
    } else if (error.status >= 500 && error.status < 600) {
      return {
        ErrorID: error.status,
        ErrorDescription: 'Ouch, it looks like you found a bug. If this continues to occur, please contact us.',
      };
    }

    return {};
  }

  public parseGraphQlError(error: ApolloError | Error, otherContext: object = {}): object {
    let context: { [key: string]: string | number } = {
      ...otherContext,
      ErrorMessage: error.message,
    };

    if (error instanceof ApolloError) {
      if (error.graphQLErrors.length > 0) {
        context.graphQlErrors = error.graphQLErrors.map((e) => e.message).join('; ');
      }

      if (error.networkError) {
        const networkError = error.networkError as HttpErrorResponse;
        context = {
          ...context,
          ErrorNetworkStatus: networkError.status,
          ErrorNetworkStatusText: networkError.statusText,
          ErrorNetworkMessage: networkError.message,
        };

        if (networkError.error && networkError.error.errors) {
          context.ErrorNetworkDetail = networkError.error.errors.map((e: any) => e.message).join('; ');
        }

        if (networkError.url) {
          context.ErrorNetworkUrl = networkError.url;
        }
      }
    }

    return context;
  }

  public trackAction(id: string, action: object = {}) {
    this.track(id, { ...action, ...this.getDefaultData() });
  }

  private initializeService(config: EnvironmentConfig): void {
    // https://stackoverflow.com/a/46265666
    // buffer events for 3s before sending, starting timer after receiving first event
    this.engagements$
      .pipe(
        takeUntil(this.subscription),
        bufferWhen(() => this.action$.pipe(delay(3000)))
      )
      .subscribe((engagements) => this.sendEngage(engagements));

    this.action$
      .pipe(
        takeUntil(this.subscription),
        bufferWhen(() => this.action$.pipe(delay(3000)))
      )
      .subscribe((actions) => this.sendTrack(actions));

    // const intercomEnabled = this.configService.getConfigSync().intercomEnabled;
    // if(!intercomEnabled) return;

    if (environment.intercom.enable && config.intercomEnabled) {
      // call intercom update whenever url changes
      // https://www.intercom.com/help/en/articles/170-integrate-intercom-in-a-single-page-app
      // NOTE: rate limits may apply
      // https://developers.intercom.com/installing-intercom/docs/intercom-javascript
      // to reduce number of hits, only send updates on significant URL change
      // replace all ids from the URL with X. also, remove any extra route info /(view-sidebar...)
      this.router.events
        .pipe(
          takeUntil(this.subscription),
          filter((event) => event instanceof NavigationEnd),
          map((event) =>
            (event as NavigationEnd).url
              .replace(/[0-9]+/g, 'X')
              .split('/(')[0]
              .replace(/\/X$/, '')
          ),
          distinctUntilChanged()
        )
        .subscribe((event) => (window as any).Intercom('update', { nonce: Date.now() }));
    }
  }

  private sendTrack(actions: any[]) {

    if (actions.length > 0) {
      this.apollo
        .mutate<mixpanelTrack, mixpanelTrackVariables>({
          mutation: gql`
            mutation mixpanelTrack($actions: JSON!) {
              mixpanelTrack(actions: $actions)
            }
          `,
          variables: { actions },
        })
        .subscribe((result) => {
          if (result.data!.mixpanelTrack !== 1) {
            console.warn(`Failed to track actions`, actions);
          }
        });
    }
  }

  private sendEngage(engagements: any[]) {
    if (engagements.length > 0) {
      this.apollo
        .mutate<mixpanelEngage, mixpanelEngageVariables>({
          mutation: gql`
            mutation mixpanelEngage($userInfo: JSON!) {
              mixpanelEngage(userInfo: $userInfo)
            }
          `,
          variables: { userInfo: engagements },
        })
        .subscribe((result) => {
          if (result.data!.mixpanelEngage !== 1) {
            console.warn(`Failed to engage`, engagements);
          }
        });
    }
  }

  private track(id: string, action: any): void {

    const mixPanelToken = this.configService.getConfigSync().mixpanelToken;

    // iiiiif
    const mixpanelEnabled = this.configService.getConfigSync().mixpanelEnabled;
    if (!mixpanelEnabled || !mixPanelToken) return;

    if (this.mixpanel.enable) {
      this.action$.next({
        event: id,
        properties: {
          token: mixPanelToken,
          ...action,
        },
      });
    }
  }

  public engageAdd(obj: any) {
    this.engage({
      $add: {
        ...obj,
      },
    });
  }

  public engageSet(obj: any) {
    this.engage({
      $set: {
        ...obj,
      },
    });
  }

  public engageSetOnce(obj: any) {
    this.engage({
      $set_once: {
        ...obj,
      },
    });
  }

  private engage(engagement: any): void {

    const mixPanelToken = this.configService.getConfigSync().mixpanelToken;

    if(!mixPanelToken) 
      return;

    if (this.mixpanel.enable) {
      this.engagements$.next({
        $token: mixPanelToken,
        $distinct_id: this.userService.currentUser?.email,
        ...engagement,
      });
    }
  }

  private isIntercomSetup: boolean = false;

  private identifyUser(user: UserFull, config: EnvironmentConfig) {
    // identify user to Sentry.io
    Sentry.setUser({
      id: user.id.toString(),
      username: `${user.firstName} ${user.lastName}`,
      email: user.email,
    });

    // identify user to Intercom
    if (environment.intercom.enable && !this.isIntercomSetup && config.intercomEnabled) {
      // https://developers.intercom.com/installing-intercom/docs/intercom-javascript
      (window as any).Intercom('boot', {
        name: `${user.firstName} ${user.lastName}`,
        email: user.email,
        user_hash: user.emailHash,
        user_id: user.sub,
        created_at: Math.floor(new Date(user.createdAt).valueOf() / 1000),
        avatar: user.picture ?? undefined,
      });

      this.isIntercomSetup = true;
    }

    const mixPanelToken = this.configService.getConfigSync().mixpanelToken;

    // identify user to Mixpanel
    if (mixPanelToken && this.mixpanel.enable && config.mixpanelEnabled) {
      const now = new Date().toISOString();
      const highestRole = user.userTeams.map((u) => u.role).sort((a, b) => roleLevel(b) - roleLevel(a));

      this.engageSet({
        $first_name: user.firstName,
        $last_name: user.lastName,
        $email: user.email,
        $last_login: now,
        $created_at: user.createdAt,
        highest_role: highestRole.length > 0 ? highestRole[0] : 'NO TEAM',
      });

      this.engageSetOnce({
        first_login: now,
      });

      this.engageAdd({
        num_login: 1,
      });
    }
  }

  public addUserCounter(name: string) {
    const now = new Date().toISOString();
    this.engageSet({
      [`last_${name}_time`]: now,
    });

    this.engageSetOnce({
      [`first_${name}_time`]: now,
    });

    this.engageAdd({
      [`num_${name}`]: 1,
    });

    if (environment.intercom?.enable) {
      (window as any).Intercom('trackEvent', name);
    }
  }

  public trackIntercomEvent(name: string): void {
    if (!environment.intercom.enable) {
      return;
    }

    this.configService.getConfig().subscribe((config) => {
      if (config.intercomEnabled) {
        (window as any).Intercom('trackEvent', name);
      }
    });
  }
}
