import { DataProxy } from '@apollo/client/cache';
import { Apollo, gql } from 'apollo-angular';
import { PERSON_BIRTHDAY_FRAGMENT, PERSON_NAME_FRAGMENT } from './person';
import { ADDRESS_DETAIL_FRAGMENT } from './address';

import { RelationshipDetail } from '../../../generated/RelationshipDetail';
import { relationshipsDetailVariables, relationshipsDetail } from '../../../generated/relationshipsDetail';
import { relationshipsCount, relationshipsCountVariables } from '../../../generated/relationshipsCount';
import { relationshipPeopleSearch, relationshipPeopleSearchVariables } from '../../../generated/relationshipPeopleSearch';

export const TELEPHONE_DETAIL = gql`
  fragment TelephoneDetail on PersonTelephone {
    isVerified
    isHidden
    telephone
    label
    dateOfInterest
  }
`;

export const EMAIL_DETAIL = gql`
  fragment EmailDetail on PersonEmail {
    isVerified
    isHidden
    email
    label
    dateOfInterest
  }
`;

export const ALTERNATE_NAME_DETAIL = gql`
  fragment AlternateNameDetail on PersonAlternateName {
    isVerified
    isHidden
    name
    label
  }
`;

export const RELATIONSHIP_DETAIL_FRAGMENT = gql`
  fragment RelationshipDetail on Relationship {
    id
    person {
      id
      ...PersonName
      picture
      gender
      pronouns
      race
      ethnicity
      tribalAffiliation
      identifiesAsLGBTQIAPlus
      isSameSexPartner
      isIdentified
      isLocated
      dateIdentified
      dateLocated
      title
      category
      ...PersonBirthday
      notes
      addresses {
        ...AddressDetail
      }
      telephones {
        ...TelephoneDetail
      }
      emails {
        ...EmailDetail
      }
      alternateNames {
        ...AlternateNameDetail
      }
      dateOfDeath
      dateOfDeathRaw
      isDeceased
      teamAttributes {
        id
        teamAttributeId
        value
      }
    }
    socialMedia {
      id
      url
      type
    }
    case {
      id
      teamId
    }
    jobTitle
    employer
    salaryRange {
      id
      label
    }
    status {
      id
      name
      color
    }
    isContacted
    isKnown
    isRelative
    firstContactedDate
    lastTwoWayContactDate
    statusModifiedDate
    ppSearchCount
    ppSearchImported
    ppSearchClicked
    ppSearchQuery
    ppSearchPointerHash
    accurintUniqueId
    familySearchImported
    familySearchPersonId
    createdAt
  }

  ${PERSON_BIRTHDAY_FRAGMENT}
  ${PERSON_NAME_FRAGMENT}
  ${ADDRESS_DETAIL_FRAGMENT}
  ${TELEPHONE_DETAIL}
  ${EMAIL_DETAIL}
  ${ALTERNATE_NAME_DETAIL}
`;

const RELATIONSHIPS_COUNT = gql`
  query relationshipsCount($caseId: Int!) {
    relationshipsCount(caseId: $caseId)
  }
`;

export const RELATIONSHIPS_DETAIL_QUERY = gql`
  query relationshipsDetail($caseId: Int!) {
    relationships(caseId: $caseId) {
      ...RelationshipDetail
    }
  }

  ${RELATIONSHIP_DETAIL_FRAGMENT}
`;

function updateRelationshipsCount(caseId: number, cache: DataProxy) {
  const relationships = cache.readQuery<relationshipsDetail, relationshipsDetailVariables>({
    query: RELATIONSHIPS_DETAIL_QUERY,
    variables: { caseId },
  });
  if (!relationships) return;

  cache.writeQuery<relationshipsCount, relationshipsCountVariables>({
    query: RELATIONSHIPS_COUNT,
    variables: { caseId },
    data: { relationshipsCount: relationships.relationships.length },
  });
}

export function deleteRelationshipCache(caseId: number, relationship: RelationshipDetail, cache: DataProxy) {
  const relationships = cache.readQuery<relationshipsDetail, relationshipsDetailVariables>({
    query: RELATIONSHIPS_DETAIL_QUERY,
    variables: { caseId },
  });
  if (!relationships) return;
  cache.writeQuery<relationshipsDetail, relationshipsDetailVariables>({
    query: RELATIONSHIPS_DETAIL_QUERY,
    variables: { caseId },
    data: { relationships: relationships.relationships.filter((r) => r.id !== relationship.id) },
  });
  updateRelationshipsCount(caseId, cache);
}

export function addRelationshipCache(caseId: number, relationship: RelationshipDetail, cache: DataProxy) {
  const relationships = cache.readQuery<relationshipsDetail, relationshipsDetailVariables>({
    query: RELATIONSHIPS_DETAIL_QUERY,
    variables: { caseId },
  });
  if (!relationships) return;
  cache.writeQuery<relationshipsDetail, relationshipsDetailVariables>({
    query: RELATIONSHIPS_DETAIL_QUERY,
    variables: { caseId },
    data: { relationships: [relationship, ...relationships.relationships] },
  });
  updateRelationshipsCount(caseId, cache);
}

export function performPeopleSearch(apollo: Apollo, caseId: number, relationshipId: number) {
  apollo
    .mutate<relationshipPeopleSearch, relationshipPeopleSearchVariables>({
      mutation: gql`
        mutation relationshipPeopleSearch($caseId: Int!, $relationshipId: Int!) {
          relationshipPeopleSearch(caseId: $caseId, relationshipId: $relationshipId) {
            ...RelationshipDetail
          }
        }
        ${RELATIONSHIP_DETAIL_FRAGMENT}
      `,
      variables: { caseId, relationshipId },
    })
    .subscribe();
}
