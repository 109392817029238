import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  Component,
  forwardRef,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  OnInit,
  HostListener,
  ChangeDetectorRef,
  ElementRef,
} from '@angular/core';
import { BsDatepickerConfig, BsDatepickerDirective } from 'ngx-bootstrap/datepicker';
import * as moment from 'moment';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'input-imprecise-date-control',
  templateUrl: './input-imprecise-date-control.component.html',
  styleUrls: ['./input-imprecise-date-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputImpreciseDateControlComponent),
      multi: true,
    },
  ],
})
export class InputImpreciseDateControlComponent implements ControlValueAccessor, OnInit {
  public faCalendarAlt = faCalendarAlt;
  public disabled: boolean = false;
  private onChangeFn: any = (v: any) => {
    this.displayValue = v;
  };
  private onTouchedFn: any = (_: any) => { };
  public dateConfig = new BsDatepickerConfig();

  @ViewChild('displayDateElement')
  inputBox: ElementRef;

  @ViewChild(BsDatepickerDirective)
  datePicker: BsDatepickerDirective;

  private value: string = '';
  public bsDatePickerValue: Date | undefined = undefined;
  public exactDate: boolean = true;
  public valueMoment: moment.Moment;
  public formatErrors: boolean = false;

  @Input()
  placeholder: string = '';

  @Input()
  adaptivePosition: boolean = false;

  @Input()
  minDate?: Date;

  @Input()
  maxDate?: Date;

  @Output()
  onValueChanged = new EventEmitter<any>();

  @Output()
  onBlur = new EventEmitter<any>();

  @Output()
  onError = new EventEmitter<boolean>();

  valid: boolean = false;
  constructor(private cdRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.dateConfig.minDate = this.minDate;
    this.dateConfig.maxDate = this.maxDate;
    this.dateConfig.showWeekNumbers = false;
    this.dateConfig.adaptivePosition = this.adaptivePosition;
  }

  public isDateExact(dateRaw: string | null | undefined): boolean {
    let count = 0;
    for (const char of dateRaw || '')
      if (char === '/') count++;
    return count === 2 ? true : false;
  }

  /**
   * Close the date picker calendar
   */
  public closeCalendar() {
    this.datePicker.hide();
  }

  // Close date picker calendar when page scrolls
  @HostListener('window:scroll')
  onScrollEvent() {
    this.datePicker.hide();
  }

  registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedFn = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: any) {
    if (typeof obj === 'string') {
      this.setDate(obj);
    } else {
      this.setDate('');
    }
  }

  public blur($event: any): void {
    this.onTouchedFn($event);
    this.onBlur.emit($event);
  }

  public inputFocus($event: any) {
    this.datePicker.hide();
    this.onTouchedFn($event);
  }

  public bsDateValueChange(date: Date | string | undefined) {
    if (date instanceof Date) {
      this.displayValue = moment(date).format('MM/DD/YYYY');

      this.onChangeFn(this.displayValue);
      this.onValueChanged.emit(this.displayValue);
    }
  }

  public onClickOutside() {
    this.datePicker.hide();
  }

  public inputTabKeydown() {
    this.datePicker.hide();
  }

  private regexMonth: RegExp = /^\s*\d{2}$/;
  private regexMonthAndDay: RegExp = /^\s*\d{1,2}\/\d{2}$/;

  public inputChanged($event: any) {
    // add slashes to input automatically only if "exact date" is selected
    let input = $event.target.value as string;
    if (this.exactDate && (this.regexMonth.test(input) || this.regexMonthAndDay.test(input)) && input.length > this.displayValue.length) {
      input += '/';
    }
    // remove multiple slashes in a row
    input = input.replace(/\/{2,}/g, '/');

    let currentCarrotPosition = undefined;
    if (this.displayValue === input) {
      // get current carrot position
      currentCarrotPosition = InputImpreciseDateControlComponent.getCaretPosition(this.inputBox.nativeElement as HTMLInputElement);

      // this is a hack so that the display updates
      this.displayValue = 'U+0000' + input;
      this.onChangeFn(this.displayValue);
      this.onValueChanged.emit(this.displayValue);
      // detectChanges is necessary. do not remove
      this.cdRef.detectChanges();
    }

    this.setDate(input, currentCarrotPosition);
    this.emitFormatError();
  }

  private setDate(dateStr: string, currentCarrotPosition: number | undefined = undefined) {
    this.displayValue = dateStr.slice();
    // update value in BSDatePicker (if it is formatted properly)
    const date = moment(
      this.displayValue,
      ['MM/DD/YYYY', 'M/DD/YYYY', 'M/D/YYYY', 'MM/D/YYYY', 'MM-DD-YYYY', 'M-DD-YYYY', 'M-D-YYYY', 'MM-D-YYYY'],
      true
    );
    this.bsDatePickerValue = date.isValid() ? date.toDate() : undefined;

    this.onChangeFn(this.displayValue);
    this.onValueChanged.emit(this.displayValue);
    this.cdRef.detectChanges();

    // need to use a setTimeout here so this carrot position change happens
    // after the input value is updated
    setTimeout(() => {
      // after we update the control, the cursor moves to the end
      // we want to keep it in the same place before we updated the value
      if (currentCarrotPosition !== undefined) {
        InputImpreciseDateControlComponent.setCaretPosition(this.inputBox.nativeElement as HTMLInputElement, currentCarrotPosition - 1);
      }
    });
  }

  private static setCaretPosition(ctrl: HTMLInputElement, pos: number) {
    ctrl.focus();
    ctrl.setSelectionRange(pos, pos);
  }

  private static getCaretPosition(ctrl: HTMLInputElement): number | undefined {
    let startPos = ctrl.selectionStart;
    let endPos = ctrl.selectionEnd;
    return startPos !== null && startPos === endPos ? endPos : undefined;
  }

  public get displayValue(): string {
    return this.value;
  }

  public set displayValue(value: string) {
    this.value = value;
    this.exactDate = this.isDateExact(value);
    this.valueMoment = moment(value);
  }

  public isInvalid(): boolean {
    return this.isOldDate() || this.isFutureDate() || this.isInvalidDate();
  }

  public isOldDate(): boolean {
    if (!this.minDate) return false;
    return this.valueMoment.toDate() < this.minDate;
  }

  public isFutureDate(): boolean {
    if (!this.maxDate) return false;
    return this.valueMoment.toDate() > this.maxDate;
  }

  public isInvalidDate(): boolean {
    if (!this.valueMoment.isValid()) return true;
    // year could be empty at this point (e.g. "06/13/")
    return this.value.endsWith("/") || this.value.split("/").length !== 3;
  }

  public emitFormatError() {
    this.formatErrors = this.exactDate && this.isInvalid();
    this.onError.emit(this.formatErrors);
  }
}
