import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { dashboardTeamReports_dashboardCaseReportsActivity } from '../../../generated/dashboardTeamReports';
import { getFullName } from '../../shared/utilities/strings';
import { getCaseStatusPercent } from '../../reports/components/activity/activity-item/activity-person.component';

@Component({
  selector: 'cok-case-activity-item',
  templateUrl: './case-activity-item.component.html',
  styleUrls: ['./case-activity-item.component.scss'],
})
export class CaseActivityItemComponent implements OnInit{
  public readonly DEFAULT_AVATAR_IMAGE = "../../assets/images/case-main-logo.svg";

  @Input()
  public case: dashboardTeamReports_dashboardCaseReportsActivity;

  public caseFullName: string;
  public caseStatusPercentage: number;

  constructor(private router: Router) {}

  ngOnInit() {
    this.caseFullName = getFullName({
      firstName: this.case.caseFirstName,
      middleName: this.case.caseMiddleName,
      lastName: this.case.caseLastName,
    });
    this.caseStatusPercentage = getCaseStatusPercent(this.case.caseStatus);
  }

  public getWidth(value: number) {
    if (this.case.allActivity === 0 || value === 0) return 0;

    return (value / this.case.allActivity) * 100;
  }

  public navigateTo(caseId: number, relationshipId: number) {
    this.router.navigate(['cases', caseId, 'connections', { outlets: { sidebar: ['view-person', relationshipId] } }], {});
  }

}
