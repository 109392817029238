import { gql } from 'apollo-angular';
import { AddressDetail } from '../../../generated/AddressDetail';
import { AddressInput } from '../../../generated/globalTypes';

export const ADDRESS_DETAIL_FRAGMENT = gql`
  fragment AddressDetail on PersonAddress {
    isVerified
    isHidden
    country
    countryCode
    latitude
    locality
    longitude
    postalCode
    raw
    route
    routeTwo
    state
    stateCode
    streetNumber
    label
    dateOfInterest
    verifiedDate
  }
`;

export function emptyAddressDetail(): AddressDetail {
  return {
    __typename: 'PersonAddress',
    isVerified: false,
    isHidden: false,
    raw: null,
    locality: '',
    country: null,
    countryCode: null,
    postalCode: null,
    route: '',
    routeTwo: null,
    state: null,
    stateCode: null,
    streetNumber: '',
    latitude: null,
    longitude: null,
    label: null,
    dateOfInterest: null,
    verifiedDate: null,
  };
}

export function toAddressInput(address: AddressDetail): AddressInput {
  const { ...output } = address as Record<string, any>;
  if ("is_verified" in address) {
    output.isVerified = output.is_verified;
    delete output.is_verified;
  }
  if ("is_hidden" in address) {
    output.isHidden = output.is_hidden;
    delete output.is_hidden;
  }
  if ("street_number" in address) {
    output.streetNumber = output.street_number;
    delete output.street_number;
  }
  if ("postal_code" in address) {
    output.postalCode = output.postal_code;
    delete output.postal_code;
  }
  if ("state_code" in address) {
    output.stateCode = output.state_code;
    delete output.state_code;
  }
  if ("country_code" in address) {
    output.countryCode = output.country_code;
    delete output.country_code;
  }
  delete output.__typename;
  return output as AddressInput;
}

export function translateAddress(place: google.maps.GeocoderResult | google.maps.places.PlaceResult): AddressDetail {
  let address: AddressDetail = {
    ...emptyAddressDetail(),
    raw: place.formatted_address || null,
  };

  if (place.address_components) {
    const address_components = new Map<string, string>();
    for (let component of place.address_components) {
      for (let type of component.types) {
        address_components.set(type, component.long_name);
        address_components.set(type + '_short', component.short_name);
      }
    }

    address = {
      ...address,
      streetNumber: address_components.get('street_number') || '',
      route: address_components.get('route') || '',
      locality: address_components.get('locality') || '',
      postalCode: address_components.get('postal_code') || null,
      state: address_components.get('administrative_area_level_1') || null,
      stateCode: address_components.get('administrative_area_level_1_short') || null,
      country: address_components.get('country') || null,
      countryCode: address_components.get('country_short') || null,
    };

    if (address.country && address.state && !address.locality) {
      address.locality = address_components.get('administrative_area_level_2') || '';
    }
  }

  if (place.geometry) {
    address = {
      ...address,
      latitude: place.geometry.location.lat(),
      longitude: place.geometry.location.lng(),
    };
  }

  return address;
}
