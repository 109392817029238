import { Pipe, PipeTransform } from '@angular/core';
@Pipe({ name: 'textarea' })
export class TextAreaPipe implements PipeTransform {
  transform(value: string | null | undefined) {
    if (value) {
      return value.replace(/(?:\\[rn]|[\r\n]+)+/g, '<br/>');
    } else {
      return value;
    }
  }
}
