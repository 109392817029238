import { NgModule } from '@angular/core';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { ApolloLink, InMemoryCache } from '@apollo/client/core';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from '@apollo/client/link/context';
import { AuthService } from './auth0/services/auth.service';

// generated by Fragment Matcher plugin
import generatedIntrospection from '../generated/fragmentTypes';
import { REPOSITORY_VERSION } from '../generated/version';

// inject jwt into graphql request
function injectJwtLink(AuthService: AuthService): ApolloLink {
  return setContext(async (_, { headers }) => {
    const token = await AuthService.token;
    return {
      headers: {
        ...headers,
        Authorization: `Bearer ${token.accessToken}`,
      },
    };
  });
}

const uri = `/graphql/`;
export function createApollo(AuthService: AuthService) {
  return {
    link: ApolloLink.from([injectJwtLink(AuthService), createUploadLink({ uri })]),
    cache: new InMemoryCache({
      possibleTypes: generatedIntrospection.possibleTypes,
      typePolicies: {
        Query: {
          fields: {
            workpad(_, { args, toReference }) {
              return toReference({ __typename: 'CaseWorkpad', id: args?.workpadId });
            },
            case(_, { args, toReference }) {
              return toReference({ __typename: 'Case', id: args?.caseId });
            },
            relationship(_, { args, toReference }) {
              return toReference({ __typename: 'Relationship', id: args?.id });
            },
          },
        },
      },
    }),
    name: 'web',
    version: REPOSITORY_VERSION,
  };
}

@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [AuthService],
    },
  ],
})
export class GraphQLModule {}
