import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AnonymousGuard } from '../auth0/guards/anonymous.guard';
import { VerifyEmailComponent } from './verify-email.component';

const routes: Routes = [
  {
    path: 'verify-email',
    canActivate: [
    ],
    component: VerifyEmailComponent

  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)]})
export class ErrorRoutingModule { }
