import { Injectable, Type } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface SidebarInfo {
  type: Type<any>;
  data?: any;
}

@Injectable()
export class SidebarService {
  private _sidebar$ = new BehaviorSubject<SidebarInfo | undefined>(undefined);
  public get sidebar$(): Observable<SidebarInfo | undefined> {
    return this._sidebar$;
  }

  public get isSidebarShown$(): Observable<boolean> {
    return this._sidebar$.pipe(map((s) => s !== undefined));
  }

  public showSidebar(sidebarInfo: SidebarInfo) {
    this._sidebar$.next(sidebarInfo);
  }

  public closeSidebar() {
    this._sidebar$.next(undefined);
  }
}
