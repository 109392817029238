import { Component } from "@angular/core"
import { BillingBundleNotificationType, BillingBundleNotificationData } from "../../services/bundle-notifcation.service";
import { CreditType } from "../../../../generated/globalTypes";
import { Router } from "@angular/router";
import { Toast, ToastPackage, ToastrService } from "ngx-toastr";
import { ModalService } from "../../services/modal.service";

@Component({
  selector: 'bundle-expiration-notification',
  templateUrl: './bundle-expiration-notification.component.html',
  styleUrls: ['./bundle-expiration-notification.component.scss']
})
export class BundleExpirationNotificationComponent extends Toast {
  public readonly BillingBundleNotificationType = BillingBundleNotificationType;

  public notification: BillingBundleNotificationData;
  public hideBundleNotification: boolean;

  constructor(
    public toastPackage: ToastPackage,
    protected toastService: ToastrService,
    private modalService: ModalService,
    private router: Router
  ) {
    super(toastService, toastPackage);
    this.notification = toastPackage.config.payload;
  }

  public get bundleIsEmptyText(): string {
    const accountName = this.notification.accountType == CreditType.BILLING_ACCOUNT ? `the billing account <b>${this.notification.accountName}</b>` : `your ${this.notification.accountName.toLowerCase()} ⁠`;
    return `There are no more credits left in ${accountName}.`;
  }

  public get bundleWillExpireInAWeekText(): string {
    const accountName = this.notification.accountType == CreditType.BILLING_ACCOUNT ? `the billing account <b>${this.notification.accountName}</b>` : `<b>${this.notification.accountName}</b>`;

    if (this.notification.amountOfNotifications > 1) {
      return `${this.notification.amountOfNotifications} bundles in ${accountName} will expire in less than week.`;
    }

    return `A bundle in ${accountName} will expire in less than a week.`;
  }

  public get bundleExpiredText(): string {
    const accountName = this.notification.accountType == CreditType.BILLING_ACCOUNT ? `the billing account <b>${this.notification.accountName}</b>` : ` your ${this.notification.accountName.toLowerCase()}`;

    if (this.notification.amountOfNotifications > 1) {
      return `${this.notification.amountOfNotifications} bundles in ${accountName} have expired.`;
    }

    return `A bundle in ${accountName} has expired.`;
  }

  public hideNotification(): void {
    this.toastService.clear(this.toastPackage.toastId);
  }

  public gotToBundles(): void {
    if (this.notification.accountType == CreditType.BILLING_ACCOUNT) {
      this.router.navigate(['billing', 'team'], { queryParams: { teamId: this.notification.teamId, tab: 'bundles' } });
    } else {
      this.router.navigate(['billing', 'personal']);
    }

    this.hideNotification();
    this.closeDialogs();
  }

  private closeDialogs(): void {
    this.modalService.closeDialogs();
  }
}
