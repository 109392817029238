import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { QuillEditorComponent } from 'ngx-quill';
import 'quill-mention';

@Component({
  selector: 'cok-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TextEditorComponent implements OnInit {

  @ViewChild(QuillEditorComponent, { static: true })
  public editor: QuillEditorComponent;

  @Input()
  public control: FormControl;

  @Input()
  public mentionValues: string[] = [];

  public modules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ size: [] }],
      [{ indent: '-1' }, { indent: '+1' }],
      [{ color: [] }, { background: [] }],
      ['link'],
    ],
    mention: {
      positioningStrategy: 'normal',
      showDenotationChar: false,
      onSelect: (item: any, insertItem: any) => {
        const editor = this.editor.quillEditor;
        insertItem(item);
        // necessary because quill-mention triggers changes as 'api' instead of 'user'
        editor.insertText(editor.getLength() - 1, '', 'user');
      },
      source: (searchTerm: any, renderList: any) => {
        const values = this.mentionValues.map((value, id) => {
          return { id, value };
        });

        if (searchTerm.length === 0) {
          renderList(values, searchTerm);
        } else {
          const matches: any = [];

          values.forEach((entry) => {
            if (entry.value.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1) {
              matches.push(entry);
            }
          });
          renderList(matches, searchTerm);
        }
      },
    },
  };

  ngOnInit(): void {
    this.control = this.control ?? new FormControl();
  }
}
