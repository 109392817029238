import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BehaviorSubject, lastValueFrom, Subject } from 'rxjs';
import { DirtyConfirmationModalComponent } from '../components/sidebar/dirty-confirmation-modal.component';

@Injectable()
export class IsSidebarDirtyService {
  public formDiscarded = new Subject<boolean | undefined>();

  public importedDetails : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private ngxModalService: BsModalService, private router: Router) {}

  private _isSidebarDirty: () => boolean = () => false;
  public get isSidebarDirty() {
    return this._isSidebarDirty;
  }

  // should only be set by the sidebar component
  public set isSidebarDirty(fn: () => boolean) {
    this._isSidebarDirty = fn;
  }

  public checkIfDirty(onClean: () => void, nextState?: RouterStateSnapshot): boolean {
    const isDirty = this._isSidebarDirty();
    if (!isDirty) {
      onClean();
    } else {
      // dirty, open modal
      const modalRef = this.ngxModalService.show(DirtyConfirmationModalComponent, {
        class: 'modal-sm modal-dialog-centered',
      });

      // get result
      lastValueFrom(modalRef.content!.onClose).then((shouldDiscard: boolean) => {
        if (shouldDiscard) {
          this.formDiscarded.next(true);
          onClean();
          if (nextState) {
            this.router.navigateByUrl(nextState.url);
          }
        }
      });
    }

    return isDirty;
  }
}
