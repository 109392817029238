<div class="modal-header">
  <h5 class="modal-title">Our Awesome Partners</h5>
  <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close" (click)="close()">
  </button>
</div>
<div class="modal-body">
  Family Connections is made possible by the donations and support of our partners. Learn about all of our
  <a href="https://connectourkids.org/our-partners" target="_blank">partners on our website</a>.
  <br />
  <br />
  <div class="container">
    <div class="row">
      <div class="col-6">
        <a href="https://www.yworks.com" target="_blank"
          ><img src="/assets/images/sponsors/yfiles-logo.png" class="sponsor-image yfiles-logo"
        /></a>
      </div>
      <div class="col-6">
        <a href="https://cloud.google.com" target="_blank"><img src="/assets/images/sponsors/google-cloud.png" class="sponsor-image" /></a>
      </div>
    </div>
  </div>
  <br />
  <br />
</div>
<div class="modal-footer">
  <button class="btn btn-secondary" type="button" (click)="close()">Close</button>
</div>
