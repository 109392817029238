import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'cok-authorize',
  template: `
    <div class="loading-wrapper">
      <div class="spinner-svg"></div>
    </div>
  `,
  styles: [],
})
export class AuthorizeComponent implements OnInit {
  constructor(private AuthService: AuthService) {}

  ngOnInit() {
    this.AuthService.handleAuthentication();
  }
}
