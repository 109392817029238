import { Injectable } from '@angular/core';

declare const SmartBanner: any;

@Injectable({
  providedIn: 'root'
})
export class SmartBannerService {
  constructor() {
  }

  public initSmartBanners(): void {
    new SmartBanner({
      daysHidden: 10,
      daysReminder: 20
    });
  }
}
