import { Injectable } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { gql } from 'apollo-angular';
import { map, switchMap, take } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';

import { UserService, USERS_QUERY } from '../../../app/auth0/services/user.service';

import { UserFull_userTeams } from '../../../generated/UserFull';
import {
  CreateTeamAttributeInput,
  UpdateTeamAttributeInput,
} from '../../../generated/globalTypes';
import { getTeamAttributes, getTeamAttributesVariables, getTeamAttributes_teamAttributes } from '../../../generated/getTeamAttributes';
import {
  createTeamAttribute,
  createTeamAttributeVariables,
  createTeamAttribute_createTeamAttribute,
} from '../../../generated/createTeamAttribute';
import { updateTeamAttribute, updateTeamAttributeVariables } from '../../../generated/updateTeamAttribute';

import { TEAM_ATTRIBUTE_DETAIL } from '../fragments/teamAttribute';
import { TeamAttributeDetail } from '../../../generated/TeamAttributeDetail';

import { getUsers, getUsersVariables, getUsers_users } from '../../../generated/getUsers';
import { TEAM_ID_STORAGE_KEY } from '../../shared/constants/storage-keys';
import { cloneDeep } from 'lodash';

export const TEAM_ATTRIBUTES_QUERY = gql`
  query getTeamAttributes($teamId: Int!) {
    teamAttributes(teamId: $teamId) {
      ...TeamAttributeDetail
    }
  }

  ${TEAM_ATTRIBUTE_DETAIL}
`;

function findSidebar(route: ActivatedRoute): ActivatedRoute | undefined {
  if (route.outlet === 'sidebar') return route;

  for (let child of route.children) {
    const sidebar = findSidebar(child);
    if (sidebar) return sidebar;
  }

  return undefined;
}

function findTeamId(route: ActivatedRoute): ActivatedRoute | undefined {
  if (route.outlet === 'primary' && route.snapshot.params && route.snapshot.params.team) return route;

  for (let child of route.children) {
    const childOutlet = findTeamId(child);
    if (childOutlet && childOutlet.snapshot.params && childOutlet.snapshot.params.team) return childOutlet;
  }

  return undefined;
}

@Injectable({
  providedIn: 'root',
})
export class TeamService {
  public readonly attributeTypes: any = {
    shortText: { label: 'Short Text', value: 'shortText' },
    longText: { label: 'Long Text', value: 'longText' },
    boolean: { label: 'Checkbox', value: 'boolean' },
    radioButtons: { label: 'Radio Buttons', value: 'radioButtons' },
    selectList: { label: 'Select list', value: 'selectList' },
    multiSelectList: { label: 'Multiple select list', value: 'multiSelectList' }, // The value contains multiple strings joint by ';;;'
    date: { label: 'Date', value: 'date' },
  };

  constructor(private router: Router, private apollo: Apollo, private userService: UserService) {
    // get id from sidebar outlet
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const sidebar = findSidebar(this.router.routerState.root);
        const params = sidebar?.snapshot.params;
        this._selectedUserId = params ? +params.id : undefined;

        const primary = findTeamId(this.router.routerState.root);
        this.teamId = primary ? +primary.snapshot.params.team : undefined;
      }
    });
  }

  public currentTeam$: BehaviorSubject<UserFull_userTeams | undefined> = new BehaviorSubject<UserFull_userTeams | undefined>(undefined);

  private _selectedUserId: number | undefined;
  public get selectedUserId() {
    return this._selectedUserId;
  }

  private set teamId(teamId: number | undefined) {
    if (teamId) {
      const team = this.userService.currentUser?.userTeams.find((t) => t.team.id === teamId);
      if (team) {
        this.setStoredTeamId(teamId);
      }

      this.currentTeam$.next(team);
    }
  }

  public setCurrentTeamId(teamId: number): void {
    this.teamId = teamId;
  }

  public getCurrentTeamId(): number | null {
    if (this.teamId) {
      return this.teamId;
    }

    const teamId = localStorage.getItem(TEAM_ID_STORAGE_KEY);
    if (teamId) {
      return parseInt(teamId);
    }

    return null;
  }

  public getCurrentTeamUsers(): Observable<getUsers_users[]> {
    return this.currentTeam$.pipe(
      take(1),
      switchMap((team) => {
        if (!team) {
          return of([]);
        }

        return this.apollo
          .query<getUsers, getUsersVariables>({ query: USERS_QUERY, variables: { teamId: team.id } })
          .pipe(map((result) => result.data.users));
      })
    );
  }

  public getTeamUsers(teamId: number): Observable<getUsers_users[]> {
    if (!teamId) {
      return of([]);
    }

    return this.apollo
      .query<getUsers, getUsersVariables>({ query: USERS_QUERY, variables: { teamId } })
      .pipe(map((result) => result.data.users));
  }

  public getTeamAttributes(teamId: number): Observable<getTeamAttributes_teamAttributes[]> {
    return this.apollo
      .watchQuery<getTeamAttributes, getTeamAttributesVariables>({
        query: TEAM_ATTRIBUTES_QUERY,
        variables: { teamId },
      })
      .valueChanges.pipe(map((result) => cloneDeep(result.data.teamAttributes)));
  }

  public createTeamAttribute(teamId: number, value: CreateTeamAttributeInput): Observable<createTeamAttribute_createTeamAttribute> {
    return this.apollo
      .mutate<createTeamAttribute, createTeamAttributeVariables>({
        mutation: gql`
          mutation createTeamAttribute($teamId: Int!, $value: CreateTeamAttributeInput!) {
            createTeamAttribute(teamId: $teamId, value: $value) {
              ...TeamAttributeDetail
            }
          }

          ${TEAM_ATTRIBUTE_DETAIL}
        `,
        variables: { teamId, value },
        update: (cache, result) => {
          if (!result.data) return;
          const teamAttributes = cache.readQuery<getTeamAttributes, getTeamAttributesVariables>({
            query: TEAM_ATTRIBUTES_QUERY,
            variables: { teamId },
          });

          if (!teamAttributes) return;

          cache.writeQuery<getTeamAttributes, getTeamAttributesVariables>({
            query: TEAM_ATTRIBUTES_QUERY,
            variables: { teamId },
            data: { teamAttributes: [...teamAttributes.teamAttributes, result.data.createTeamAttribute] },
          });
        },
      })
      .pipe(map((result) => result.data!.createTeamAttribute));
  }

  public editTeamAttribute(teamId: number, teamAttributeId: number, value: UpdateTeamAttributeInput): Observable<TeamAttributeDetail> {
    return this.apollo
      .mutate<updateTeamAttribute, updateTeamAttributeVariables>({
        mutation: gql`
          mutation updateTeamAttribute($teamId: Int!, $teamAttributeId: Int!, $value: UpdateTeamAttributeInput!) {
            updateTeamAttribute(teamId: $teamId, id: $teamAttributeId, value: $value) {
              ...TeamAttributeDetail
            }
          }

          ${TEAM_ATTRIBUTE_DETAIL}
        `,
        variables: { teamId, teamAttributeId, value },
        update: (cache, result) => {
          if (!result.data) return;
          const teamAttributes = cache.readQuery<getTeamAttributes, getTeamAttributesVariables>({
            query: TEAM_ATTRIBUTES_QUERY,
            variables: { teamId },
          });

          if (!teamAttributes) return;

          cache.writeQuery<getTeamAttributes, getTeamAttributesVariables>({
            query: TEAM_ATTRIBUTES_QUERY,
            variables: { teamId },
            data: {
              teamAttributes: [
                ...teamAttributes.teamAttributes.map((attr) => {
                  if (attr.id === result.data!.updateTeamAttribute.id) {
                    return { ...attr, ...result.data?.updateTeamAttribute };
                  }

                  return attr;
                }),
              ],
            },
          });
        },
      })
      .pipe(map((result) => result.data!.updateTeamAttribute));
  }

  public deleteTeamAttribute(teamId: number, teamAttributeId: number): Observable<TeamAttributeDetail> {
    return this.apollo
      .mutate<any, any>({
        mutation: gql`
          mutation deleteTeamAttribute($teamId: Int!, $teamAttributeId: Int!) {
            deleteTeamAttribute(teamId: $teamId, id: $teamAttributeId) {
              ...TeamAttributeDetail
            }
          }

          ${TEAM_ATTRIBUTE_DETAIL}
        `,
        variables: { teamId, teamAttributeId },
        update: (cache, result) => {
          if (!result.data) return;
          const teamAttributes = cache.readQuery<getTeamAttributes, getTeamAttributesVariables>({
            query: TEAM_ATTRIBUTES_QUERY,
            variables: { teamId },
          });
          if (!teamAttributes) return;
          cache.writeQuery<getTeamAttributes, getTeamAttributesVariables>({
            query: TEAM_ATTRIBUTES_QUERY,
            variables: { teamId },
            data: { teamAttributes: teamAttributes.teamAttributes.filter((item) => item.id !== result.data.deleteTeamAttribute.id) },
          });
        },
      })
      .pipe(map((result) => result.data.deleteTeamAttribute));
  }

  private setStoredTeamId(teamId: number): void {
    localStorage.setItem(TEAM_ID_STORAGE_KEY, teamId.toString());
  }
}
