import { AbstractControl, FormGroup, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';
import * as _ from 'lodash';

export class Validator {
  public static required(control: AbstractControl) {
    if (
      !control.value ||
      (control.value && control.value instanceof Date && control.value.toString() === 'Invalid Date') ||
      (control.value &&
        !(control.value instanceof Date) &&
        !Array.isArray(control.value) &&
        !(typeof control.value === 'number') &&
        (control.value instanceof String && (control.value === '' || control.value.trim() === '')))
    ) {
      return { required: true };
    }
    return null;
  }

  private static dateFormats = ['MM/DD/YYYY', 'M/DD/YYYY', 'M/D/YYYY', 'MM/D/YYYY'];

  public static minDate(minDate: Date) {
    return (control: AbstractControl) => {
      let userInputtedMoment: moment.Moment | undefined = undefined;

      if (control.value) {
        if (typeof control.value === 'string') {
          userInputtedMoment = moment(control.value, Validator.dateFormats, true);
        } else if (control.value instanceof Date) {
          userInputtedMoment = moment(control.value);
        }

        if (userInputtedMoment && userInputtedMoment.isValid()) {
          let minDateMoment = moment(minDate);
          return minDateMoment.diff(userInputtedMoment, 'days') > 0 ? { minDate: true, expectedMinDate: minDate } : null;
        }
      }
      return null;
    };
  }

  public static maxDate(maxDate: Date, dateTime: boolean = false) {
    return (control: AbstractControl) => {
      let userInputtedMoment: moment.Moment | undefined = undefined;

      if (control.value) {
        if (typeof control.value === 'string') {
          userInputtedMoment = moment(control.value, Validator.dateFormats, true);
        } else if (control.value instanceof Date) {
          userInputtedMoment = moment(control.value);
        }

        if (userInputtedMoment && userInputtedMoment.isValid()) {
          let maxDateMoment = moment(maxDate);
          return maxDateMoment.diff(userInputtedMoment, dateTime ? 'minutes' : 'days') < 0
            ? { maxDate: true, expectedMaxDate: maxDate }
            : null;
        }
      }
      return null;
    };
  }

  public static maxLength(numb: number): ValidatorFn {
    return (control: AbstractControl) => {
      if (control.value && control.value.length >= numb + 1) {
        return {
          maxLength: {
            expectedLength: numb,
            actualLength: control.value.length,
          },
        };
      }
      return null;
    };
  }

  public static minLength(numb: number): ValidatorFn {
    return (control: AbstractControl) => {
      if (control.value && control.value.length <= numb - 1) {
        return {
          minLength: {
            actualLength: control.value.length,
            expectedLength: numb,
          },
        };
      }
      return null;
    };
  }

  public static notAllowed(notAllowed: string[]): ValidatorFn {
    return (control: AbstractControl) => {
      const valueAsStr = control.value?.toString().trim() ?? '';
      if (valueAsStr && notAllowed.find((value) => value === valueAsStr)) {
        return { notAllowed: true };
      }

      return null;
    };
  }

  public static pattern(pattern: any): ValidatorFn {
    return (control: AbstractControl) => {
      const regex = new RegExp(pattern);
      if (control.value && !regex.test(control.value)) {
        return { pattern: true };
      }
      return null;
    };
  }

  public static validChildUrlIfIsSelectedInItsSibling(control: FormGroup) {
    const isSelected = control.value.isSelected;

    if (isSelected) {
      return Validator.url(control.controls.val);
    }
  }

  public static url(control: AbstractControl) {
    try {
      if (!control.value) {
        return { url: true };
      }

      new URL(control.value);
      return null;
    } catch (_err: any) {
      return { url: true };
    }
  }

  // https://github.com/angular/angular/blob/13ae2d72717ca204df8ea743155676862bba36df/packages/forms/src/validators.ts#L93
  public static EMAIL_REGEX = /^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

  public static email(control: AbstractControl) {
    if (control.value) {
      let value = control.value.trim();
      if (value && !Validator.EMAIL_REGEX.test(value)) {
        return { email: true };
      }
    }
    return null;
  }

  public static date(control: AbstractControl) {
    const value = control.value;
    const date = value && moment(value, 'MM/DD/YYYY').format('MM/DD/YYYY');
    if (date === 'Invalid date') {
      return { date: true };
    }
    return null;
  }

  public static mismatch(controlName: string): ValidatorFn {
    return (control: AbstractControl) => {
      if (control.parent) {
        const targetControl: any = control.parent.get(controlName);
        if (targetControl.value) {
          if (targetControl.value === control.value) {
            return null;
          } else {
            return { mismatch: true };
          }
        }
      }
      return null;
    };
  }

  public static googleAddress(control: AbstractControl) {
    const value = control.value;
    if (!value) return null;

    if (_.isString(value) || !(value === Object(value))) {
      return {
        googleAddress: true,
      };
    }

    return null;
  }
}
