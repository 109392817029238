<div class="modal-header">
  <h5 class="modal-title">Mobile App</h5>
  <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close" (click)="close()">
  </button>
</div>
<div class="modal-body container">
  Get the mobile app on the iOS App Store and on Google Play.
  <br />
  <br />
  <div class="badge-container">
    <div class="link-container">
      <a href="https://apps.apple.com/us/app/connect-our-kids/id1471038972?mt=8" target="_blank"
        ><img
          class="app-store-badge"
          src="https://linkmaker.itunes.apple.com/en-us/badge-lrg.svg?releaseDate=2020-05-02&kind=iossoftware&bubble=ios_apps"
        />
      </a>
    </div>
    <div class="link-container">
      <a href="https://play.google.com/store/apps/details?id=com.connectOurKids" target="_blank"
        ><img class="google-play-badge" src="https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png" />
      </a>
    </div>
    <br />
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-secondary" type="button" (click)="close()">Close</button>
</div>
