import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ActiveToast, IndividualConfig, ToastrService } from 'ngx-toastr'

@Injectable({ providedIn: 'root' })
export class NotificationService {
  constructor(private toastr: ToastrService,
    private router: Router) { }

  public showSuccess(message: string, title: string): ActiveToast<any> {
    return this.toastr.success(message, title);
  }

  public showWarning(message: string, title: string): ActiveToast<any> {
    return this.toastr.warning(message, title);
  }

  public showError(message: string, title: string, config?: Partial<IndividualConfig>): ActiveToast<any> {
    return this.toastr.error(message, title, config);
  }

  public showNewMessage(component?: Partial<IndividualConfig>): void {
    this.toastr.show("", "", component);
  }

  public clearAll(): void {
    this.toastr.clear();
  }

  public clear(toastrId: number): void {
    this.toastr.clear(toastrId);
  }
}
