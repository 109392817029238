<div
  class="sidebar"
  [class.shorter]="shorter"
  (clickOutside)="onClickOutside($event)"
  [clickOutsideEnabled]="isClickOutsideCheckEnabled$ | async"
>
  <div class="sidebar__wrap">
    <ng-content></ng-content>
  </div>
</div>
