<div class="modal-header">
  <h5 class="modal-title">Delete Relationship Status</h5>
  <button type="button" class="btn-close" aria-label="Close" [mat-dialog-close]>
  </button>
</div>

<ng-container *ngIf="!isFinalConfirmation; else finalConfirmation">
  <div class="modal-body">
    <p class="text-justify">
      All assigned statuses related to this relationship status will be <strong>PERMANENTLY</strong> deleted.
    </p>

    <p><strong>This action cannot be undone</strong>.</p>
    <label for="nameConfirmation" [hidden]="true">Name Confirmation</label>
    <input
      id="nameConfirmation"
      type="text"
      class="form-control"
      #nameConfirmation
      placeholder="Type {{ data.relationshipStatus.name }} here"
      [autofocus]="true"
      (input)="setDeleteDisabled(nameConfirmation.value)"
    />
  </div>

  <div class="modal-footer">
    <div *ngIf="isBusy" class="spinner-svg small"></div>

    <button class="btn btn-secondary" type="button" [mat-dialog-close] [disabled]="isBusy ? true : null">Cancel</button>
    <button
      class="btn btn-danger"
      type="button"
      (click)="transitionToFinalConfirmation()"
      [disabled]="isBusy || isDeleteDisabled ? true : null"
    >
      Delete
    </button>
  </div>
</ng-container>

<ng-template #finalConfirmation>
  <div class="modal-body">
    <p class="mb-0">
      Are you sure you want to delete
      <em>{{ data.relationshipStatus.name }}</em>
      relationship status?
    </p>
    <p class="mb-0"><strong>This action cannot be undone</strong>.</p>
  </div>

  <div class="modal-footer">
    <ng-container *ngIf="!countdownDone">
      <countdown [config]="{ leftTime: 5, format: 's' }" (event)="countdownEvent($event)" class="me-2"></countdown>
      <div class="spinner-svg small me-2"></div>
    </ng-container>

    <div *ngIf="isBusy" class="spinner-svg small me-2"></div>

    <button class="btn btn-secondary" type="button" [mat-dialog-close] [disabled]="isBusy ? true : null">Cancel</button>
    <button
      class="btn btn-danger"
      type="button"
      (click)="submit()"
      [disabled]="isBusy || !countdownDone ? true : null"
    >
      Delete
    </button>
  </div>
</ng-template>
