import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AnonymousGuard implements CanActivate {
  constructor(private AuthService: AuthService, private router: Router) {}

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    try {
      await this.AuthService.token;
      return this.router.parseUrl('/');
    } catch (err) {
      return true;
    }
  }
}
