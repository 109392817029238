<div id="create-relationship-status">
  <div mat-dialog-title>
    <div>{{ isEditingExistingRelationship ? relationshipStatus.name : 'Add New Relationship Status' }}</div>
  </div>
  <mat-dialog-content class="mat-typography">
    <form [formGroup]="form">
      <div class="row mb-4">
        <div class="col-md-4">
          <label class="modal-desc">Name</label>
        </div>
        <div class="col-md-8">
          <input formControlName="name" class="modal-input" placeholder="Name" />
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-md-4">
          <label class="modal-desc">Notes</label>
        </div>
        <div class="col-md-8">
          <input formControlName="notes" class="modal-input" placeholder="Notes" />
        </div>
      </div>
      <!-- <div class="row mb-4">
        <div class="col-md-12">
          <div class="d-flex">
            <div class="flex-grow-1 modal-desc">Colorblind</div>
            <mat-slide-toggle formControlName="colorBlind"></mat-slide-toggle>
          </div>
        </div>
      </div> -->
      <div class="row mb-4">
        <div class="col-md-4">
          <div class="modal-desc">Color</div>
        </div>
        <div class="col-md-8 d-flex">
          <ngx-colors formControlName="color" ngx-colors-trigger></ngx-colors>
          <div class="p-2 color-txt">{{ color }}</div>
        </div>
      </div>
      <div class="row mb-4" *ngIf="isGlobalStatus">
        <div class="col-md-4">
          <div class="modal-desc">Template</div>
        </div>
        <div class="col-md-8 d-flex">
          <mat-slide-toggle formControlName="isTemplate"></mat-slide-toggle>
        </div>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-dialog-close class="btn btn-secondary me-2">Cancel</button>
    <button (click)="submit()" class="btn btn-primary">
      {{ isEditingExistingRelationship ? 'Save' : 'Create' }}
    </button>
  </mat-dialog-actions>
</div>
