import { DataProxy } from '@apollo/client/cache';
import { gql } from 'apollo-angular';

import { engagements, engagementsVariables } from '../../../generated/engagements';
import { EngagementDetail } from '../../../generated/EngagementDetail';

const COMMON_FRAGMENT = gql`
  fragment EngagementCommonDetail on EngagementCommon {
    id
    createdAt
    createdBy {
      id
      name
      picture
      email
    }
    case {
      id
      person {
        id
        fullName
        picture
      }
    }
    relationship {
      id
      person {
        id
        fullName
        picture
      }
    }
    isPublic
    isDraft
    isTwoWayContact
    notes
  }
`;

const DOCUMENT_FRAGMENT = gql`
  fragment EngagementDocumentDetail on EngagementDocument {
    ...EngagementCommonDetail
    title
    originalFileName
    attachment
    thumbnail
    occurredAt
  }
`;

const CALL_FRAGMENT = gql`
  fragment EngagementCallDetail on EngagementCall {
    ...EngagementCommonDetail
    occurredAt
    isTwoWayContact
  }
`;

const EMAIL_FRAGMENT = gql`
  fragment EngagementEmailDetail on EngagementEmail {
    ...EngagementCommonDetail
    relationship {
      id
      person {
        id
        fullName
        picture
        emails {
          email
        }
      }
    }
    occurredAt
    isTwoWayContact
    subject
  }
`;

const NOTE_FRAGMENT = gql`
  fragment EngagementNoteDetail on EngagementNote {
    ...EngagementCommonDetail
    occurredAt
    isTwoWayContact
  }
`;

const OTHER_FRAGMENT = gql`
  fragment EngagementOtherDetail on EngagementOther {
    ...EngagementCommonDetail
    occurredAt
    isTwoWayContact
    engagementType
  }
`;

const REMINDER_FRAGMENT = gql`
  fragment EngagementReminderDetail on EngagementReminder {
    ...EngagementCommonDetail
  }
`;

export const ENGAGEMENT_DETAIL_FRAGMENT = gql`
  fragment EngagementDetail on Engagement {
    __typename
    ... on EngagementCall {
      ...EngagementCallDetail
    }
    ... on EngagementEmail {
      ...EngagementEmailDetail
    }
    ... on EngagementNote {
      ...EngagementNoteDetail
    }
    ... on EngagementDocument {
      ...EngagementDocumentDetail
    }
    ... on EngagementOther {
      ...EngagementOtherDetail
    }
    ... on EngagementReminder {
      ...EngagementReminderDetail
    }
  }

  ${CALL_FRAGMENT}
  ${EMAIL_FRAGMENT}
  ${NOTE_FRAGMENT}
  ${DOCUMENT_FRAGMENT}
  ${OTHER_FRAGMENT}
  ${REMINDER_FRAGMENT}
  ${COMMON_FRAGMENT}
`;

export const ENGAGEMENTS_QUERY = gql`
  query engagements($caseId: Int!) {
    engagements(caseId: $caseId) {
      ...EngagementDetail
    }
  }
  ${ENGAGEMENT_DETAIL_FRAGMENT}
`;

export const ENGAGEMENTS_WITH_RELATIONSHIPS_COUNT_QUERY = gql`
  query engagementsWithRelationshipsCount($caseId: Int!) {
    engagements(caseId: $caseId) {
      ...EngagementDetail
    }
    relationshipsCount(caseId: $caseId)
  }
  ${ENGAGEMENT_DETAIL_FRAGMENT}
`;

export const ENGAGEMENTS_COUNTS_QUERY = gql`
  query engagementCounts($caseId: Int!) {
    engagementCounts(caseId: $caseId) {
      type
      count
    }
    relationshipsCount(caseId: $caseId)
  }
`;

export const ADD_ENGAGEMENT_OTHER_MUTATION = gql`
  mutation addEngagementOther($caseId: Int!, $engagementType: OtherEngagementDataType!, $value: CreateEngagementOther!) {
    createEngagementOther(caseId: $caseId, engagementType: $engagementType, value: $value) {
      ...EngagementDetail
    }
  }
  ${ENGAGEMENT_DETAIL_FRAGMENT}
`;

export const CREATE_ENGAGEMENT_CALL_MUTATION = gql`
  mutation createEngagementCall($caseId: Int!, $value: CreateEngagementCall!) {
    createEngagementCall(caseId: $caseId, value: $value) {
      ...EngagementDetail
    }
  }
  ${ENGAGEMENT_DETAIL_FRAGMENT}
`;

export const CREATE_ENGAGEMENT_EMAIL_MUTATION = gql`
  mutation createEngagementEmail($caseId: Int!, $value: CreateEngagementEmail!) {
    createEngagementEmail(caseId: $caseId, value: $value) {
      ...EngagementDetail
    }
  }
  ${ENGAGEMENT_DETAIL_FRAGMENT}
`;

export const CREATE_ENGAGEMENT_NOTE_MUTATION = gql`
  mutation createEngagementNote($caseId: Int!, $value: CreateEngagementNote!) {
    createEngagementNote(caseId: $caseId, value: $value) {
      ...EngagementDetail
    }
  }
  ${ENGAGEMENT_DETAIL_FRAGMENT}
`;

export const DELETE_ENGAGEMENT_MUTATION = gql`
  mutation deleteEngagementListItem($caseId: Int!, $engagementId: Int!) {
    deleteEngagement(caseId: $caseId, engagementId: $engagementId) {
      ...EngagementDetail
    }
  }
  ${ENGAGEMENT_DETAIL_FRAGMENT}
`;

export function deleteEngagementCache(caseId: number, engagement: EngagementDetail, cache: DataProxy) {
  const engagements = cache.readQuery<engagements, engagementsVariables>({ query: ENGAGEMENTS_QUERY, variables: { caseId } });
  if (!engagements) return;
  cache.writeQuery<engagements, engagementsVariables>({
    query: ENGAGEMENTS_QUERY,
    variables: { caseId },
    data: { engagements: engagements.engagements.filter((e) => e.id !== engagement.id) },
  });
}

export function deleteRelationshipEngagementCache(caseId: number, relationshipId: number, cache: DataProxy) {
  const engagements = cache.readQuery<engagements, engagementsVariables>({ query: ENGAGEMENTS_QUERY, variables: { caseId } });
  if (!engagements) return;
  cache.writeQuery<engagements, engagementsVariables>({
    query: ENGAGEMENTS_QUERY,
    variables: { caseId },
    data: { engagements: engagements?.engagements?.filter((e) => e.relationship?.id !== relationshipId) },
  });
}

export function addEngagementCache(caseId: number, engagement: EngagementDetail, cache: DataProxy) {
  const engagements = cache.readQuery<engagements, engagementsVariables>({ query: ENGAGEMENTS_QUERY, variables: { caseId } });
  if (!engagements) return;
  cache.writeQuery<engagements, engagementsVariables>({
    query: ENGAGEMENTS_QUERY,
    variables: { caseId },
    data: { engagements: [engagement, ...engagements.engagements] },
  });
}
