import { StringWrapper } from '../../../generated/globalTypes';
import { ParsedName } from './parsers';

/**
 * Takes a string and converts to GraphQL StringWrapper
 * @param str 
 * @returns string mapped to a StringWrapper
 */
export function toStringWrapper(str: string | undefined): StringWrapper {
  return { val: str };
}

export function getFullName({
  firstName,
  middleName,
  lastName,
  suffix,
}: ParsedName): string {
  let name = firstName || '';
  if (middleName)
    name += (name.length > 0 ? ' ' : '') + middleName;
  if (lastName)
    name += (name.length > 0 ? ' ' : '') + lastName;
  if (suffix)
    name += (name.length > 0 ? ', ' : '') + suffix;
  return name;
}