import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, AbstractControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { gql } from 'apollo-angular';
import { ToastrService } from 'ngx-toastr';

import { createTeam, createTeamVariables } from '../../../../generated/createTeam';

@Component({
  selector: 'cok-no-team',
  templateUrl: 'no-team.component.html',
  styleUrls: ['no-team.component.scss'],
})
export class NoTeamComponent implements OnInit {
  public form = new FormGroup({
    teamName: new FormControl(null, [Validators.required, Validators.minLength(5), Validators.maxLength(128)]),
  });
  public isBusy = false;

  constructor(private apollo: Apollo, private messageService: ToastrService, private router: Router) {}

  ngOnInit() {}

  private isSubmitted: boolean = false;
  public onSubmit($event: any) {
    this.isSubmitted = true;
    if (this.isBusy || this.form.invalid) return;

    const teamName: string = this.teamName.value.trim();

    this.isBusy = true;
    this.apollo
      .mutate<createTeam, createTeamVariables>({
        mutation: gql`
          mutation createTeam($isSelfSignup: Boolean!, $value: CreateTeamInput!) {
            createTeam(isSelfSignUp: $isSelfSignup, value: $value) {
              id
            }
          }
        `,
        variables: {
          isSelfSignup: true,
          value: {
            name: teamName,
          },
        },
        refetchQueries: ['getMe'],
        awaitRefetchQueries: true,
      })
      .subscribe(
        async (result) => {
          await this.router.navigate(['/cases/team'], { queryParams: { newTeam: true } });

          this.isBusy = false;
        },
        (err) => {
          console.error('Failed to create team', err);
          const match = (err.message as string).match(/(.*GraphQL error: )?(.*)/);
          const errorMessage = match![2];
          this.messageService.error(errorMessage);
          this.isBusy = false;
        }
      );
  }

  public hasError(control: AbstractControl) {
    return control.invalid && (control.touched || this.isSubmitted);
  }

  public get teamName() {
    return this.form.get('teamName') as FormControl;
  }
}
