import { Component, ChangeDetectorRef, OnInit, OnDestroy } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'mobile-app-modal',
  templateUrl: 'mobile-app-modal.component.html',
  styleUrls: ['mobile-app-modal.component.scss'],
})
export class MobileAppModalComponent implements OnInit, OnDestroy {
  constructor(private ref: ChangeDetectorRef, private bsModalRef: BsModalRef) {}

  ngOnInit(): void {}

  public close() {
    this.bsModalRef.hide();
  }

  ngOnDestroy(): void {}
}
