<div class="cok-input" (clickOutside)="onClickOutside($event)">
  <div>
    <span *ngIf="impreciseDate">
      <input
        type="text"
        class="form-control"
        [ngClass]="{'is-invalid' : isInvalid()}"
        [value]="displayValue"
        (focus)="inputFocus($event)"
        (focusout)="lostFocus($event)"
        (blur)="blur($event)"
        [placeholder]="placeholder"
        (input)="inputChanged($event)"
        (keydown.tab)="inputTabKeydown($event)"
      />
    </span>
    <span *ngIf="!impreciseDate">
      <input
        type="text"
        class="form-control"
        [ngClass]="{'is-invalid' : isInvalid()}"
        [imask]="maskConfig"
        [value]="displayValue"
        (focus)="inputFocus($event)"
        (focusout)="lostFocus($event)"
        (blur)="blur($event)"
        [placeholder]="placeholder"
        (input)="inputChanged($event)"
        (keydown.tab)="inputTabKeydown($event)"
      />
    </span>
    <div class="autocomplete {{ hideAutocomplete }}">
      <ul>
        <li *ngFor="let item of validDate" (click)="onSelect(item)">
          <span>{{ item.label }}</span>
        </li>
      </ul>
    </div>
  </div>
  <div class="input-date-hidden">
    <input
      type="text"
      class="form-control"
      #datePicker="bsDatepicker"
      bsDatepicker
      [outsideClick]="false"
      tabindex="-1"
      (bsValueChange)="bsDateValueChange($event)"
      [bsValue]="datePickerValue"
      [bsConfig]="{
        showWeekNumbers: false,
        dateInputFormat: 'MM/DD/YYYY',
        adaptivePosition: true
      }"
      [maxDate]="maxDate"
      [minDate]="minDate"
    />
  </div>
  <div class="calendar-icon">
    <span class="bs-datepicker-icon" (click)="toggle($event)">
      <fa-icon [icon]="faCalendarAlt"></fa-icon>
    </span>
  </div>
</div>
<!-- Errors -->
<div>
  <p class="maxsize-error mt-1 mb-1" *ngIf="isOldDate()">Date must be on or after {{ minDate | date: 'MM/dd/yyyy' }}</p>
  <p class="maxsize-error mt-1 mb-1" *ngIf="isFutureDate()">Date must be on or before {{ maxDate | date: 'MM/dd/yyyy' }}
  </p>
</div>