import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

@Injectable({ providedIn: 'root' })
export class ModalService {

  private readonly modalsClosingSubject = new Subject<void>();

  public closeDialogs(): void {
    this.modalsClosingSubject.next();
  }

  public getModalsClosingObservable(): Observable<void> { 
    return this.modalsClosingSubject.asObservable();
  }
}
