import {
  Component,
  ElementRef,
  forwardRef,
  Input,
  OnInit,
  ViewChild,
  EventEmitter,
  Output,
  ChangeDetectorRef,
  HostListener
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import { BsDatepickerConfig, BsDatepickerDirective } from 'ngx-bootstrap/datepicker';
import * as moment from 'moment';

@Component({
  selector: 'input-datetime-control',
  templateUrl: './input-datetime-control.component.html',
  styleUrls: ['./input-datetime-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputDatetimeControlComponent),
      multi: true,
    },
  ],
})
export class InputDatetimeControlComponent implements ControlValueAccessor, OnInit {
  public faCalendarAlt = faCalendarAlt;
  public disabled: boolean = false;
  private onChangeFn: any = (_: any) => { };
  private onTouchedFn: any = (_: any) => { };
  public dateConfig = new BsDatepickerConfig();

  @ViewChild('displayDatetimeElement')
  inputBox: ElementRef;

  @ViewChild(BsDatepickerDirective)
  datePicker: BsDatepickerDirective;

  public displayValue: string = '';
  public bsDatePickerValue: Date | undefined = undefined;

  @Input()
  placeholder: string = '';

  @Input()
  placement: string = '';

  @Input()
  minDate?: Date;

  @Input()
  maxDate?: Date;

  @Output()
  onValueChanged = new EventEmitter<any>();

  @Output()
  onBlur = new EventEmitter<any>();

  constructor(private cdRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.dateConfig.minDate = this.minDate;
    this.dateConfig.maxDate = this.maxDate;
    this.dateConfig.showWeekNumbers = false;
  }

  /**
   * Close the date picker calendar
   */
  public closeCalendar() {
    this.datePicker.hide();
  }

  // Close date picker calendar when page scrolls
  @HostListener('window:scroll')
  onScrollEvent() {
    this.datePicker.hide();
  }

  registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedFn = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: any) {
    if (typeof obj === 'string') {
      this.setDate(obj);
    } else {
      this.setDate('');
    }
  }

  public blur($event: any): void {
    this.onTouchedFn($event);
    this.onBlur.emit($event);
  }

  public inputFocus($event: any) {
    this.datePicker.hide();
    this.onTouchedFn($event);
  }

  public bsDateValueChange(date: Date | string | undefined) {
    if (date instanceof Date) {
      this.displayValue = moment(date).format('MM/DD/YYYY h:mm a');

      this.onChangeFn(this.displayValue);
      this.onValueChanged.emit(this.displayValue);
    }
  }

  public onClickOutside() {
    this.datePicker.hide();
  }

  public inputTabKeydown() {
    this.datePicker.hide();
  }

  private regexMonth: RegExp = /^\s*\d{2}$/;
  private regexMonthAndDay: RegExp = /^\s*\d{1,2}\/\d{2}$/;

  public inputChanged($event: any) {
    // add slashes to input automatically
    let input = $event.target.value as string;
    if ((this.regexMonth.test(input) || this.regexMonthAndDay.test(input)) && input.length > this.displayValue.length) {
      input += '/';
    }
    // remove multiple slashes in a row
    input = input.replace(/\/{2,}/g, '/');

    let currentCarrotPosition = undefined;
    if (this.displayValue === input) {
      // get current carrot position
      currentCarrotPosition = InputDatetimeControlComponent.getCaretPosition(this.inputBox.nativeElement as HTMLInputElement);

      // this is a hack so that the display updates
      this.displayValue = 'U+0000' + input;
      this.onChangeFn(this.displayValue);
      this.onValueChanged.emit(this.displayValue);
      // detectChanges is necessary. do not remove
      this.cdRef.detectChanges();
    }

    this.setDate(input, currentCarrotPosition);
  }

  private setDate(dateStr: string, currentCarrotPosition: number | undefined = undefined) {
    this.displayValue = dateStr.slice();

    // update value in BSDatePicker (if it is formatted properly)
    const date = moment(this.displayValue, [
      'MM/DD/YYYY',
      'M/DD/YYYY',
      'M/D/YYYY',
      'MM/D/YYYY',
      'MM/DD/YYYY h:mm a',
      'M/DD/YYYY h:mm a',
      'M/D/YYYY h:mm a',
      'MM/D/YYYY h:mm a',
    ], true);
    this.bsDatePickerValue = date.isValid() ? date.toDate() : undefined;

    this.onChangeFn(this.displayValue);
    this.onValueChanged.emit(this.displayValue);
    this.cdRef.detectChanges();

    // need to use a setTimeout here so this carrot position change happens
    // after the input value is updated
    setTimeout(() => {
      // after we update the control, the cursor moves to the end
      // we want to keep it in the same place before we updated the value
      if (currentCarrotPosition !== undefined) {
        InputDatetimeControlComponent.setCaretPosition(this.inputBox.nativeElement as HTMLInputElement, currentCarrotPosition - 1);
      }
    });
    // });
  }

  private static setCaretPosition(ctrl: HTMLInputElement, pos: number) {
    ctrl.focus();
    ctrl.setSelectionRange(pos, pos);
  }

  private static getCaretPosition(ctrl: HTMLInputElement): number | undefined {
    let startPos = ctrl.selectionStart;
    let endPos = ctrl.selectionEnd;
    return startPos !== null && startPos === endPos ? endPos : undefined;
  }

  public isInvalid(): boolean {
    return this.isOldDate() || this.isFutureDate();
  }

  public isOldDate(): boolean {
    if (!this.minDate) return false;
    return moment(this.displayValue).toDate() < this.minDate;
  }

  public isFutureDate(): boolean {
    if (!this.maxDate) return false;
    return moment(this.displayValue).toDate() > this.maxDate;
  }
}
