export const EnumExports = {
  STATUS: {
    1: 'Completed',
    2: 'Processing',
    3: 'Cancel',
    4: 'Pending',
    5: 'Error',
  },
  STATUS_CONSTANT: {
    Completed: 1,
    Processing: 2,
    Cancel: 3,
    Pending: 4,
    Error: 5,
  },
  STATUS_DEFAULT: '4',
  FILE_TYPES: {
    1: 'PDF',
    2: 'Word',
  },
  FILE_EXTENSION: {
    1: '.pdf',
    2: '.doc',
  },
  OPTIONS: {
    caseOverview: 1,
    includeConnections: 2,
    includeDocuments: 3,
    includeSensitiveInfo: 4,
    includeEngagement: 5,
    workspace: 6,
  },
  OPTIONS_ENUM: {
    1: 'Case Overview',
    2: 'Include Connections',
    3: 'Include Documents',
    4: 'Include Sensitive Info',
    5: 'Include Engagement History',
    6: 'Workspace',
  },
};
