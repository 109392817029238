import { gql } from "apollo-angular";

export const RELATIONSHIP_STATUS = gql`
  fragment RelationshipStatus on RelationshipStatus {
    id
    name
    colorBlind
    color
    notes
    sortOrder
    isTemplate
  }
`;

export const TEAM_RELATIONSHIP_STATUSES = gql`
  query relationshipStatuses($teamId: Int!) {
    relationshipStatuses(teamId: $teamId) {
      ...RelationshipStatus
    }
  }

  ${RELATIONSHIP_STATUS}
`;

export const CREATE_TEAM_RELATIONSHIP_STATUS = gql`
  mutation createRelationshipStatus($teamId: Int!, $value: RelationshipStatusInput!) {
    createRelationshipStatus(teamId: $teamId, value: $value) {
      ...RelationshipStatus
    }
  }

  ${RELATIONSHIP_STATUS}
`;

export const UPDATE_TEAM_RELATIONSHIP_STATUS = gql`
  mutation updateRelationshipStatus($teamId: Int!, $id: Int!, $value: RelationshipStatusInput!) {
    updateRelationshipStatus(teamId: $teamId, id: $id, value: $value) {
      ...RelationshipStatus
    }
  }

  ${RELATIONSHIP_STATUS}
`;

export const DELETE_TEAM_RELATIONSHIP_STATUS = gql`
  mutation deleteRelationshipStatus($teamId: Int!, $id: Int!) {
    deleteRelationshipStatus(teamId: $teamId, id: $id) {
      ...RelationshipStatus
    }
  }

  ${RELATIONSHIP_STATUS}
`;
