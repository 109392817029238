<div mat-dialog-title class="main-title">Confirm your role</div>
<div mat-dialog-content class="content">
    <mat-stepper #stepper>
        <mat-step >
            <div class="text-center pt-5 pb-5">
                <img src="../../../../assets/images/welcome-people.png">
                <div *ngIf="vettedUser">
                   <p class="first-title-simple"> Please tell us a little more about your role so we can better support you in the future! Thank you!</p>
                </div>
                <div *ngIf="limitedAccess">
                    <p class="first-title">Please complete the following questions in order to gain full access to the Family Connection tool.</p>
                    <p class="first-desc">(You currently have limited access to Family Connections and do not have access to the People Search tool)</p>
                </div>
                <div *ngIf="vettingRequired">
                    <p class="first-title-simple"> Please complete the following questions in order to access our Family Connections tool. </p>
                 </div>
            </div>
            <div mat-dialog-actions align="end">
              <button mat-button matStepperNext class="blue-btn">Next</button>
            </div>
        </mat-step>
        <mat-step>
            <form [formGroup]="form" (ngSubmit)="confirm()">
                <div class="container pt-4">
                    <div class="row">
                        <div class="col-md-12">
                            <p class="mb-0 mt-3 label-txt">Just a friendly reminder to double-check that the information you're entering belongs to you!</p>
                        </div>
                    </div>
                    <div class="row short-row">
                        <div class="col-md-5">
                            <label>Select your role</label>
                        </div>
                        <div class="col-md-7 kyc-input">
                            <mat-select
                                id="selectRole"
                                placeholder="Role"
                                [ngModelOptions]="{ standalone: true }"
                                [(ngModel)]="selectedRoleOption">
                                    <mat-option *ngFor="let option of VETTED_USER_ORGANIZATION_ROLE_OPTIONS" [value]="option.value">
                                        {{ option.name }}
                                    </mat-option>
                            </mat-select>

                            <ng-container *ngIf="selectedRoleOption === VettedUserOrganizationRole.OTHER">
                                <label>
                                    Briefly describe your role or title
                                </label>
                                <mat-form-field class="example-full-width" appearance="fill">
                                    <mat-label>Role</mat-label>
                                    <input
                                        type="text"
                                        matInput
                                        required
                                        [(ngModel)]="otherRole"
                                        [ngModelOptions]="{ standalone: true }">
                                </mat-form-field>
                            </ng-container>
                        </div>
                    </div>

                    <div class="row" *ngIf="!vettedUser">
                        <div class="col-md-5">
                            <label>Name of your agency</label>
                        </div>
                        <div class="col-md-7 kyc-input">
                            <mat-form-field appearance="outline" class="full-width">
                                <input matInput formControlName="agency" placeholder="Agency">
                            </mat-form-field>
                        </div>

                        <div class="col-md-5">
                            <label>How will you use our tool?</label>
                        </div>
                        <div class="col-md-7 kyc-input">
                            <mat-form-field appearance="outline" class="full-width">
                                <textarea matInput formControlName="plan" rows="3" placeholder="Write.."></textarea>
                            </mat-form-field>
                        </div>

                        <div class="col-md-12">
                            <label id="radio-label" class="control-label text-start">Select one of the following to confirm your role</label>

                            <div class="radio-btn-wrapper">
                                <input
                                    type="radio"
                                    name="verification-method"
                                    id="verification-by-supervisor"
                                    [(ngModel)]="isVerifyingByPicture"
                                    [ngModelOptions]="{ standalone: true }"
                                    [value]="false">
                                <label for="verification-by-supervisor">Provide the name and contact information of your supervisor</label><br>
                                <input
                                    type="radio"
                                    name="verification-method"
                                    id="verification-by-picture"
                                    [(ngModel)]="isVerifyingByPicture"
                                    [ngModelOptions]="{ standalone: true }"
                                    [value]="true">
                                <label for="verification-by-picture">Upload proof of role</label><br>
                            </div>
                        </div>

                        <div class="first-option mt-4" *ngIf="!isVerifyingByPicture">
                            <div class="row">
                                <div class="col-md-5">
                                    <label>Name</label>
                                </div>
                                <div class="col-md-7 kyc-input">
                                    <mat-form-field appearance="outline" class="full-width">
                                        <input matInput formControlName="contactPersonName" placeholder="Name">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-5">
                                    <label>Contact information</label>
                                </div>
                                <div class="col-md-7 kyc-input">
                                    <mat-form-field appearance="outline" class="full-width">
                                        <input matInput formControlName="contactPersonContactForm" placeholder="Contact information">
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>

                        <div class="second-option mt-3" *ngIf="isVerifyingByPicture">
                            <div class="row">
                                <div class="d-flex flex-row bd-highlight">
                                    <div class="bd-highlight">
                                        <cok-image-upload [avatarType]="'document'" formControlName="proofOfWorkImage" [previewUrl]="answers.proofOfWorkImage">
                                        </cok-image-upload>
                                    </div>
                                    <div class="px-2 bd-highlight d-flex align-items-center gray-txt"> Upload proof of employment
                                        <mat-icon
                                            matTooltip="Upload a picture of your professional business card, organizational badge, or other proof of child welfare employment or volunteer role."
                                            aria-label="Button that displays a tooltip when focused or hovered over"
                                            matTooltipPosition="above">info
                                        </mat-icon>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12 text-left small-info">
                        You can review our terms of service agreement <a target="_blank" href="https://www.connectourkids.org/terms">here</a>.
                    </div>
                </div>
                <div mat-dialog-actions align="end">
                    <button mat-button matStepperPrevious class="gray-btn">Back</button>
                    <button mat-stroked-button color="primary" class="blue-btn" [disabled]="shouldDisableConfirmButton">Confirm</button>
                </div>
            </form>
        </mat-step>
        <mat-step >
            <div class="text-center pt-5 pb-5">
                <!-- <img src="../../../../assets/images/check-mark.png"> -->
                <img src="../../../../assets/images/party-icon.png">
                <p class="last-title" *ngIf="vettedUser">Thank you for completing this brief question. If you ever want to update your answer, you can go to <span class="profile-verification-label" (click)="goToProfileVerification()">My Profile > Verification</span></p>
                <p class="last-title" *ngIf="limitedAccess">Your answers have been successfully submitted and are pending review. You will receive an email when this information has been reviewed. Thank you!</p>
                <p class="last-title" *ngIf="vettingRequired">Your answers have been successfully submitted and are pending review. You will receive an email when your information has been reviewed and granted access to Family Connections. Thank you!</p>
            </div>
            <div mat-dialog-actions align="end">
              <button mat-stroked-button color="primary" class="blue-btn" *ngIf="isVettingNotRequired" (click)="exitModal()">Exit</button>
            </div>

        </mat-step>
      </mat-stepper>
</div>
