import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { UserService } from '../services/user.service';

@Injectable()
export class ErrorGuard implements CanActivate {
  constructor(private userService: UserService, public router: Router) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    try {
      const user = await this.userService.ensureUser();
      if (user.userTeams.length > 0) {
        // if user is part of a team redirect them to root
        return this.router.parseUrl('/');
      }
    } catch (err) {
      console.error(err);
      return false;
    }

    return true;
  }
}
