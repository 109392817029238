<div class="person">
  <div class="avatar" [tooltip]="name" placement="right">
    <img class="personImage" [attr.src]="person?.picture | avatar" />
  </div>
  <div class="name d-none d-sm-flex text-truncate">
    <ng-container *ngIf="teamMember">
      <span class="text-truncate">{{ name }}</span>
    </ng-container>
    <ng-container *ngIf="case">
      <a href="javascript:void(0)" class="text-truncate" [routerLink]="['/', 'cases', case.id]">
        {{ name || (caseFileNumber ? ('Case ' + caseFileNumber) : '') }}
      </a>
    </ng-container>
    <span class="status text-truncate" *ngIf="status">
      <span class="pie" [ngStyle]="{ background: 'conic-gradient(white 0% ' + num + '%, #1d6491 ' + num + '% 100%)' }"></span>
      <span class="status-name">{{ status }}</span>
    </span>
  </div>
</div>
