export enum VettedUserOrganizationRole {
    WWK_RECRUITER = 'WWK_RECRUITER',
    WWK_SUPERVISOR = 'WWK_SUPERVISOR',
    CASA = 'CASA',
    CASEWORKER = 'CASEWORKER',
    OTHER = ''
}

export const VETTED_USER_ORGANIZATION_ROLE_OPTIONS = [
    {
        name: 'WWK recruiter',
        value: VettedUserOrganizationRole.WWK_RECRUITER,
    },
    {
        name: 'WWK supervisor',
        value: VettedUserOrganizationRole.WWK_SUPERVISOR,
    },
    {
        name: 'CASA',
        value: VettedUserOrganizationRole.CASA,
    },
    {
        name: 'Caseworker',
        value: VettedUserOrganizationRole.CASEWORKER,
    },
    {
        name: 'Other',
        value: VettedUserOrganizationRole.OTHER,
    },
];