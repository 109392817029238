<section class="lyt-form not-found prevent-screen">
  <div class="inner">
    <div class="text-lable">
      Your account does not belong to any team. Please either create a new team below, or contact your team manager to request access to an
      existing team.
    </div>

    <h3 class="mb-3">Create a New Team</h3>
    <form [formGroup]="form" (ngSubmit)="onSubmit($event)" role="form">
      <div class="form-group row">
        <div class="col-sm-12" [class.has-error]="hasError(teamName)">
          <input class="form-control" type="text" placeholder="New Team Name" [formControl]="teamName" />
          <div *ngIf="hasError(teamName)">
            <p class="maxsize-error mt-1" *ngIf="teamName.errors!.required">
              Team Name is required
            </p>
            <p class="maxsize-error mt-1" *ngIf="teamName.errors!.minlength">
              Team Name must be at least {{ teamName.errors!.minlength.requiredLength }} characters
            </p>
            <p class="maxsize-error mt-1" *ngIf="teamName.errors!.maxlength">
              Team Name must be at most {{ teamName.errors!.maxlength.requiredLength }} characters
            </p>
          </div>
        </div>
      </div>

      <button type="submit" class="btn btn-primary" [class.processing]="isBusy">Create New Team</button>
    </form>
  </div>
</section>
