<div class="result-container row ms-1 me-1 pt-2 pb-2">
  <div class="col-3 col-md-5 ps-1">
    <div class="person">
      <div class="avatar" placement="right">
        <img class="personImage" [src]="case.casePicture ? case.casePicture : DEFAULT_AVATAR_IMAGE" />
      </div>
      <div class="name d-none d-sm-flex text-truncate">
        <ng-container *ngIf="case">
          <a class="text-truncate" [routerLink]="['/', 'cases', case.caseId]">
            {{ caseFullName || (case.caseFileNumber ? ('Case ' + case.caseFileNumber) : '') }}
          </a>

          <span class="status text-truncate" *ngIf="case.caseStatus">
            <span class="pie"
              [ngStyle]="{ background: 'conic-gradient(white 0% ' + caseStatusPercentage + '%, #1d6491 ' + caseStatusPercentage + '% 100%)' }"></span>
            <span class="status-name">{{ case.caseStatus }}</span>
          </span>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="col-9 col-md-7 d-flex flex-row justify-content-center align-items-center pe-0">
    <div class="bar">
      <div *ngIf="case.connections > 0" class="connections"
        [style.width.%]="getWidth(case.connections)" [tooltip]="case.connections + ' connections'" placement="top">
      </div>
      <div *ngIf="case.notes > 0" class="notes" [style.width.%]="getWidth(case.notes)"
        [tooltip]="case.notes + ' notes'" placement="top"></div>
      <div *ngIf="case.documents > 0" class="documents"
        [style.width.%]="getWidth(case.documents)" [tooltip]="case.documents + ' documents'"
        placement="top"></div>
      <div *ngIf="case.calls > 0" class="calls" [style.width.%]="getWidth(case.calls)"
        [tooltip]="case.calls + ' calls'" placement="top"></div>
      <div *ngIf="case.emails > 0" class="emails" [style.width.%]="getWidth(case.emails)"
        [tooltip]="case.emails + ' emails'" placement="top"></div>
      <div *ngIf="case.inPerson > 0" class="in_person"
        [style.width.%]="getWidth(case.inPerson)" [tooltip]="case.inPerson + ' in person'"
        placement="top"></div>
      <div *ngIf="case.letters > 0" class="letters"
        [style.width.%]="getWidth(case.letters)" [tooltip]="case.letters + ' letters'"
        placement="top"></div>
      <div *ngIf="case.socialMedia > 0" class="social_media"
        [style.width.%]="getWidth(case.socialMedia)"
        [tooltip]="case.socialMedia + ' social media'" placement="top"></div>
      <div *ngIf="case.texts > 0" class="texts" [style.width.%]="getWidth(case.texts)"
        [tooltip]="case.texts + ' texts'" placement="top"></div>
      <div *ngIf="case.alignmentConversations > 0" class="alignment_conversations" [style.width.%]="getWidth(case.alignmentConversations)"
        [tooltip]="case.alignmentConversations + ' alignment conversations'" placement="top"></div>
    </div>
    <span class="count">{{ case.allActivity }}</span>
  </div>
</div>
