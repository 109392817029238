import { Component, forwardRef, ViewChild, ElementRef, AfterViewInit, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

import { Roles } from '../../../../generated/globalTypes';
import { roleLevel } from '../../services/permission.service';

@Component({
  selector: 'cok-permission-select',
  templateUrl: './permission-select.component.html',
  styleUrls: ['./permission-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => PermissionSelectComponent),
    },
  ],
})
export class PermissionSelectComponent implements ControlValueAccessor, AfterViewInit {
  @Input() showIconsOnNullSelection: boolean = true;

  onChange: (val: Roles | null) => void = () => {};
  public roles = Roles;
  public faCheck = faCheck;
  public faTimes = faTimes;

  public scrollBarTopBackground: string = '';
  public scrollBarBottom: string = '';
  public scrollBarTopSelection: string = '';

  private _selectedValue: Roles | null = null;

  public get selectedValue() {
    return this._selectedValue;
  }
  public set selectedValue(value: Roles | null) {
    this._selectedValue = value;
    this.onChange(value);
    this.calculateScrollBarSize();
  }

  ngAfterViewInit() {
    setTimeout(() => this.calculateScrollBarSize());
  }

  public isEnabled: boolean = true;

  get showIcon(): boolean {
    return this.showIconsOnNullSelection || this.selectedValue !== null;
  }

  writeValue(value: Roles | null) {
    this.selectedValue = value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: Function) {}

  setDisabledState(isDisabled: boolean): void {
    this.isEnabled = !isDisabled;
  }

  hasMinimumLevel(role: Roles): boolean {
    if (!this.selectedValue) {
      return false;
    }

    const selectedLevel = roleLevel(this.selectedValue);
    const level = roleLevel(role);

    return selectedLevel >= level;
  }

  @ViewChild('radioManager', { static: true }) private radioManager!: ElementRef<HTMLDivElement>;
  @ViewChild('radioEditor', { static: true }) private radioEditor!: ElementRef<HTMLDivElement>;
  @ViewChild('radioViewer', { static: true }) private radioViewer!: ElementRef<HTMLDivElement>;
  @ViewChild('radioCaseCreator', { static: true }) private radioCaseCreator!: ElementRef<HTMLDivElement>;
  @ViewChild('radioMember', { static: true }) private radioMember!: ElementRef<HTMLDivElement>;
  @ViewChild('radioNone', { static: true }) private radioNone!: ElementRef<HTMLDivElement>;

  private calculateScrollBarSize() {
    // calculate positions of scroll bar top/bottom
    const radioRadius = this.radioManager.nativeElement.offsetHeight / 2; // assume all radios are same radius
    const top = this.radioManager.nativeElement.offsetTop + radioRadius;
    const bottom = this.radioNone.nativeElement.parentElement!.offsetHeight - this.radioNone.nativeElement.offsetTop - radioRadius;

    let selectionTop = this.radioNone.nativeElement.offsetTop + radioRadius; // same as None case - for null value
    switch (this.selectedValue) {
      case Roles.MANAGER:
        selectionTop = this.radioManager.nativeElement.offsetTop + radioRadius;
        break;
      case Roles.EDITOR:
        selectionTop = this.radioEditor.nativeElement.offsetTop + radioRadius;
        break;
      case Roles.VIEWER:
        selectionTop = this.radioViewer.nativeElement.offsetTop + radioRadius;
        break;
      case Roles.CASE_CREATOR:
        selectionTop = this.radioCaseCreator.nativeElement.offsetTop + radioRadius;
        break;
      case Roles.MEMBER:
        selectionTop = this.radioMember.nativeElement.offsetTop + radioRadius;
        break;
      case Roles.NONE:
        selectionTop = this.radioNone.nativeElement.offsetTop + radioRadius;
        break;
    }

    this.scrollBarTopBackground = `${top}px`;
    this.scrollBarBottom = `${bottom}px`;
    this.scrollBarTopSelection = `${selectionTop}px`;
  }
}
