import { environment } from '@env/environment';
import { Pipe, PipeTransform } from '@angular/core';

export enum AvatarType {
  PERSON = 'person',
  TEAM = 'team',
  DOCUMENT = 'document'
}

/*
 * Returns the input profile image if the image is defined
 * or the default question mark image if not
 */
@Pipe({ name: 'avatar' })
export class AvatarPipe implements PipeTransform {

  private readonly DEFAULT_IMAGE_FILE = '/images/node-blue.svg';

  public transform(image: string | null | undefined, type: AvatarType = AvatarType.PERSON): string {
    if (image) {
      return image;
    }

    const imageFile = this.getImagefile(type);
    return environment.baseAssetUrl + imageFile;
  }

  private getImagefile(type: AvatarType): string {
    if (!type) {
      return this.DEFAULT_IMAGE_FILE;
    }

    switch (type) {
      case AvatarType.TEAM:
        return '/images/logo-connect.png';
      case AvatarType.PERSON:
        return '/images/node-blue.svg';
      case AvatarType.DOCUMENT:
        return '/images/uploadid.png';
      default:
        return this.DEFAULT_IMAGE_FILE;
    }
  }
}
