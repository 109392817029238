import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CreateTaskComponent } from '../create-task/create-task.component';
import { DeleteTaskComponent } from '../delete-task/delete-task.component';
import { CompleteTaskComponent } from '../complete-task/complete-task.component';
import { TaskInfo } from '../../../../../generated/TaskInfo';
import { MatCheckboxDefaultOptions, MAT_CHECKBOX_DEFAULT_OPTIONS } from '@angular/material/checkbox';
import * as moment from 'moment';

@Component({
  selector: 'cok-task-item',
  templateUrl: './task-item.component.html',
  styleUrls: ['./task-item.component.scss'],
  providers: [{ provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: { clickAction: 'noop' } as MatCheckboxDefaultOptions }],
})
export class TaskItemComponent implements OnInit {
  @Input() task: TaskInfo;

  public isOverdue: boolean = false;
  public friendlyDueDate: string;

  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {
    if (new Date(this.task.dueDate) < new Date()) {
      this.isOverdue = true;
    }

    this.friendlyDueDate = moment(this.task.dueDate).format('MMMM D YYYY, hh:mm a');
  }

  public editTask() {
    this.dialog.open(CreateTaskComponent, { data: { task: this.task }, width: '650px', panelClass: 'user-task' });
  }

  public deleteTask() {
    this.dialog.open(DeleteTaskComponent, { data: { task: this.task }, width: '500px', panelClass: 'delete-task' });
  }

  public completeTask() {
    this.dialog.open(CompleteTaskComponent, { data: { task: this.task }, width: '500px', panelClass: 'complete-task' });
  }
}
