import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class IsFormDirtyService {
  public checkIfDirty(form: FormGroup) {
    for (const field in form.controls) {
      const control = form.get(field);

      if (control?.dirty) return true;
    }

    return false;
  }
}
