import { Routes } from '@angular/router';
import { AuthGuard } from './auth0/guards/auth.guard';
import { NotFoundPageComponent } from './core/containers/not-found-page';
import { SiteAdminGuard } from './auth0/guards/siteAdmin.guard';
import { TeamGuard } from './auth0/guards/team.guard';
import { BrowserSupportedGuard, UNSUPPORTED_PATH } from './browser-supported.guard';
import { UnsupportedBrowserComponent } from './core/components/unsupported-browser/unsupported-browser.component';
import { CanDeactivateSidebarGuard } from './person/guards/deactivate-sidebar.guard';
import { FamilySearchRoute } from './person/components/family-search-modal/fs-route.component';
import { UserTasksComponent } from './user/containers/user-tasks/user-tasks.component';
import { DashboardComponent } from './dashboard/dashboard.component';

export const routes: Routes = [
  {
    path: '',
    canActivateChild: [BrowserSupportedGuard],
    canDeactivate: [CanDeactivateSidebarGuard],
    children: [
      {
        path: UNSUPPORTED_PATH,
        component: UnsupportedBrowserComponent,
      },
      {
        path: 'dashboard', component: DashboardComponent,
        canActivate: [TeamGuard],
        canDeactivate: [CanDeactivateSidebarGuard],
      },
      {
        path: 'cases',
        loadChildren: () => import('./person/person.module').then((m) => m.PersonModule),
        canActivate: [TeamGuard],
        canDeactivate: [CanDeactivateSidebarGuard],
      },
      {
        path: 'user',
        loadChildren: () => import('./user/user.module').then((m) => m.UserModule),
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateSidebarGuard],
      },
      {
        path: 'admin',
        loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
        canActivate: [SiteAdminGuard],
        canDeactivate: [CanDeactivateSidebarGuard],
      },
      {
        path: 'reports',
        loadChildren: () => import('./reports/reports.module').then((m) => m.ReportsModule),
        canActivate: [TeamGuard],
        canDeactivate: [CanDeactivateSidebarGuard],
      },
      {
        path: 'billing',
        loadChildren: () => import('./billing/billing.module').then((m) => m.BillingModule),
        canActivate: [TeamGuard],
        canDeactivate: [CanDeactivateSidebarGuard],
      },
      {
        path: 'settings',
        loadChildren: () => import('./setting/setting.module').then((m) => m.SettingModule),
        canActivate: [TeamGuard],
        canDeactivate: [CanDeactivateSidebarGuard],
      },
      {
        path: 'callback/familysearch',
        component: FamilySearchRoute,
      },
      {
        path: 'error',
        loadChildren: () => import('./error/error.module').then((m) => m.ErrorModule),
      },
      {
        path: 'tasks', component: UserTasksComponent,
        canActivate: [TeamGuard],
        canDeactivate: [CanDeactivateSidebarGuard],
      },
      { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
      { path: '**', component: NotFoundPageComponent },
    ],
  }
];
