<div class="task-box" [class.completed]="task.isComplete">
  <div class="d-flex">
    <div class="p-4">
      <mat-checkbox class="example-margin task-checkbox" [checked]="task.isComplete" (click)="completeTask()"></mat-checkbox>
    </div>
    <div class="p-3 w-100">
      <div class="d-flex align-items-center">
        <div class="task-title">
          {{ task.title }} -
          <span [ngClass]="task.isComplete ? 'task-complete' : 'task-active'">
            <ng-container *ngIf="task.isComplete else notCompletedTemplate">
              completed<img src="../../../../../assets/images/complete-task-1.png">
              </ng-container>

              <ng-template #notCompletedTemplate>
                active
              </ng-template>
          </span>
        </div>
        <div class="ms-auto">
          <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu" class="action-menu">
            <button mat-menu-item (click)="editTask()">
              <span>Edit</span>
            </button>
            <button mat-menu-item (click)="deleteTask()">
              <span>Delete</span>
            </button>
          </mat-menu>
        </div>
      </div>

      <div class="task-desc">{{ task.description }}</div>
      <div class="task-type" [ngClass]="task.type === 'CASE' ? 'type-case' : 'type-personal'">{{ task.type[0] + task.type.substring(1).toLowerCase() }} Task</div>
      <div class="d-flex pt-3">
        <div class="task-date" *ngIf="task.dueDate">
          <div class="d-flex align-items-center" [class.overdue]="isOverdue">
            <mat-icon>access_time</mat-icon>Due date: {{ friendlyDueDate }}
          </div>
        </div>

        <div class="ms-auto task-assign">
          <span *ngIf="task.case"
            >Case: <span>{{ task.case?.person.fullName }}</span></span
          >
          <span *ngIf="task.case && task.relationship"> - </span>
          <span *ngIf="task.relationship"
            >Person: <span>{{ task.relationship?.person.fullName }}</span></span
          >
        </div>
      </div>
    </div>
  </div>
</div>
