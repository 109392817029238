import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { FamilySearchService } from '../../services/familysearch-sdk.service';
import { FS_REDIRECT_STORAGE_KEY, FS_JWT_STORAGE_KEY, FS_MODAL_STATUS_STORAGE_KEY } from '../../../shared/constants/storage-keys';

@Component({
  template: `
    <div style="position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);">
      <p style="margin-bottom: -10px;font-size: 18px; color: #333;">Redirecting...</p>
      <img src="../../../../assets/images/redirect.png" style="width: 100px;">
    </div>
  `,
  providers: [FamilySearchService]
})
export class FamilySearchRoute {
  constructor(
    private router: Router,
    private familySearchService: FamilySearchService,
  ) {
    const redirectPath = window.localStorage.getItem(FS_REDIRECT_STORAGE_KEY);
    if (redirectPath) {
      window.localStorage.removeItem(FS_REDIRECT_STORAGE_KEY);
      this.familySearchService.fs.oauthResponse(() => {
        const jwt = this.familySearchService.fs.jwt as string;
        window.localStorage.setItem(FS_JWT_STORAGE_KEY, jwt);
        // set session so we can open up the fs modal when we redirect to the case page
        window.sessionStorage.setItem(FS_MODAL_STATUS_STORAGE_KEY, 'open');
        this.router.navigateByUrl(redirectPath);
      });
    } else {
      this.router.navigateByUrl('');
    }
  }
}