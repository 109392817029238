<div class="this-box">
  <div class="background-line line" [style.top]="scrollBarTopBackground" [style.bottom]="scrollBarBottom"></div>
  <div class="selection-line line" [style.top]="scrollBarTopSelection" [style.bottom]="scrollBarBottom"></div>

  <div class="form-group" #radioManager>
    <label class="c-checkbox round">
      <input type="radio" [(ngModel)]="selectedValue" [value]="roles.MANAGER" [disabled]="isEnabled ? null : true" />
      <span class="checkmark"></span><span class="ms-1">Team Manager</span>
    </label>

    <div class="description">
      <fa-icon
        *ngIf="showIcon"
        [icon]="hasMinimumLevel(roles.MANAGER) ? faCheck : faTimes"
        [style.color]="hasMinimumLevel(roles.MANAGER) ? 'green' : 'red'"
      ></fa-icon>
      Add, remove, or edit members
    </div>
  </div>
  <div class="form-group" #radioEditor>
    <label class="c-checkbox round">
      <input type="radio" [(ngModel)]="selectedValue" [value]="roles.EDITOR" [disabled]="isEnabled ? null : true" />
      <span class="checkmark"></span><span class="ms-1">Team Editor</span>
    </label>

    <div class="description">
      <fa-icon
        *ngIf="showIcon"
        [icon]="hasMinimumLevel(roles.EDITOR) ? faCheck : faTimes"
        [style.color]="hasMinimumLevel(roles.EDITOR) ? 'green' : 'red'"
      ></fa-icon>
      Edit or remove cases
    </div>
  </div>
  <div class="form-group" #radioViewer>
    <label class="c-checkbox round">
      <input type="radio" [(ngModel)]="selectedValue" [value]="roles.VIEWER" [disabled]="isEnabled ? null : true" />
      <span class="checkmark"></span><span class="ms-1">Team Viewer</span>
    </label>

    <div class="description">
      <fa-icon
        *ngIf="showIcon"
        [icon]="hasMinimumLevel(roles.VIEWER) ? faCheck : faTimes"
        [style.color]="hasMinimumLevel(roles.VIEWER) ? 'green' : 'red'"
      ></fa-icon>
      View all case details
    </div>
  </div><div class="form-group" #radioCaseCreator>
    <label class="c-checkbox round">
      <input type="radio" [(ngModel)]="selectedValue" [value]="roles.CASE_CREATOR" [disabled]="isEnabled ? null : true" />
      <span class="checkmark"></span><span class="ms-1">Case Manager</span>
    </label>

    <div class="description">
      <fa-icon *ngIf="showIcon" [icon]="hasMinimumLevel(roles.CASE_CREATOR) ? faCheck : faTimes"
        [style.color]="hasMinimumLevel(roles.CASE_CREATOR) ? 'green' : 'red'"></fa-icon>
      Create cases and manage them
    </div>
  </div><div class="form-group" #radioMember>
    <label class="c-checkbox round">
      <input type="radio" [(ngModel)]="selectedValue" [value]="roles.MEMBER" [disabled]="isEnabled ? null : true" />
      <span class="checkmark"></span><span class="ms-1">Team Member</span>
    </label>

    <div class="description">
      <fa-icon *ngIf="showIcon" [icon]="hasMinimumLevel(roles.MEMBER) ? faCheck : faTimes"
        [style.color]="hasMinimumLevel(roles.MEMBER) ? 'green' : 'red'"></fa-icon>
      Use team's templates and billing accounts
    </div>
  </div>
  <div class="form-group" #radioNone>
    <label class="c-checkbox round">
      <input type="radio" [(ngModel)]="selectedValue" [value]="roles.NONE" [disabled]="isEnabled ? null : true" />
      <span class="checkmark"></span><span class="ms-1">No Team Permission</span>
    </label>
  </div>
</div>
