<section *ngIf="isMobileScreen" class="lyt-form not-found prevent-screen">
  <div class="inner">
    <a [routerLink]="['/']" class="logo"
      ><img src="./assets/images/logo-connect.png" alt="Connect Our Kids" /><span>Connect Our Kids</span></a
    >
    <div class="text-lable">
      This Family Connections web app is optimized for tablets, notebooks, and larger displays. Please maximize your screen. If you are on a
      mobile device, use the links below to install the mobile app.
    </div>

    <div class="badge-container">
      <div class="link-container">
        <a href="https://apps.apple.com/us/app/connect-our-kids/id1471038972?mt=8" target="_blank"
          ><img
            class="app-store-badge"
            src="https://linkmaker.itunes.apple.com/en-us/badge-lrg.svg?releaseDate=2020-05-02&kind=iossoftware&bubble=ios_apps"
        /></a>
      </div>
      <div class="link-container">
        <a href="https://play.google.com/store/apps/details?id=com.connectOurKids" target="_blank"
          ><img class="google-play-badge" src="https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png"
        /></a>
      </div>
    </div>
  </div>
</section>
