<div id="completeTask">
  <div mat-dialog-title>
    <div class="floating-icon">
      <img src="../../../../../assets/images/complete-task.png" />
    </div>
    <span>{{ isComplete ? 'Uncheck' : 'Complete' }} Task</span>
  </div>
  <mat-dialog-content class="mat-typography">
    <div class="modal-desc">Are you sure you want to move this to {{ isComplete ? 'Active' : 'Completed' }}?</div>
    <div class="modal-task-name">‘{{ task?.title }}’</div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-dialog-close class="btn btn-secondary me-auto">Cancel</button>
    <button class="btn btn-success" [class.disabled]="isLoading" (click)="completeTask()">{{ isComplete ? 'Uncheck' : 'Complete' }}</button>
  </mat-dialog-actions>
</div>
