import { NgModule } from '@angular/core';
import { environment } from '@env/environment';
import { UserService } from './auth0/services/user.service';
import { ConfigService } from './auth0/services/config.service';

declare let window: any;

@NgModule({
  imports: [],
  declarations: [],
  exports: [],
  providers: [],
})
export class IntercomModule {
  
  constructor(private userService: UserService, private configService: ConfigService) {
    if (!environment.intercom.enable) {
      return;
    }

    this.configService.getConfig().subscribe((config) => {
      if (!config.intercomEnabled) {
        return;
      }

      window.intercomSettings = {
        app_id: environment.intercom.appId,
        alignment: 'left',
      };

      (function () {
        const w = window;
        const ic = w.Intercom;
        if (typeof ic === 'function') {
          ic('reattach_activator');
          ic('update', w.intercomSettings);
        } else {
          const d = document;
          const i: any = function () {
            i.c(arguments);
          };
          i.q = [];
          i.c = function (args: any) {
            i.q.push(args);
          };
          w.Intercom = i;
          const l = function () {
            const s = d.createElement('script');
            s.type = 'text/javascript';
            s.async = true;
            s.src = 'https://widget.intercom.io/widget/' + environment.intercom.appId;
            const x: any = d.getElementsByTagName('script')[0];
            x.parentNode.insertBefore(s, x);
          };
          if (document.readyState === 'complete') {
            l();
          } else if (w.attachEvent) {
            w.attachEvent('onload', l);
          } else {
            w.addEventListener('load', l, false);
          }
        }
      })();
    });
  }
}
