import { Injectable } from "@angular/core";
import { Observable, ReplaySubject, take } from "rxjs";

export type EnvironmentConfig = {
  instanceName:string;
  auth0: {
    issuer: string;
    clientID: string;
    audience: string;
  },
  yfiles: {                
    company: string,
    contact: string,
    date: string,
    distribution: boolean,
    domains: string[],
    email: string,
    fileSystemAllowed: boolean,
    licensefileversion: string,
    localhost: boolean,
    oobAllowed: boolean,
    package: string,
    product: string,
    projectname: string,
    subscription: string,
    type: string,
    version: string,
    watermark: string,
    key: string,
  },
  domain: string;
  mixpanelToken: string | undefined;
  googleMapAPIKey: string;
  mixpanelEnabled: boolean;
  intercomEnabled: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class ConfigService {

    private _environmentConfig: EnvironmentConfig;
    private environmentConfig = new ReplaySubject<EnvironmentConfig>(0);

    constructor() {
        this.getEnvironmentConfigFromServer();
    }

    public async getEnvironmentConfigFromServer(): Promise<EnvironmentConfig> {

        if(this._environmentConfig)
        return this._environmentConfig;

        const response = await fetch('/api/environment');
        const environmentConfig = await response.json() as EnvironmentConfig;

        this._environmentConfig = environmentConfig;
        
        this.environmentConfig.next(environmentConfig);

        return environmentConfig;
    }

    public getConfigSync(): EnvironmentConfig {
        if(!this._environmentConfig)
        throw new Error('Environment config not loaded');

        return this._environmentConfig;
    }

    public getConfig(): Observable<EnvironmentConfig> {
      return this.environmentConfig.asObservable().pipe(take(1));
    }
}