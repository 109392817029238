import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'cok-not-found-page',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <section class="lyt-form not-found">
      <div class="inner">
        <a [routerLink]="['/']" class="logo"
          ><img src="./assets/images/logo-connect.png" alt="Connect Our Kids" /><span>Connect Our Kids</span></a
        >
        <div class="text-lable">The page you requested does not exist.</div>

        <p>Navigate back to <a routerLink="/">Home</a></p>
      </div>
    </section>
  `,
  styles: [
    `
      :host {
        text-align: center;
      }
    `,
  ],
})
export class NotFoundPageComponent {}
