import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'fileIcon' })
export class FileIconPipe implements PipeTransform {
  readonly icons = ['doc', 'exe', 'mp4', 'pdf', 'ppt', 'xls', 'zip'];

  check(ext: string, subs: Array<string>) {
    if (this.icons.includes(ext)) {
      return ext;
    }
    for (let sub of subs) {
      let newExt = ext.substr(0, ext.length - sub.length);
      if (ext.slice(-sub.length) === sub && this.icons.includes(newExt)) {
        return newExt;
      }
    }
    return 'file';
  }

  transform(fileName: string) {
    if (fileName) {
      const ext = fileName.split('.').pop().toLocaleLowerCase();

      // Check for .doc, xls as well as docx, xlsx...
      let iconName: string = this.check(ext, ['x']);

      return `icon-${iconName}`;
    }
  }
}
