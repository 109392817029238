<div id="createTask">
  <div mat-dialog-title>
    <div class="floating-icon">
      <img src="../../../../../assets/images/create-task.png" />
    </div>
    <div>{{ task ? 'Update Task' : 'Create Task' }}</div>
  </div>
  <mat-dialog-content class="mat-typography">
    <form [formGroup]="form">
      <div class="modal-desc">Choose below, which type of task you want to create?</div>
      <label class="radio-btn-wrapper">
        <input [value]="false" name="isCaseTask" type="radio" formControlName="isCaseTask" />
        <span>Personal Task</span>
      </label>
      <label class="radio-btn-wrapper">
        <input [value]="true" name="isCaseTask" type="radio" formControlName="isCaseTask" />
        <span>Case Task</span>
      </label>
      <div class="row case-task-div" [class.show]="isCaseTask">
        <div class="col-md-6">
          <div class="modal-desc">Select Case *</div>
          <div class="styled-select">
            <ng-select
              [formControl]="caseChild"
              bindValue="id"
              bindLabel="fullName"
              placeholder="Case"
              [items]="cases$ | async"
              notFoundText="No cases found"
              (clear)="onCaseCleared()"
              (change)="onCaseChanged($event)"
              [class.has-error]="hasError(caseChild)"
            >
              <ng-template ng-option-tmp let-item="item">
                <div class="select-avatar"><img [src]="item.picture | avatar" alt="Case image" /></div>
                {{ item.fullName }}
              </ng-template>
              <ng-template ng-label-tmp let-item="item">
                <div class="select-avatar"><img [src]="item.picture | avatar" alt="Case image" /></div>
                {{ item.fullName }}
              </ng-template>
            </ng-select>
          </div>
          <div *ngIf="hasError(caseChild)">
            <p class="maxsize-error mt-1">Case required</p>
          </div>
        </div>

        <div class="col-md-6">
          <div class="modal-desc">Select Relationship</div>
          <div class="styled-select">
            <ng-select
              [formControl]="relationship"
              bindValue="id"
              bindLabel="fullName"
              placeholder="Relationship"
              [items]="relationships"
              notFoundText="No relationships found"
              (clear)="onRelationshipCleared()"
              (change)="onRelationshipChanged($event)"
            >
              <ng-template ng-option-tmp let-item="item">
                <div class="select-avatar"><img [src]="item.picture | avatar" alt="Relationship image" /></div>
                {{ item.fullName }}
              </ng-template>
              <ng-template ng-label-tmp let-item="item">
                <div class="select-avatar"><img [src]="item.picture | avatar" alt="Relationship image" /></div>
                {{ item.fullName }}
              </ng-template>
            </ng-select>
          </div>
        </div>
      </div>

      <div class="bottom-div">
        <div class="modal-desc mb-4">Please fill in all the info for the task</div>
        <div class="row">
          <div class="col-md-4">
            <div class="modal-desc">Title*</div>
          </div>
          <div class="col-md-8">
            <input class="modal-input" placeholder="Task title" [formControl]="title" [class.has-error]="hasError(title)" />
            <div *ngIf="hasError(title)">
              <p *ngIf="title.errors" class="maxsize-error">Title required</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="modal-desc">Description</div>
          </div>
          <div class="col-md-8">
            <textarea class="modal-input textarea-expanded" placeholder="Task description" [formControl]="description"></textarea>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="modal-desc">Due date</div>
          </div>
          <div class="col-md-8">
            <mat-form-field appearance="fill" class="date-time-picker" floatLabel="never">
              <input
                type="text"
                class="form-control"
                tabindex="-1"
                matInput
                [ngxMatDatetimePicker]="datePicker"
                placeholder="Choose a date"
                [formControl]="dueDate"
                [class.has-error]="hasError(dueDate)"
                (click)="datePicker.open()"
              />
              <mat-datepicker-toggle matSuffix [for]="datePicker" class="date-select calendar-icon"></mat-datepicker-toggle>
              <ngx-mat-datetime-picker #datePicker [showSeconds]="false" [enableMeridian]="true"></ngx-mat-datetime-picker>
            </mat-form-field>
          </div>
        </div>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-dialog-close class="btn btn-secondary me-auto">Cancel</button>
    <button class="btn btn-primary" [class.disabled]="isLoading" (click)="submit()">{{ task ? 'Update' : 'Create' }}</button>
  </mat-dialog-actions>
</div>
