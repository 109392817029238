import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AuthService } from '../auth0/services/auth.service';

@Component({
  selector: 'cok-verify-email',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `

    <section class="lyt-form not-found">
      <div class="inner">
        <a href="/" class="logo"
          ><img src="./assets/images/logo-connect.png" alt="Connect Our Kids" /><span>Connect Our Kids</span></a
        >        <div class="text-lable">Please check your email to verify your account. After you have verified your account, you can login.</div>

        <button class="btn btn-primary" (click)="loginClick()">Try Login Again</button>
        </div>
    </section>

  `,
  styles: [
      `
        :host {
          text-align: center;
        }
      `,
    ]
})
export class VerifyEmailComponent implements OnInit {

  constructor(
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
  }

  loginClick() {
    this.authService.login();
  }

}
