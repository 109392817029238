import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'replace'})
export class ReplacePipe implements PipeTransform {

  public transform(value: string, textToReplace: string, replacementText: string): string {

    if (!value || !textToReplace || !replacementText) {
      return value;
    }

    const patternToReplace = new RegExp(textToReplace, 'g');
    return value.replace(patternToReplace, replacementText);
  }
}
