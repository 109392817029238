import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Component, forwardRef, ViewChild, Input, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BsDatepickerDirective } from 'ngx-bootstrap/datepicker';
import * as moment from 'moment';
import * as _ from 'lodash';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import IMask from 'imask';
/// REFER DOC: https://valor-software.com/ngx-bootstrap/old/2.0.5/#/datepicker#bs-daterangepicker

@Component({
  selector: 'input-date-mask-control',
  templateUrl: './input-date-mask-control.component.html',
  styleUrls: ['./input-date-mask-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputDateMaskControlComponent),
      multi: true,
    },
  ],
})
export class InputDateMaskControlComponent implements ControlValueAccessor {
  public maskConfig = {
    mask: Date,
    pattern: 'MM/DD/YYYY',
    lazy: false,
    format: (date: any) => {
      return moment(date).utc().format('MM/DD/YYYY');
    },
    parse: (str: any) => {
      return moment(str, 'MM/DD/YYYY');
    },
    blocks: {
      YYYY: {
        mask: IMask.MaskedRange,
        from: 1900,
        to: 2227,
      },
      MM: {
        mask: IMask.MaskedRange,
        from: 1,
        to: 12,
      },
      DD: {
        mask: IMask.MaskedRange,
        from: 1,
        to: 31,
      },
    },
  };
  public faCalendarAlt = faCalendarAlt;

  public disabled: boolean = false;
  private onChangeFn: any = (_: any) => {};
  private onTouchedFn: any = (_: any) => {};

  public touchedStateSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public focusInStateSubject = new BehaviorSubject<boolean>(false);

  @ViewChild('datePicker', { static: true })
  datePicker: BsDatepickerDirective;

  public displayValue: string = '';
  public value: Date | null = null;
  public datePickerValue: Date = new Date();

  @Input('placeholder')
  placeholder: string = '';

  @Input('impreciseDate')
  impreciseDate: boolean = false;

  @Output()
  onValueChanged = new EventEmitter<any>();

  @Input()
  minDate?: Date;

  @Input()
  maxDate?: Date;

  private counter: number = 0;

  constructor(private detector: ChangeDetectorRef) {}

  public validDate: any[] = [];
  public hideAutocomplete: String = 'hide';

  public onSelect(item: any) {
    this.onChangeFn(item.value);
    this.onValueChanged.emit(item.value);
    this.focusInStateSubject.next(true);
    this.displayValue = item.display;
    this.value = item.value;
    this.hideAutocomplete = 'hide';
  }

  public focus($event: any): void {
    this.focusInStateSubject.next(true);
  }

  public select(): void {}

  public blur($event: any): void {
    const dateString: string = $event.target.value;
    const displayDate = moment.utc(dateString, 'MM/DD/YYYY').toDate();
    if (this.value !== displayDate) {
      this.value = displayDate;
      this.onChangeFn(dateString);
    }
    this.touchedStateSubject.next(true);
    this.focusInStateSubject.next(false);
  }

  registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedFn = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  transformDate(date: string) {
    const dateArray = this.getDateList(date);
    if (dateArray.length === 3) {
      this.value = moment.utc(date, 'YYYY/MM/DD').toDate();
      return moment.utc(date, 'YYYY/MM/DD').format('MM/DD/YYYY');
    }
    if (dateArray.length === 2) {
      if (dateArray[0].length === 4) return `${dateArray[1]}/${dateArray[0]}`;
    }
    return date;
  }

  writeValue(obj: any) {
    if (this.impreciseDate) {
      if (typeof obj === 'string') {
        this.displayValue = this.transformDate(obj);
      }
    } else {
      if (obj && moment.utc(obj, 'MM/DD/YYYY').format('MM/DD/YYYY') !== 'Invalid date') {
        let str = moment.utc(obj, 'MM/DD/YYYY').format('MM/DD/YYYY');
        this.displayValue = str;
        this.value = moment(obj).utc().toDate();
      } else {
        this.value = null;
        this.displayValue = '';
      }
    }
  }

  public inputFocus($event: any) {
    this.datePicker.hide();
  }

  public lostFocus($event: any) {
    if (this.impreciseDate) {
      if (this.validDate.length) {
        const date = this.validDate[0];
        this.displayValue = date.display;
        this.value = date.value;
        this.emitChanges(date.value);
      } else {
        if (this.displayValue) {
          this.emitChanges('invalid date');
        } else {
          this.emitChanges(null);
        }
      }
    } else {
      const dateString: string = $event.target.value;
      const parsedDate = moment(dateString, 'MM/DD/YYYY').utc();
      this.value = parsedDate.isValid() ? parsedDate.toDate() : null;
      this.displayValue = parsedDate.isValid() ? parsedDate.format('MM/DD/YYYY') : '';
      this.emitChanges(this.value);
      this.detector.markForCheck();
    }
  }

  public emitChanges(event: any) {
    this.onChangeFn(event);
    this.onValueChanged.emit(event);
    this.focusInStateSubject.next(true);
  }

  // REF: https://github.com/valor-software/ngx-bootstrap/issues/2825
  // this is a limitation of version
  // this is a work-around solution
  public bsDateValueChange($event: any) {
    this.counter += 1;
    if (this.counter > 1) {
      let str = '';
      const momentState = moment.utc($event, 'MM/DD/YYYY').format('MM/DD/YYYY');

      if (this.impreciseDate) {
        if ($event && typeof $event === 'object' && momentState !== 'Invalid date') {
          str = moment.utc($event, 'YYYY/MM/DD').format('YYYY/MM/DD');
          this.value = $event;
          this.displayValue = momentState;
          this.emitChanges(str);
        }
      } else {
        if ($event && momentState !== 'Invalid date') {
          str = moment.utc($event, 'MM/DD/YYYY').format('MM/DD/YYYY');
          this.value = $event;
        } else {
          this.value = null;
        }
        this.displayValue = str;
        this.emitChanges(this.value);
      }
    }
  }

  public onClickOutside($event: any) {
    this.hideAutocomplete = 'hide';
    this.datePicker.hide();
  }

  public inputTabKeydown($event: any) {
    this.datePicker.hide();
  }

  public getYear(dateList: any[]) {
    let year = '';
    let isValid = false;
    dateList.forEach((date) => {
      if (date && Number(date) > 1800 && Number(date) <= new Date().getFullYear()) {
        year = date;
        dateList.splice(dateList.indexOf(date), 1);
        isValid = true;
      }
    });
    return { isValid, year, dateList };
  }

  public getFullDate(dateArray: any[]) {
    const defaultMonth = dateArray[1];
    const { isValid, year, dateList } = this.getYear(dateArray);
    const newList = [];
    dateList.splice(dateList.indexOf(defaultMonth), 1);
    const month = Number(defaultMonth);
    const day = Number(dateList[0]);
    if (isValid) {
      if (month <= 12 && day <= 31) {
        newList.push({
          label: moment.utc(`${year}/${defaultMonth}/${dateList[0]}`, 'YYYY/MM/DD').format('MMMM Do YYYY'),
          value: `${year}/${defaultMonth}/${dateList[0]}`,
          display: `${defaultMonth}/${dateList[0]}/${year}`,
        });
      }
      if (month <= 31 && day <= 12) {
        newList.push({
          label: moment.utc(`${year}/${dateList[0]}/${defaultMonth}`, 'YYYY/MM/DD').format('MMMM Do YYYY'),
          value: `${year}/${dateList[0]}/${defaultMonth}`,
          display: `${dateList[0]}/${defaultMonth}/${year}`,
        });
      }
    }
    this.validDate = newList;
  }

  public getDateList(value: String) {
    let dateArray;
    const newList: any[] = [];
    if (value.includes('-')) {
      dateArray = value.split('-');
    } else if (value.includes('/')) {
      dateArray = value.split('/');
    } else {
      dateArray = [value];
    }

    dateArray.forEach((date) => {
      if (date) newList.push(date);
    });
    return newList;
  }

  getDayAndMonth(value1: any, value2: any, dateArray: any[]) {
    if (Number(value1) > 0 && Number(value2) > 0) {
      if (Number(value1) <= 12 && Number(value2) <= 12) {
        dateArray = dateArray.concat([
          {
            label: moment.utc(`${value1}/${value2}`, 'MM/DD').format('Do MMMM'),
            value: `${value1}/${value2}`,
            display: `${value1}/${value2}`,
          },
          {
            label: moment.utc(`${value2}/${value1}`, 'MM/DD').format('Do MMMM'),
            value: `${value2}/${value1}`,
            display: `${value2}/${value1}`,
          },
        ]);
      } else if (Number(value1) <= 12 && Number(value2) >= 31) {
        dateArray.push({
          label: moment.utc(`${value1}/${value2}`, 'MM/DD').format('Do MMMM'),
          value: `${value1}/${value2}`,
          display: `${value1}/${value2}`,
        });
      } else if (Number(value1) <= 31 && Number(value2) >= 12) {
        dateArray.push({
          label: moment.utc(`${value2}/${value1}`, 'MM/DD').format('Do MMMM'),
          value: `${value2}/${value1}`,
          display: `${value2}/${value1}`,
        });
      }
    }
    return dateArray;
  }

  public getPossibleDates(dates: any[]) {
    let year1 = moment.utc(dates[0], 'YY').format('YYYY');
    let year2 = moment.utc(dates[1], 'YY').format('YYYY');
    const validateYear2 = this.getYear([year2]);
    const validateYear1 = this.getYear([year1]);

    let dateArray: any = [];
    if (
      validateYear2.isValid &&
      Number(dates[0]) <= 12 &&
      Number(dates[0]) > 0 &&
      validateYear1.isValid &&
      Number(dates[1]) <= 12 &&
      Number(dates[1]) > 0
    ) {
      dateArray = [
        {
          label: moment.utc(`${validateYear2.year}/${dates[0]}`, 'YYYY/MM').format('MMMM YYYY'),
          value: `${validateYear2.year}/${dates[0]}`,
          display: `${dates[0]}/${validateYear2.year}`,
        },
        {
          label: moment.utc(`${validateYear1.year}/${dates[1]}`, 'YYYY/MM').format('MMMM YYYY'),
          value: `${validateYear1.year}/${dates[1]}`,
          display: `${dates[1]}/${validateYear1.year}`,
        },
      ];
    } else if (validateYear2.isValid && Number(dates[0]) <= 12 && Number(dates[0]) > 0) {
      dateArray = [
        {
          label: moment.utc(`${validateYear2.year}/${dates[0]}`, 'YYYY/MM').format('MMMM YYYY'),
          value: `${validateYear2.year}/${dates[0]}`,
          display: `${dates[0]}/${validateYear2.year}`,
        },
      ];
    } else if (validateYear1.isValid && Number(dates[1]) <= 12 && Number(dates[1]) > 0) {
      dateArray = [
        {
          label: moment.utc(`${validateYear1.year}/${dates[1]}`, 'YYYY/MM').format('MMMM YYYY'),
          value: `${validateYear1.year}/${dates[1]}`,
          display: `${dates[1]}/${validateYear1.year}`,
        },
      ];
    }

    this.validDate = _.uniqBy(this.getDayAndMonth(dates[0], dates[1], dateArray), 'label');
  }

  public generateValidDate(value: String) {
    const dateArray = this.getDateList(value);
    if (dateArray.length === 3) {
      this.getFullDate(dateArray);
    } else if (dateArray.length === 2) {
      const { isValid, year, dateList } = this.getYear(dateArray);
      if (isValid) {
        const month = Number(dateList[0]);
        if (month <= 12 && month > 0) {
          this.validDate = [
            {
              label: moment.utc(`${year}/${dateList[0]}`, 'YYYY/MM').format('MMMM YYYY'),
              value: `${year}/${dateList[0]}`,
              display: `${dateList[0]}/${year}`,
            },
          ];
        }
      } else {
        this.getPossibleDates(dateArray);
      }
    } else if (dateArray.length === 1) {
      let yearInput = dateArray[0];
      const newValidDate = [];

      if (dateArray[0].length === 2) {
        yearInput = moment.utc(yearInput, 'YY').format('YYYY');
      }
      const { isValid, year } = this.getYear([yearInput]);

      if (isValid) {
        newValidDate.push({ label: moment.utc(year, 'YYYY/MM').format('YYYY'), value: year, display: year });
      }

      if (Number(dateArray[0]) <= 12 && Number(dateArray[0]) > 0) {
        newValidDate.push({ label: moment.utc(dateArray[0], 'MM').format('MMMM'), value: `${dateArray[0]}`, display: `${dateArray[0]}` });
      }

      this.validDate = newValidDate;
    } else {
      this.validDate.length = 0;
    }
  }

  public inputChanged($event: any) {
    this.displayValue = $event.target.value;
    if (this.impreciseDate) {
      this.hideAutocomplete = '';
      this.generateValidDate($event.target.value);
    } else {
      this.onValueChanged.emit(this.displayValue);
      this.focusInStateSubject.next(true);
    }
  }

  public toggle($event: any) {
    this.datePickerValue = this.displayValue === '' ? new Date() : new Date(this.displayValue);
    this.datePicker.bsValue = this.datePickerValue;
    this.datePicker.toggle();
  }

  public isInvalid(): boolean {
    return this.isOldDate() || this.isFutureDate();
  }

  public isOldDate(): boolean {
    if (!this.minDate) return false;
    return moment(this.displayValue).toDate() < this.minDate;
  }

  public isFutureDate(): boolean {
    if (!this.maxDate) return false;
    return moment(this.displayValue).toDate() > this.maxDate;
  }

}
