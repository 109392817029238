import { Component, Inject } from '@angular/core';
import { CountdownEvent } from 'ngx-countdown/public_api';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RelationshipStatus } from '../../../../../generated/RelationshipStatus';
import { RelationshipStatusService } from '../../../../person/services/relationship-status.service';
import { RelationshipStatusAdminService } from '../../../../admin/service/relationship-status-admin.service';

export interface DeleteRelationshipStatusModalData {
  teamId: number;
  relationshipStatus: RelationshipStatus;
  isGlobalStatus: boolean;
}

@Component({
  selector: 'delete-relationship-status-modal',
  templateUrl: './delete-relationship-status-modal.component.html',
  styleUrls: [],
})
export class DeleteRelationshipStatusModalComponent {

  public isBusy: boolean = false;
  public isDeleteDisabled: boolean = true;
  public isFinalConfirmation = false;
  public countdownDone = false;

  constructor(
    private dialogRef: MatDialogRef<DeleteRelationshipStatusModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DeleteRelationshipStatusModalData,
    private relationshipStatusService: RelationshipStatusService,
    private relationshipStatusAdminService: RelationshipStatusAdminService,
  ) {
  }

  public countdownEvent($event: CountdownEvent): void {
    if ($event.action === 'done') {
      this.countdownDone = true;
    }
  }

  public transitionToFinalConfirmation(): void {
    this.isFinalConfirmation = true;
    this.dialogRef.updateSize('400px');
  }

  public setDeleteDisabled(nameConfirmation: String) {
    this.isDeleteDisabled = this.data.relationshipStatus.name.toLowerCase().trim() !== nameConfirmation.toLowerCase().trim();
  }

  public submit(): void {
    this.isBusy = true;

    const deletionObservable = this.data.isGlobalStatus ?
      this.relationshipStatusAdminService.deleteRelationshipStatus(this.data.relationshipStatus.id) :
      this.relationshipStatusService.deleteRelationshipStatus(this.data.teamId, this.data.relationshipStatus.id)
    
    deletionObservable.subscribe((result) => {
        if (result) {
          this.isBusy = false;
          this.dialogRef.close(true);
        }
      });
  }
}
