<ng-template #maxFileSizeModal>
  <div class="modal-header">
    <h5 class="modal-title">Invalid Image</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()">
    </button>
  </div>
  <div class="modal-body">
    The uploaded image size is too large, it must be <= 25 MB
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="closeModal()">OK</button>
  </div>
</ng-template>

<ng-template #invalidExtensionModal>
  <div class="modal-header">
    <h5 class="modal-title">Invalid Image</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()">
    </button>
  </div>
  <div class="modal-body">
    Invalid Extension. Only jpg, jpeg, gif, and png are supported.
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="closeModal()">OK</button>
  </div>
</ng-template>

<ng-template #invalidImageModal>
  <div class="modal-header">
    <h5 class="modal-title">Invalid Image</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()">
    </button>
  </div>
  <div class="modal-body">
    Image is corrupt or has an unknown error.
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="closeModal()">OK</button>
  </div>
</ng-template>

<div class="box-upload show-review-avatar">
  <div data-provides="fileupload" [class.fileupload]="isEnabled">
    <span class="btn btn-file" *ngIf="isEnabled">
      <fa-icon [icon]="faImage" size="1x"></fa-icon><span class="text">{{ previewUrl ? 'Change' : 'Add' }} Photo</span>
      <input accept=".jpg,.jpeg,.gif,.png" name="pic" type="file" (change)="showPreviewImage($event)" />
    </span>
    <img [src]="previewUrl | avatar: avatarType" class="review-avatar" [class.not-allow-upload]="!isEnabled" />
  </div>
</div>
