<div class="cok-input" [delayClickOutsideInit]="true" [attachOutsideOnClick]="true" (clickOutside)="onClickOutside()"
  (scroll)="onScrollEvent()">
  <div>
    <!--suppress HtmlFormInputWithoutLabel -->
    <input type="text" class="form-control" [ngClass]="{'is-invalid' : isInvalid()}" #displayDatetimeElement
      [disabled]="this.disabled" [ngModel]="displayValue"
      (focus)="inputFocus($event)" (blur)="blur($event)" [placeholder]="placeholder" (input)="inputChanged($event)"
      (keydown.tab)="inputTabKeydown()" />
  </div>
  <div class="input-date-hidden">
    <!--suppress HtmlFormInputWithoutLabel -->
    <input type="text" class="form-control" [disabled]="this.disabled" [isDisabled]="this.disabled"
      #datePicker="bsDatepicker" bsDatepicker [outsideClick]="false" tabindex="-1"
      (bsValueChange)="bsDateValueChange($event)" [bsValue]="bsDatePickerValue" [bsConfig]="dateConfig"
      [maxDate]="maxDate" [minDate]="minDate" [placement]="placement" />
  </div>
  <div class="calendar-icon">
    <span class="bs-datepicker-icon" (click)="datePicker.toggle()">
      <fa-icon [icon]="faCalendarAlt"></fa-icon>
    </span>
  </div>
</div>
<!-- Errors -->
<div>
  <p class="maxsize-error mt-1 mb-1" *ngIf="isOldDate()">Date must be on or after {{ minDate | date: 'MM/dd/yyyy' }}</p>
  <p class="maxsize-error mt-1 mb-1" *ngIf="isFutureDate()">Date must be on or before {{ maxDate | date: 'MM/dd/yyyy' }}
  </p>
</div>