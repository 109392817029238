<mat-card class="custom-mat-card" [class.d-none]="hideBundleNotification">
  <mat-card-header>
    <div mat-card-avatar>
      <img src="../../assets/images/icons/social/expired-bundle.png" alt="Notification Image">
    </div>
    <mat-card-title>Billing notification</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <p *ngIf="notification.notificationType === BillingBundleNotificationType.EMPTY_BUNDLE"
      [innerHTML]="bundleIsEmptyText"></p>
    <p *ngIf="notification.notificationType === BillingBundleNotificationType.BUNDLE_EXPIRING_IN_A_WEEK"
      [innerHTML]="bundleWillExpireInAWeekText"></p>
    <p *ngIf="notification.notificationType === BillingBundleNotificationType.BUNDLE_EXPIRED"
      [innerHTML]="bundleExpiredText"></p>
  </mat-card-content>
  <mat-card-actions align="end">
    <button class="btn btn-cancel" (click)="hideNotification()">Cancel</button>
    <button class="btn btn-info" (click)="gotToBundles()">Visit page</button>
  </mat-card-actions>
</mat-card>
