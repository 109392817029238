
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "Engagement": [
      "EngagementCall",
      "EngagementDocument",
      "EngagementEmail",
      "EngagementNote",
      "EngagementOther",
      "EngagementReminder"
    ],
    "EngagementCommon": [
      "EngagementCall",
      "EngagementDocument",
      "EngagementEmail",
      "EngagementNote",
      "EngagementOther",
      "EngagementReminder"
    ]
  }
};
      export default result;
    