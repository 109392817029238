import { gql } from 'apollo-angular';

export const TEAM_ATTRIBUTE_DETAIL = gql`
  fragment TeamAttributeDetail on TeamAttribute {
    id
    order
    name
    type
    defaultValue
    options
    disabled
    isChildBased
  }
`;
