import { Pipe, PipeTransform } from '@angular/core';
import { EnumExports } from './../constants/enum-export';

@Pipe({ name: 'printCase' })
export class PrintCasePipe implements PipeTransform {
  readonly printCaseEnum: any = EnumExports.OPTIONS_ENUM;

  transform(options: Array<number>) {
    const dimension = '&nbsp;&#9679;&nbsp;';
    let valueOptions: Array<string> = options.map((item: any) => this.printCaseEnum[item.toString()]);
    let value = valueOptions.join(dimension);

    return value;
  }
}
