<div class="container-fluid">
  <perfect-scrollbar>
    <div class="first-wrapper">
      <div class="row">
        <div class="col-lg-4 col-md-12">
          <div class="welcome-wrapper d-flex align-items-center">
            <div class="d-flex flex-row bd-highlight">
              <div class="py-2 bd-highlight">
                <img src="../../assets/images/welcome-dog.svg">
              </div>
              <div class="p-2 bd-highlight">
                <h5>Welcome, {{ user.firstName }}!</h5>
                <p>Welcome to your dashboard. Here you will see key features and your cases information at a quick glance. If you want to learn more about this dashboard, check out <a href="https://help.connectourkids.org/en/articles/6937518-dashboard-feature">this article.</a></p>
              </div>
            </div>
          </div>
        </div> 
        <div class="col-lg-8 col-md-12">
          <div class="task-wrapper">
            <h5 class="d-flex align-items-center">Tasks</h5>
            <div
              *ngIf="!tasksLoading"
              class="row">
              <div class="col-md-6">
                <div class="task-box">
                  <div class="active-task">
                    <img src="../../assets/images/reminder-blue.png">
                    Active Tasks
                    <span>{{ activeTasksCount }}</span>
                    <div class="progress">
                      <div
                        class="progress-bar"
                        role="progressbar"
                        [ngStyle]="{ width: activeTasksCountPercentage + '%' }"
                        [attr.aria-valuenow]="activeTasksCountPercentage"
                        aria-valuemin="0"
                        aria-valuemax="100"></div>
                    </div>
                  </div>
                  <div class="complete-task">
                    <img src="../../assets/images/complete-task-1.png">
                    Completed Tasks
                    <span>{{ completedTasksCount }}</span>
                    <div class="progress">
                      <div
                        class="progress-bar"
                        role="progressbar"
                        [ngStyle]="{ width: completedTasksCountPercentage + '%' }"
                        [attr.aria-valuenow]="completedTasksCountPercentage"
                        aria-valuemin="0"
                        aria-valuemax="100"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="task-box">
                  <div class="personal-task">
                    <img src="../../assets/images/personal-task.png">
                    Personal Tasks
                    <span>{{ personalTasksCount }}</span>
                    <div class="progress">
                      <div
                        class="progress-bar"
                        role="progressbar"
                        [ngStyle]="{ width: personalTasksPercentage + '%' }"
                        [attr.aria-valuenow]="personalTasksPercentage"
                        aria-valuemin="0"
                        aria-valuemax="100"></div>
                    </div>
                  </div>
                  <div class="case-task">
                    <img src="../../assets/images/activity-documents.svg">
                    Case Tasks
                    <span>{{ caseTasksCount }}</span>
                    <div class="progress">
                      <div
                        class="progress-bar"
                        role="progressbar"
                        [ngStyle]="{ width: caseTasksCountPercentage + '%' }"
                        [attr.aria-valuenow]="caseTasksCountPercentage"
                        aria-valuemin="0"
                        aria-valuemax="100"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-12">
                <div class="task-link">
                  <a [routerLink]="['/tasks']">
                    View More
                    <mat-icon>keyboard_arrow_right</mat-icon>
                  </a>
                </div>
              </div>
            </div>

            <div *ngIf="tasksLoading">
              <div class="row">
                <div
                  class="col-md-6"
                  *ngFor="let x of [1,2]">
                  <div class="task-box">
                    <div class="skeleton skeleton-task"></div>
                    <div
                      class="skeleton skeleton-task"
                      style="margin-bottom: 0px;"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="dropdown-wrapper">
      <div class="row">
        <div class="col-md-12">
          <div class="d-flex flex-row-reverse">
            <div class="bd-highlight">
              <ng-select
                placeholder="Team"
                class="task-select"
                [items]="userTeams"
                [ngModel]="teamId"
                bindValue="id"
                bindLabel="name"
                (change)="updateSelectedTeam($event?.id)"
                [closeOnSelect]="true"
                [searchable]="false"
                appendTo="body">
                <ng-template ng-option-tmp let-team="item">
                <img id="teamImg" src="{{ team.picture ? team.picture : DEFAULT_TEAM_IMAGE}}" alt="Team image">
                <ng-option>{{ team.name }}</ng-option>
              </ng-template>
                <ng-template ng-label-tmp let-team="item">
                  <img id="labelImg" src="{{ team.picture ? team.picture : DEFAULT_TEAM_IMAGE}}" alt="Label image" />
                  {{ team.name }}
                </ng-template>

              </ng-select>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ng-container *ngIf="hasPermissions">
      <div class="case-wrapper">
        <div class="border-wrapper">
          <div class="case-title">Cases</div>
          <ng-container *ngIf="!teamReportsLoading">
            <ng-container *ngIf="cases.length">
              <div class="row">
                <div
                  class="col-lg-3 col-md-6 col-sm-6 col-12"
                  *ngFor="let case of cases">
                  <a
                    [routerLink]="['/cases/', case.id]"
                    class="case-card-link">
                    <mat-card class="example-card case-card">
                      <img
                        mat-card-image
                        src="{{ getChildPictureOrDefault(case.person.picture) }}"
                        alt="Photo of {{ case.person.fullName }}">
                      <mat-card-header>
                        <mat-card-title>
                          {{ case.person.fullName || (case.caseFileNumber ? ('Case ' + case.caseFileNumber!) : '') }}
                        </mat-card-title>
                      </mat-card-header>
                      <mat-card-content>
                        <div class="row">
                          <div class="col-lg-8 col-6">
                            <div class="flex-row">
                              <div class="case-gray">Status</div>
                              <div class="case-dark-gray">{{ case.caseStatus.name }}</div>
                            </div>
                          </div>
                          <div class="col-lg-4 col-6 d-flex justify-content-end">
                            <div class="flex-row">
                              <div class="case-gray">Age</div>
                              <div class="case-dark-gray text-center">{{ age(case.person.birthdayRaw) }}</div>
                            </div>
                          </div>
                        </div>
                      </mat-card-content>
                    </mat-card>
                  </a>
                </div>
              </div>
              <div class="case-link">
                <a [routerLink]="['/cases/my']">
                  View More
                  <mat-icon>keyboard_arrow_right</mat-icon>
                </a>
              </div>
            </ng-container>

            <div
              class="no-case"
              *ngIf="!cases.length">
              <img src="../../assets/images/no-results.png">
              <span>No cases found for this team</span>
            </div>
          </ng-container>

          <div *ngIf="teamReportsLoading">
            <div class="row">
              <div
                class="col-lg-3 col-md-4 col-sm-6 col-12"
                *ngFor="let x of [1,2,3,4]">
                <div class="skeleton skeleton-case-card"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="last-wrapper">
        <div class="row">
          <div class="col-lg-6 col-md-12">
            <div class="report-wrapper">
              <div class="d-flex bd-highlight">
                <div class="bd-highlight">
                  <div class="report-title">My Case Activity Report</div>
                </div>
                <div class="ms-auto bd-highlight">
                  <ng-select placeholder="Activity" class="task-select"
                  bindLabel="name"
                  bindValue="value"
                  placeholder="Kyc Status"
                  [items]="CASE_ACTIVITY_REPORT_SORT_BY_OPTIONS"
                  [searchable]="false"
                  [(ngModel)]="caseActivityReportSortBy"
                  (change)="loadCaseReportsActivityWithSort()"
                  [disabled]="caseReportsActivity?.length == 0">
                  </ng-select>
                </div>
              </div>
              <ng-container *ngIf="!teamReportsLoading && !caseReportsActivityLoading && caseReportsActivity">
                <div class="row blue-report d-flex align-items-center">
                  <cok-case-activity-item *ngFor="let caseChild of caseReportsActivity" [case]="caseChild"></cok-case-activity-item>
                  <div class="no-data-div" *ngIf="caseReportsActivity.length == 0"><img src="../../assets/images/no-results.png">No data</div>
                </div>
                <div class="report-link">
                  <a [routerLink]="['/reports', teamId, 'activity']">
                    View More
                    <mat-icon>keyboard_arrow_right</mat-icon>
                  </a>
                </div>
              </ng-container>

              <div *ngIf="teamReportsLoading || caseReportsActivityLoading">
                <div
                  class="row purple-report d-flex align-items-center"
                  *ngFor="let x of [1,2,3,4,5]">
                  <div class="col-lg-4 col-md-4 col-sm-12 col-12">
                    <div class="d-flex flex-row">
                      <div
                        class="skeleton"
                        style="width: 32px; height: 32px; border-radius: 4px"></div>
                      <div class="d-flex align-items-center">
                        <div
                          class="skeleton ms-4"
                          style="width: 60px; height: 21px; border-radius: 4px"></div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-8 col-md-8 col-sm-12 col-12">
                    <div
                      class="skeleton"
                      style="height: 16px; border-radius: 0.25rem"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-md-12">
            <div class="report-wrapper">
              <div class="report-duration-title">My Duration Report</div>
              <ng-container *ngIf="!teamReportsLoading && reportsDuration">
                <div class="row report-duration d-flex align-items-center">
                  <div class="col-lg-4 col-md-4 col-sm-4 col-12">
                    <div class="d-flex flex-row">
                      <div class="circle icon-duration-new"></div>
                      <p class="report-blue-title">New</p>
                    </div>
                    <div class="d-flex flex-row">
                      <div class="circle icon-duration-discover"></div>
                      <p class="report-blue-title">Discovery</p>
                    </div>
                    <div class="d-flex flex-row">
                      <div class="circle icon-duration-engagement"></div>
                      <p class="report-blue-title">Engagement</p>
                    </div>
                    <div class="d-flex flex-row">
                      <div class="circle icon-duration-promising-placement"></div>
                      <p class="report-blue-title">Promising Placement</p>
                    </div>
                    <div class="d-flex flex-row">
                      <div class="circle icon-duration-finalization"></div>
                      <p class="report-blue-title">Finalization</p>
                    </div>
                    <div class="d-flex flex-row">
                      <div class="circle icon-duration-closed"></div>
                      <p class="report-blue-title">Closed</p>
                    </div>
                    <div class="d-flex flex-row">
                      <div class="circle icon-duration-inactive"></div>
                      <p class="report-blue-title">Inactive</p>
                    </div>
                  </div>

                  <div
                    #containerRef
                    class="col-lg-7 col-md-7 col-sm-8 col-11 report-duration-pie">
                    <ngx-charts-pie-chart
                      [results]="reportsDuration"
                      [customColors]="reportsDurationChartColors"
                      [view]="[containerRef.offsetWidth, 200]">
                    </ngx-charts-pie-chart>
                    <span *ngIf="!hasAnyReportsDurationValues"><img src="../../assets/images/no-results.png">No data</span>
                  </div>
                </div>
                <div class="report-link">
                  <a [routerLink]="['/reports', teamId, 'duration']">
                    View More
                    <mat-icon>keyboard_arrow_right</mat-icon>
                  </a>
                </div>
              </ng-container>

              <div *ngIf="teamReportsLoading">
                <div class="row report-duration d-flex align-items-center">
                  <div class="col-lg-4 col-md-4 col-sm-4 col-12">
                    <div
                      class="d-flex flex-row mb-2"
                      *ngFor="let x of [1,2,3,4,5,6,7]">
                      <div
                        class="skeleton"
                        style="width: 32px; height: 32px; border-radius: 4px"></div>
                      <div class="d-flex align-items-center">
                        <div
                          class="skeleton ms-4"
                          style="width: 100px; height: 21px; border-radius: 4px"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="team-wrapper" *ngIf="showTeamMembers">
          <div class="border-wrapper">
            <div class="team-title">Team Members</div>
            <ng-container *ngIf="!teamReportsLoading">
              <div
                *ngIf="teamMemberCasesWithCount.length"
                class="row team-members">
                <div
                  class="col-lg-6 col-md-12"
                  *ngFor="let data of teamMemberCasesWithCount">
                  <div class="d-flex flex-row d-flex align-items-center team-box" *ngIf="data.teamMember">
                    <div class="team-img">
                      <img
                        src="{{ data.teamMember.picture ? data.teamMember.picture : DEFAULT_TEAM_IMAGE}}"
                        alt="team">
                    </div>
                    <div class="team-name">{{ data.teamMember.name }}</div>
                    <div class="ms-auto d-flex flex-row">
                      <div class="bd-highlight ps-5">
                        <p class="case-nr">{{ data.activeCases }}</p>
                        <p class="case-status">Active Cases</p>
                      </div>
                      <div class="bd-highlight ps-5">
                        <p class="case-nr">{{ data.closedCases }}</p>
                        <p class="case-status">Closed Cases</p>
                      </div>
                      <div class="bd-highlight ps-5">
                        <p class="case-nr">{{ data.inactiveCases }}</p>
                        <p class="case-status">Inactive Cases</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="team-link">
                  <a [routerLink]="['/cases/team', teamId, 'members']">
                    View More
                    <mat-icon>keyboard_arrow_right</mat-icon>
                  </a>
                </div>
              </div>

              <div
                *ngIf="!teamMemberCasesWithCount.length"
                class="row team-members">
                <h6 class="text-center p-5">No team members found for this team</h6>
              </div>
            </ng-container>

            <div
              class="row team-members"
              *ngIf="teamReportsLoading">
              <div
                class="col-lg-6 col-md-12"
                *ngFor="let x of [1,2,3,4,5,6]">
                <div class="d-flex flex-row d-flex align-items-center team-box">
                  <div
                    class="skeleton"
                    style="width: 60px; height: 60px; border-radius: 50%;"></div>
                  <div class="team-name">
                    <div
                      class="skeleton"
                      style="height: 24px; width: 120px; border-radius: 6px;"></div>
                  </div>
                  <div class="ms-auto d-flex flex-row">
                    <div
                      class="bd-highlight ps-5"
                      *ngFor="let x of [1,2,3]">
                      <div class="skeleton skeleton-team-text"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ng-container *ngIf="!hasPermissions">
          <div class="no-permission-card">
            <h6>You don't have the correct permissions to view this</h6>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </perfect-scrollbar>
</div>
