import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { SidebarComponent } from '../../shared/components/sidebar/sidebar.component';
import { IsSidebarDirtyService } from '../../shared/services/is-sidebar-dirty.service';

@Injectable({
  providedIn: 'root',
})
export class CanDeactivateSidebarGuard implements CanDeactivate<SidebarComponent> {
  constructor(private sidebarDirtyService: IsSidebarDirtyService, private ngxModalService: BsModalService, private router: Router) {}

  canDeactivate(
    component: SidebarComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (this.router.getCurrentNavigation()?.extras.state?.ignoreDeactivateSidebarGuard) {
      return true;
    }

    if (
      this.sidebarDirtyService.checkIfDirty(() => {
        return true;
      }, nextState)
    ) {
      return false;
    }

    return true;
  }
}
