import { Component, OnInit } from '@angular/core';
import { Apollo } from 'apollo-angular';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { filter, Subject, take } from 'rxjs';
import { UserFull, UserFull_userTeams_team } from '../../generated/UserFull';
import { getTasksFiltered_tasks } from '../../generated/getTasksFiltered';
import { UserService } from '../auth0/services/user.service';
import { TaskService, TaskType } from '../user/services/task.service';
import { TeamService } from '../person/services/team.service';
import { getUsers_users } from '../../generated/getUsers';
import { DASHBOARD_TEAM_REPORTS, DASHBOARD_CASE_REPORTS_ACTIVITY } from '../reports/fragments/index';
import { dashboardTeamReports, dashboardTeamReportsVariables, dashboardTeamReports_dashboardTeamMembersWithCaseCount, dashboardTeamReports_dashboardReportsDuration, dashboardTeamReports_dashboardTeamCasesWithLimit, dashboardTeamReports_dashboardCaseReportsActivity } from '../../generated/dashboardTeamReports';
import { dashboardCaseReportsActivity, dashboardCaseReportsActivityVariables } from '../../generated/dashboardCaseReportsActivity';
import { TEAM_ID_STORAGE_KEY } from '../shared/constants/storage-keys';
import { Roles } from '../../generated/globalTypes';

interface TeamMemberWithCaseCount extends dashboardTeamReports_dashboardTeamMembersWithCaseCount {
  teamMember: getUsers_users;
}

@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  public readonly DEFAULT_AVATAR_IMAGE = "../../assets/images/case-main-logo.svg";
  public readonly DEFAULT_TEAM_IMAGE = "../../assets/images/node-blue.svg";
  public readonly TEAM_MEMBERS_TO_SHOW_COUNT = 6;
  public readonly CASE_LIMIT = 4;
  public readonly CASE_ACTIVITY_LIMIT = 4;
  public readonly TEAM_ID_STORAGE_KEY = "SELECTED_TEAM_ID";

  public readonly CASE_ACTIVITY_REPORT_SORT_BY_OPTIONS = [
    {
      name: 'High to low activity',
      value: 'updatedAt_desc'
    },
    {
      name: 'Low to high activity',
      value: 'updatedAt_asc'
    },
  ];

  private subscription = new Subject<void>();

  public reportsDurationChartColors = [
    { name: "New", value: '#2c98f0' },
    { name: "Discovery", value: '#673ab7' },
    { name: "Engagement", value: '#b0279a' },
    { name: "Promising Placement", value: '#009688' },
    { name: "Finalization", value: '#779150' },
    { name: "Closed", value: '#1d6491' },
    { name: "Inactive", value: '#E5EDEE' },
  ];

  public reportsDuration: dashboardTeamReports_dashboardReportsDuration[] = [];

  public usersLoading = true;
  public tasksLoading = true;
  public teamReportsLoading = true;
  public caseReportsActivityLoading = false;

  public teamId: number;
  public user: UserFull;
  public userTeams: UserFull_userTeams_team[] = [];
  public teamMembers: getUsers_users[] = [];

  public cases: dashboardTeamReports_dashboardTeamCasesWithLimit[] = [];

  public tasks: getTasksFiltered_tasks[] = [];
  public personalTasksCount: number;
  public personalTasksPercentage: number;
  public caseTasksCount: number;
  public caseTasksCountPercentage: number;
  public activeTasksCount: number;
  public activeTasksCountPercentage: number;
  public completedTasksCount: number;
  public completedTasksCountPercentage: number;

  public teamMemberCasesWithCount: dashboardTeamReports_dashboardTeamMembersWithCaseCount[] = [];
  public caseReportsActivity: dashboardTeamReports_dashboardCaseReportsActivity[];
  public hasAnyReportsDurationValues = false;

  public caseActivityReportSortBy = 'updatedAt_desc';

  public hasPermissions = true;

  constructor(
    private apollo: Apollo,
    private userService: UserService,
    private teamService: TeamService,
    private messageService: ToastrService,
    private taskService: TaskService,
  ) { }

  public ngOnInit(): void {
    this.loadUser();
    this.loadTasks();
  }

  public percent(x: number, y: number): number {
    if (y === 0) return 0;
    return (x / y) * 100;
  }

  private loadUser(): void {
    this.usersLoading = true;
    this.userService.currentUser$
      .pipe(
        filter((u) => u != null),
        take(1))
      .subscribe((u) => {
        if (!u) return; // should never be undefined
        this.user = u;
        this.userTeams = this.user.userTeams
          .map(({ team }) => team)
          .sort((a, b) => a.id - b.id);

        // check if team id is already in storage
        const storedTeamId = this.teamService.getCurrentTeamId();
        const defaultTeamId = this.userTeams[0].id;

        if (storedTeamId) {
          const teamExists = this.userTeams.find(({ id }) => id === storedTeamId);
          this.updateSelectedTeam(teamExists?.id || defaultTeamId);
        } else {
          this.updateSelectedTeam(defaultTeamId);
        }
        this.usersLoading = false;
    });
  }

  public updateSelectedTeam(teamId: number): void {
    this.teamId = teamId;
    this.teamService.setCurrentTeamId(this.teamId);
    this.loadTeamMembers();
  }

  public loadTeamMemberCaseData(): void {
    this.teamReportsLoading = true;
    this.apollo
      .query<dashboardTeamReports, dashboardTeamReportsVariables>({
        query: DASHBOARD_TEAM_REPORTS,
        variables: {
          teamId: this.teamId,
          caseLimit: this.CASE_LIMIT,
          teamMemberResultsLimit: this.TEAM_MEMBERS_TO_SHOW_COUNT,
          caseReportsLimit: this.CASE_ACTIVITY_LIMIT,
          caseReportsSortBy: this.caseActivityReportSortBy
        },
        fetchPolicy: 'network-only',
      })
      .subscribe({
        next: ({ data }) => {
          this.cases = data.dashboardTeamCasesWithLimit;
          this.teamMemberCasesWithCount = data.dashboardTeamMembersWithCaseCount
            .map(v => ({
              teamMember: this.teamMembers.find(({ id }) => id === v.userId)!,
              ...v
            } as TeamMemberWithCaseCount));
          this.caseReportsActivity = data.dashboardCaseReportsActivity;
          this.reportsDuration = data.dashboardReportsDuration;
          this.hasAnyReportsDurationValues = this.reportsDuration.some(r => r.value > 0);
          this.teamReportsLoading = false;
        },
        error: (_err) => {
          this.cases = [];
          this.teamMemberCasesWithCount = [];
          this.caseReportsActivity = [];
          this.reportsDuration = [];
          this.teamReportsLoading = false;
        },
      });
  }

  public loadCaseReportsActivityWithSort(): void {
    this.caseReportsActivityLoading = true;
    this.apollo
      .query<dashboardCaseReportsActivity, dashboardCaseReportsActivityVariables>({
        query: DASHBOARD_CASE_REPORTS_ACTIVITY,
        variables: {
          teamId: this.teamId,
          caseReportsLimit: this.CASE_ACTIVITY_LIMIT,
          caseReportsSortBy: this.caseActivityReportSortBy
        },
        fetchPolicy: 'network-only',
      })
      .subscribe({
        next: ({ data }) => {
          this.caseReportsActivity = data.dashboardCaseReportsActivity;
          this.caseReportsActivityLoading = false;
        },
        error: (_err) => {
          this.caseReportsActivity = [];
          this.caseReportsActivityLoading = false;
        },
      });
  }

  private loadTeamMembers(): void {
    this.teamService.getTeamUsers(this.teamId)
      .subscribe(members => {
        if (!members) return;

        this.teamMembers = members;
        this.loadTeamMemberCaseData();
    });
  }

  private loadTasks(): void {
    this.tasksLoading = true;
    this.taskService.getTasksFiltered().valueChanges.subscribe({
      next: (result) => {
        this.tasks = result.data.tasks;
        this.personalTasksCount = this.tasks.filter(t => TaskType.PERSONAL === t.type).length;
        this.personalTasksPercentage = this.calculateTasksPercentage(this.personalTasksCount);

        this.caseTasksCount = this.tasks.length - this.personalTasksCount;
        this.caseTasksCountPercentage = this.calculateTasksPercentage(this.caseTasksCount);

        this.activeTasksCount = this.tasks.filter(t => !t.isComplete).length;
        this.activeTasksCountPercentage = this.calculateTasksPercentage(this.activeTasksCount);

        this.completedTasksCount = this.tasks.length - this.activeTasksCount;
        this.completedTasksCountPercentage = this.calculateTasksPercentage(this.completedTasksCount);

        this.tasksLoading = false;
      },
      error: (e) => {
        this.messageService.error(`Failed to get tasks<br/>${e.message}`);
        this.tasksLoading = false;
      }
    });
  }

  private calculateTasksPercentage(value: number): number {
    return this.percent(value, this.tasks.length);
  }

  public getChildPictureOrDefault(picture: string | null): string {
    if (!picture) return this.DEFAULT_AVATAR_IMAGE;
    return picture;
  }

  public age(raw_date: string | null): string | null {
    if (!raw_date) return '-';
    return moment().diff(raw_date, 'years') + ' years';
  }

  public get showTeamMembers(): boolean {
    const userTeamRole = this.userService.getTeamRole(this.teamId);
    const teamRoles = [Roles.MANAGER, Roles.EDITOR, Roles.VIEWER];
    if (teamRoles.every(role => role !== userTeamRole)) return false;
    return true;
  }
}
