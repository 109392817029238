import { gql } from 'apollo-angular';
import { PERSON_BIRTHDAY_FRAGMENT, PERSON_NAME_FRAGMENT } from './person';
import { ADDRESS_DETAIL_FRAGMENT } from './address';
import { USER_DETAIL_FRAGMENT } from '../../admin/fragments/users';

export const CASE_DETAIL_FRAGMENT = gql`
  fragment CaseDetail on Case {
    id
    teamId
    billingAccountId
    person {
      id
      ...PersonName
      picture
      gender
      pronouns
      isSameSexPartner
      isIdentified
      isLocated
      dateIdentified
      dateLocated
      title
      race
      ethnicity
      tribalAffiliation
      identifiesAsLGBTQIAPlus
      category
      ...PersonBirthday
      notes
      addresses {
        ...AddressDetail
      }
      teamAttributes {
        id
        teamAttributeId
        value
      }
    }
    caseStatus {
      id
      name
      representsClosure
    }
    childStatus {
      id
      name
    }
    fosterCare
    caseFileNumber
    history {
      id
      timestamp
      caseStatusId
    }
    team {
      id
    }
    billingAccount {
      id
      name
      isDefault
    }
    owners {
      user {
        id
        name
        picture
      }
    }
  }

  ${PERSON_BIRTHDAY_FRAGMENT}
  ${PERSON_NAME_FRAGMENT}
  ${ADDRESS_DETAIL_FRAGMENT}
`;

export const CASE_OWNER_FRAGMENT = gql`
  fragment CaseOwnerDetails on CaseOwner {
    caseId
    userId
  }
`;

export const CASES_DETAIL_QUERY = gql`
  query casesDetail($activelyContributed: Boolean) {
    cases(activelyContributed: $activelyContributed) {
      ...CaseDetail
    }
  }

  ${CASE_DETAIL_FRAGMENT}
`;

export const CASE_DETAIL_QUERY = gql`
  query caseDetails($caseId: Int!) {
    case(caseId: $caseId) {
      ...CaseDetail
    }
  }

  ${CASE_DETAIL_FRAGMENT}
`;

export const CASE_OWNERS_QUERY = gql`
  query getCaseOwnersByCaseId($caseId: Int!) {
    getCaseOwnersByCaseId(caseId: $caseId) {
      name
      picture
    }
  }

  ${USER_DETAIL_FRAGMENT}
`;

export const ADD_CASE_OWNERS_MUTATION = gql`
  mutation addOwnersToCase($caseId: Int!, $userIds: [Int!]!) {
    addOwnersToCase(caseId: $caseId, userIds: $userIds) {
      userId
    }
  }
`;

export const REMOVE_CASE_OWNERS_MUTATION = gql`
  mutation removeOwnersFromCase($caseId: Int!, $userIds: [Int!]!, $newRole: Roles!) {
    removeOwnersFromCase(caseId: $caseId, userIds: $userIds, newRole: $newRole) {
      userId
    }
  }
`;

export const REMOVE_CASE_OWNER_MUTATION = gql`
  mutation removeOwnerFromCase($caseId: Int!, $userId: Int!, $newRole: Roles!) {
    removeOwnerFromCase(caseId: $caseId, userId: $userId, newRole: $newRole) {
      userId
    }
  }
`;
