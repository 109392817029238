import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RelationshipStatus } from '../../../../../generated/RelationshipStatus';
import { Validator } from '../../../utilities/validator.form-control';
import { RelationshipStatusService } from '../../../../person/services/relationship-status.service';
import { Observable } from 'rxjs';
import { RelationshipStatusAdminService } from '../../../../admin/service/relationship-status-admin.service';

export interface CreateEditRelationshipStatusModalData {
  isGlobalStatus: boolean;
  teamId: number;
  isEditingExistingRelationship: boolean;
  relationshipStatus: RelationshipStatus;
}

@Component({
  selector: 'cok-create-edit-relationship-status',
  templateUrl: './create-edit-relationship-status.component.html',
  styleUrls: ['./create-edit-relationship-status.component.scss']
})
export class CreateEditRelationshipStatusComponent {

  public form: FormGroup;

  public isGlobalStatus: boolean;
  public isEditingExistingRelationship: boolean;
  public relationshipStatus: RelationshipStatus;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: CreateEditRelationshipStatusModalData,
    private dialogRef: MatDialogRef<CreateEditRelationshipStatusComponent>,
    private formBuilder: FormBuilder,
    private relationshipStatusService: RelationshipStatusService,
    private relationshipStatusAdminService: RelationshipStatusAdminService,
  ) {
    if (data) {
      this.isGlobalStatus = data.isGlobalStatus;
      this.isEditingExistingRelationship = data.isEditingExistingRelationship;
      this.relationshipStatus = data.relationshipStatus;
    }

    this.buildForm();
  }

  public get color(): string {
    return this.form.get('color')?.value;
  }

  public submit(): void {
    const request = this.getSavingRequest();
    request.subscribe((savedRelationshipStatus) => this.dialogRef.close(savedRelationshipStatus));
  }

  private getSavingRequest(): Observable<RelationshipStatus> {
    const value = this.form.value;

    if (this.isGlobalStatus) {
      return this.isEditingExistingRelationship ?
        this.relationshipStatusAdminService.updateRelationshipStatus(this.relationshipStatus.id, value) :
        this.relationshipStatusAdminService.createRelationshipStatus(value);
    } else {
      return this.isEditingExistingRelationship ?
        this.relationshipStatusService.updateRelationshipStatus(this.data.teamId, this.relationshipStatus.id, value) :
        this.relationshipStatusService.createRelationshipStatus(this.data.teamId, value);
    }
  }

  private buildForm(): void {
    if (this.isEditingExistingRelationship) {
      this.form = this.formBuilder.group({
        name: [this.relationshipStatus.name, Validator.required],
        colorBlind: [this.relationshipStatus.colorBlind, Validator.required],
        color: [this.relationshipStatus.color, Validator.required],
        notes: [this.relationshipStatus.notes, Validator.required],
        sortOrder: [this.relationshipStatus.sortOrder, Validator.required],
      });

      if (this.isGlobalStatus) {
        this.form.addControl('isTemplate', new FormControl(this.relationshipStatus.isTemplate));
      }
    } else {
      this.form = this.formBuilder.group({
        name: ['', Validator.required],
        colorBlind: [false, Validator.required],
        color: ['', Validator.required],
        notes: ['', Validator.required],
        sortOrder: [-1, Validator.required],
      });

      if (this.isGlobalStatus) {
        this.form.addControl('isTemplate', new FormControl(false));
      }
    }
  }
}
