import { Injectable } from '@angular/core';
import * as fileSaver from 'file-saver';

@Injectable()
export class CsvService {

  private static readonly COLUMN_SEPARATOR = ",";
  private static readonly LINE_SEPARATOR = "\n";
  private static readonly CONTENT_TYPE = "text/csv;charset=utf-8";
  private static readonly FILE_EXTENSION = ".csv";
  
  public writeCsv(linesWithColumns: string[][], title: string): void {
    const lines = linesWithColumns.map((lineColumns) => lineColumns.join(CsvService.COLUMN_SEPARATOR));
    const csvContent = lines.join(CsvService.LINE_SEPARATOR);
    const blob = new Blob([csvContent], { type: CsvService.CONTENT_TYPE });
    fileSaver.saveAs(blob, title + CsvService.FILE_EXTENSION);
  }
}
