import { Component, Input, forwardRef, ViewChild, TemplateRef, ChangeDetectorRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import { AvatarType } from '../../pipes/avatar.pipe';
import { loadFile, loadImage, reduceFileSize } from '../../utilities/image';


@Component({
  selector: 'cok-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ImageUploadComponent),
    },
  ],
})
export class ImageUploadComponent implements ControlValueAccessor {
  public faImage = faImage;

  @Input() previewUrl: string | undefined;

  @Input() avatarType: AvatarType | undefined;

  @ViewChild('maxFileSizeModal')
  public maxFileSizeModal: TemplateRef<any>;

  @ViewChild('invalidExtensionModal')
  public invalidExtensionModal: TemplateRef<any>;

  @ViewChild('invalidImageModal')
  public invalidImageModal: TemplateRef<any>;

  onChange: Function;

  constructor(private modalService: BsModalService, private ref: ChangeDetectorRef) {}

  @Input()
  public isEnabled: boolean = true;

  private modalRef: BsModalRef | undefined;

  writeValue(value: null) {}

  registerOnChange(fn: Function) {
    this.onChange = fn;
  }

  registerOnTouched(fn: Function) {}

  setDisabledState(isDisabled: boolean): void {
    this.isEnabled = !isDisabled;
  }

  public closeModal() {
    if (this.modalRef) this.modalRef.hide();
  }

  public async showPreviewImage($event: any) {
    if ($event.target.files && $event.target.files[0]) {
      const file: File = $event.target.files[0];

      if (file.size > 25 * 1024 * 1024) {
        this.modalRef = this.modalService.show(this.maxFileSizeModal, { class: 'modal-sm modal-dialog-centered' });
        return;
      }

      const fileExt = file.name.split('.').pop()!.toLocaleLowerCase();
      if (['jpg', 'jpeg', 'gif', 'png'].indexOf(fileExt) < 0) {
        this.modalRef = this.modalService.show(this.invalidExtensionModal, { class: 'modal-sm modal-dialog-centered' });
        return;
      }

      try {
        const reader = await loadFile(file);
        const dataUrl = reader.result as string;
        const img = await loadImage(dataUrl);
        const output = await reduceFileSize(file, img);

        this.previewUrl = dataUrl;
        this.ref.detectChanges();
        this.onChange(output);
      } catch (err) {
        console.error(err);
        this.modalRef = this.modalService.show(this.invalidImageModal, { class: 'modal-sm modal-dialog-centered' });
      }
    }
  }
}
