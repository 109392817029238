import { Component } from '@angular/core';

@Component({
  selector: 'cok-unsupported-browser',
  template: `
    <section class="lyt-form not-found browser-check">
      <div class="inner">
        <a [routerLink]="['/']" class="logo"
          ><img src="./assets/images/logo-connect.png" alt="Connect Our Kids" /><span>Connect Our Kids</span></a
        >
        <div class="text-lable">
          Your browser is not supported. Use one of the following links to download a supported browser:
        </div>

        <ul class="text-lable list">
          <li><a href="https://www.google.com/chrome">Google Chrome (Best Option)</a></li>
          <li><a href="https://www.mozilla.org/firefox/">Mozilla Firefox</a></li>
          <li><a href="https://www.microsoft.com/edge">Microsoft Edge 80+</a></li>
        </ul>

        <p>Thanks so much</p>
      </div>
    </section>
  `,
  styles: [
    `
      .list {
        text-align: left;
        width: 60%;
        margin-left: 20%;
      }
    `,
  ],
})
export class UnsupportedBrowserComponent {}
