import { map, Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { CREATE_TEAM_RELATIONSHIP_STATUS, DELETE_TEAM_RELATIONSHIP_STATUS, TEAM_RELATIONSHIP_STATUSES, UPDATE_TEAM_RELATIONSHIP_STATUS } from "../fragments/relationship-status";
import { createRelationshipStatus, createRelationshipStatusVariables } from "../../../generated/createRelationshipStatus";
import { RelationshipStatusInput } from "../../../generated/globalTypes";
import { RelationshipStatus } from "../../../generated/RelationshipStatus";
import { updateRelationshipStatus, updateRelationshipStatusVariables } from "../../../generated/updateRelationshipStatus";
import { deleteRelationshipStatus, deleteRelationshipStatusVariables } from "../../../generated/deleteRelationshipStatus";
import { relationshipStatuses, relationshipStatusesVariables } from "../../../generated/relationshipStatuses";

@Injectable({ providedIn: 'root' })
export class RelationshipStatusService {

  constructor(private apollo: Apollo) {
  }

  public getRelationshipStatuses(teamId: number, forceReload = false): Observable<RelationshipStatus[]> {
    return this.apollo
      .query<relationshipStatuses, relationshipStatusesVariables>({
        query: TEAM_RELATIONSHIP_STATUSES,
        variables: { teamId },
        fetchPolicy: forceReload ? 'network-only' : undefined,
      })
      .pipe(map((result) => result.data.relationshipStatuses));
  }

  public createRelationshipStatus(teamId: number, value: RelationshipStatusInput): Observable<RelationshipStatus> {
    return this.apollo
      .mutate<createRelationshipStatus, createRelationshipStatusVariables>({
        mutation: CREATE_TEAM_RELATIONSHIP_STATUS,
        variables: { teamId, value },
        fetchPolicy: 'network-only',
      })
      .pipe(map((result) => result.data!.createRelationshipStatus));
  }

  public updateRelationshipStatus(teamId: number, id: number, value: RelationshipStatusInput): Observable<RelationshipStatus> {
    return this.apollo
      .mutate<updateRelationshipStatus, updateRelationshipStatusVariables>({
        mutation: UPDATE_TEAM_RELATIONSHIP_STATUS,
        variables: { teamId, id, value },
        fetchPolicy: 'network-only',
      })
      .pipe(map((result) => result.data!.updateRelationshipStatus));
  }

  public deleteRelationshipStatus(teamId: number, id: number): Observable<RelationshipStatus> {
    return this.apollo
      .mutate<deleteRelationshipStatus, deleteRelationshipStatusVariables>({
        mutation: DELETE_TEAM_RELATIONSHIP_STATUS,
        variables: { teamId, id },
        fetchPolicy: 'network-only',
      })
      .pipe(map((result) => result.data!.deleteRelationshipStatus));
  }
}
