import { gql } from 'apollo-angular';
import * as moment from 'moment';

import { PersonBirthday } from '../../../generated/PersonBirthday';

export const DEFAULT_SUFFIXES = ['Sr.', 'Jr.', 'II', 'III', 'IV', 'V'];
export const DEFAULT_PRONOUNS = ['she/her', 'he/him', 'they/them'];
export const DEFAULT_ALTERNATE_LABELS = ['Alias', 'Maiden', 'Alternate Name'];
export const DEFAULT_RACES = [
  'American Indian or Alaska Native',
  'Alaska Native',
  'Asian',
  'Black or African American',
  'Native Hawaiian or Other Pacific Islander',
  'White'
];
export const DEFAULT_ETHNICITIES = ['Hispanic or Latino', 'Not Hispanic or Latino'];
export const DEFAULT_TRIBAL_AFFILIATIONS = ['Yes', 'No', "Don't Know"];
export const DEFAULT_LGBTQIAPLUS_IDENTIFICATIONS = ['Yes', 'No', 'Questioning', "Don't Know"];

export const PERSON_NAME_FRAGMENT = gql`
  fragment PersonName on Person {
    firstName
    middleName
    lastName
    suffix
    fullName
  }
`;

export const PERSON_BIRTHDAY_FRAGMENT = gql`
  fragment PersonBirthday on Person {
    birthMonth
    birthYear
    dayOfBirth
    birthdayRaw
  }
`;

export function fromDateString(input: string | undefined | null): PersonBirthday {
  const trimmed = (input || '').trim();
  const date = moment(trimmed, ['MM/DD/YYYY', 'M/DD/YYYY', 'M/D/YYYY', 'MM/D/YYYY'], true);

  return {
    __typename: 'Person',
    birthdayRaw: trimmed || null,
    birthMonth: date.isValid() ? date.month() + 1 : null,
    birthYear: date.isValid() ? date.year() : null,
    dayOfBirth: date.isValid() ? date.date() : null,
  };
}

export function getBirthdayDate(input: PersonBirthday | undefined | null): moment.Moment | undefined {
  if (!input) return undefined;

  if (input.birthYear && input.birthMonth && input.dayOfBirth) {
    const date = moment({ year: input.birthYear, month: input.birthMonth - 1, day: input.dayOfBirth });
    return date.isValid() ? date : undefined;
  }

  const trimmed = (input.birthdayRaw || '').trim();
  const date = moment(trimmed, ['MM/DD/YYYY', 'M/DD/YYYY', 'M/D/YYYY', 'MM/D/YYYY'], true);
  return date.isValid() ? date : undefined;
}

export function getFullName({
  firstName,
  middleName,
  lastName,
  suffix,
}: { firstName?: string | null; middleName?: string | null; lastName?: string | null; suffix?: string | null } = {}) {
  let name = firstName || '';
  if (middleName) {
    name += (name ? ' ' : '') + middleName;
  }

  if (lastName) {
    name += (name ? ' ' : '') + lastName;
  }

  if (suffix) {
    name += (name ? ', ' : '') + suffix;
  }

  return name;
}
