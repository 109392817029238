import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';

import { UserService } from '../services/user.service';
import { Roles } from '../../../generated/globalTypes';

@Injectable()
export class TeamManagerGuard implements CanActivate {
  constructor(private userService: UserService, public router: Router) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    try {
      const user = await this.userService.ensureUser();

      const team = user?.userTeams.find((t) => t.team.id === +route.params.team);

      if (!team) {
        return this.router.parseUrl(user.isSiteAdmin ? '/admin' : '/error');
      }

      if (team && team.role != Roles.MANAGER) {
        return this.router.navigateByUrl(`/cases/team/${team.team.id}`, { replaceUrl: true, state: { isGuardRedirect: true } });
      }
    } catch (err) {
      console.error(err);
      return false;
    }

    return true;
  }
}
