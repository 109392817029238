import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import { Apollo } from 'apollo-angular';
import { BehaviorSubject, finalize, map, Observable } from 'rxjs';
import { RELATIONSHIPS_DETAIL_QUERY } from '../../../../../app/person/fragments/relationship';
import { CASES_DETAIL_QUERY } from '../../../../../app/person/fragments/cases';
import { TaskService } from '../../../../../app/user/services/task.service';
import { casesDetail } from '../../../../../generated/casesDetail';
import { CreateTaskInput, UpdateTaskInput } from '../../../../../generated/globalTypes';
import { relationshipsDetail } from '../../../../../generated/relationshipsDetail';
import { TaskType } from '../../../services/task.service';
import { IsFormDirtyService } from '../../../../../app/shared/services/is-form-dirty.service';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Validator } from '../../../../../app/shared/utilities/validator.form-control';
import { TaskInfo } from '../../../../../generated/TaskInfo';
import { ToastrService } from 'ngx-toastr';
import { MixpanelService } from './../../../../shared/services/mixpanel.service';

interface Case {
  id: number;
  fullName: string | null;
  picture: string | null;
}

interface Relationship {
  id: number;
  fullName: string | null;
  picture: string | null;
}

@Component({
  selector: 'create-task',
  templateUrl: './create-task.component.html',
  styleUrls: ['./create-task.component.scss'],
})
export class CreateTaskComponent implements OnInit {
  public task: TaskInfo | null;

  public readonly faCalendarAlt = faCalendarAlt;

  public form: FormGroup;

  public cases$: Observable<Case[]>;
  public relationships: Relationship[];
  private selectedCaseId$: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);
  private selectedCaseId: number | null;
  private selectedRelationshipId: number | null;

  public isSubmitted = false;
  public busyIndicator = false;
  public isDirty = false;
  public isLoading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: { task: TaskInfo },
    private formBuilder: FormBuilder,
    private apollo: Apollo,
    private taskService: TaskService,
    private formDirtyService: IsFormDirtyService,
    private dialog: MatDialog,
    private messageService: ToastrService,
    private mixpanelService: MixpanelService,
  ) {
    this.task = data.task;
  }

  public ngOnInit(): void {
    this.form = this.formBuilder.group({
      isCaseTask: [false],
      dueDate: [],
      title: [null, Validator.required],
      description: null,
      case: null,
      relationship: null,
    });

    this.cases$ = this.apollo
      .watchQuery<casesDetail>({
        query: CASES_DETAIL_QUERY,
        variables: { activelyContributed: false },
      })
      .valueChanges.pipe(
        map((result) => result.data.cases.map((c) => ({ ...c.person })).sort((a, b) => a.fullName.localeCompare(b.fullName)))
      );

    this.selectedCaseId$.subscribe((caseId) => {
      if (!caseId) {
        this.selectedRelationshipId = null;
        this.relationships = [];
        return;
      }

      this.selectedCaseId = caseId;

      this.apollo
        .watchQuery<relationshipsDetail>({
          query: RELATIONSHIPS_DETAIL_QUERY,
          variables: { caseId },
        })
        .valueChanges.pipe(
          map((result) => result.data.relationships.map((r) => ({ ...r.person })).sort((a, b) => a.fullName.localeCompare(b.fullName)))
        )
        .subscribe((result) => {
          this.relationships = result;
        });
    });

    this.form.get('isCaseTask')?.valueChanges.subscribe((val) => {
      if (val) {
        this.caseChild.setValidators(Validator.required);
        this.caseChild.updateValueAndValidity();
        return;
      }
      this.caseChild.removeValidators(Validator.required);
      this.caseChild.updateValueAndValidity();
    });

    if (this.task) {
      this.selectedCaseId$.next(this.task.case?.id || null);

      this.form.patchValue({
        title: this.task.title,
        description: this.task.description,
        dueDate: this.task.dueDate ? new Date(this.task.dueDate) : null,
        case: this.task.case?.id,
        relationship: this.task.relationship?.id,
        isCaseTask: this.task.type == TaskType.CASE ? true : false,
      });
    }
  }

  public createTask(): void {
    const title = this.title.value;
    const description = this.description.value;
    const isComplete = false;
    const dueDate = this.dueDate.value ? { val: this.dueDate.value } : null;
    const type = this.isCaseTask ? TaskType.CASE : TaskType.PERSONAL;
    const caseId = this.isCaseTask ? this.selectedCaseId : null;
    const relationshipId = this.isCaseTask ? this.selectedRelationshipId : null;

    const value: CreateTaskInput = {
      title,
      description,
      isComplete,
      dueDate,
      type,
      caseId,
      relationshipId,
    };

    this.isLoading = true;

    const customType = {
      taskType: value.type === TaskType.CASE ? 'CASE' : 'PERSONAL',
    };

    this.taskService
      .createTask(value)
      .pipe(
        finalize(() => {
          this.busyIndicator = false;
          this.isLoading = false;
          this.close(false);
        }),
      )
      .subscribe({
        next: () => {
          this.messageService.success('Successfully created Task'),
          this.mixpanelService.trackAction('task-creation-success', customType);
        },
          error: (e) => {
          this.messageService.error(`Failed to create Task<br/>${e.message}`),

          this.mixpanelService.trackAction('task-creation-failure', this.mixpanelService.parseGraphQlError(e));
      }
      });
  }

  public updateTask(): void {
    if (!this.task) {
      return;
    }

    const title = this.title.value;
    const description = this.description.value;
    const dueDate = this.dueDate.value ? { val: this.dueDate.value } : null;
    const type = this.isCaseTask ? TaskType.CASE : TaskType.PERSONAL;
    const caseId = this.isCaseTask ? this.selectedCaseId : null;
    const relationshipId = this.isCaseTask ? this.selectedRelationshipId : null;

    const value: UpdateTaskInput = {
      title,
      description,
      dueDate,
      type,
      caseId,
      relationshipId,
    };

    this.isLoading = true;

    this.taskService
      .updateTask(this.task?.id, value)
      .pipe(
        finalize(() => {
          this.busyIndicator = false;
          this.isLoading = false;
          this.close(false);
        })
      )
      .subscribe({
        next: () => this.messageService.success('Successfully updated Task'),
        error: (e) => this.messageService.error(`Failed to update Task<br/>${e.message}`),
      });
  }

  public submit(): void {
    this.isSubmitted = true;
    if (this.busyIndicator || this.form.invalid) return;
    this.busyIndicator = true;
    this.isLoading = true;
    if (this.task) {
      this.updateTask();
    } else {
      this.createTask();
    }
  }

  private close(warn: boolean): void {
    if (!warn) {
      this.dialog.closeAll();
      return;
    }

    this.isDirty = this.formDirtyService.checkIfDirty(this.form);
    if (!this.isDirty) {
      this.dialog.closeAll();
    }
  }

  public onCaseCleared(): void {
    this.relationships = [];
    this.selectedCaseId = null;
  }

  public onCaseChanged(event: any): void {
    this.selectedCaseId$.next(event.id);
    this.selectedCaseId = event.id;
  }

  public onRelationshipCleared(): void {
    this.selectedRelationshipId = null;
  }

  public onRelationshipChanged(event: any): void {
    this.selectedRelationshipId = event.id;
  }

  public get isCaseTask(): boolean {
    return this.form.get('isCaseTask')?.value || false;
  }

  public get title(): AbstractControl {
    return this.form.get('title')!;
  }

  public get description(): AbstractControl {
    return this.form.get('description')!;
  }

  public get dueDate(): AbstractControl {
    return this.form.get('dueDate')!;
  }

  public get caseChild(): AbstractControl {
    return this.form.get('case')!;
  }

  public get relationship(): AbstractControl {
    return this.form.get('relationship')!;
  }

  public hasError(control: AbstractControl): boolean {
    return control && control.invalid && (control.dirty || control.touched || this.isSubmitted);
  }
}
