<div class="container" id="taskWrapper">
  <div class="row">
    <div class="col-md-12 p-0">
      <div class="d-flex align-items-center">
        <div class="welcome-text">Let's get started, {{ userName }}! <span>☺️</span></div>
      </div>
      <div class="page-title">Tasks</div>
      <div class="d-flex">
        <div class="me-auto py-2">
          <div class="form-group task-search">
            <mat-icon>search</mat-icon>
            <input #input type="text" class="form-control" placeholder="Type to search..." (keyup)="searchTerm = $event.target.value" />
          </div>
        </div>
        <div class="py-2">
          <ng-select
            placeholder="Sort by date"
            class="task-select"
            [items]="sortingItems$"
            bindValue="value"
            bindLabel="name"
            (change)="sortBy = $event"
            [closeOnSelect]="true"
          >
            <ng-template ng-option-tmp let-item="item">
              <ng-option>{{ item.name }}</ng-option>
            </ng-template>
          </ng-select>
        </div>
        <div class="py-2 ms-3">
          <button mat-raised-button color="primary" class="task-add-new" (click)="openAddNewTaskModal()">Add new</button>
        </div>
      </div>
    </div>
    <div class="col-md-12 top-line">
      <div class="row">
        <div class="col-lg-2 col-md-3 filter-content" *ngIf="allTasks.length > 0">
          <div class="filter-box mb-4">
            <p class="filter-title">Task status</p>
            <div class="d-flex">
              <label class="me-auto">
                <input name="status" type="radio" [checked]="isSelected" (change)="taskCompleted = undefined" />All</label
              >
              <div>({{ taskCounts['StatusAll'] }})</div>
            </div>
            <div class="d-flex">
              <label class="me-auto"> <input name="status" type="radio" (change)="taskCompleted = false" />Active</label>
              <div>({{ taskCounts['StatusActive'] }})</div>
            </div>
            <div class="d-flex">
              <label class="me-auto"> <input name="status" type="radio" (change)="taskCompleted = true" />Completed</label>
              <div>({{ taskCounts['StatusComplete'] }})</div>
            </div>
          </div>
          <div class="filter-box">
            <p class="filter-title">Task type</p>
            <div class="d-flex">
              <label class="me-auto"> <input name="type" type="radio" [checked]="isSelected" (change)="taskType = undefined" />All</label>
              <div>({{ taskCounts['TypeAll'] }})</div>
            </div>
            <div class="d-flex">
              <label class="me-auto"> <input name="type" type="radio" (change)="taskType = taskTypes.PERSONAL" />Personal</label>
              <div>({{ taskCounts['TypePersonal'] }})</div>
            </div>
            <div class="d-flex">
              <label class="me-auto"> <input name="type" type="radio" (change)="taskType = taskTypes.CASE" />Case</label>
              <div>({{ taskCounts['TypeCase'] }})</div>
            </div>
          </div>
        </div>
        <ng-container *ngIf="tasks && !isLoading; else loadingTemplate">
          <div class="col-lg-10 col-md-9 task-content" *ngIf="allTasks.length > 0; else noTasksTemplate">
            <perfect-scrollbar>
              <ng-container *ngIf="tasks.length > 0; else noFilteredTasksTemplate">
                <div class="task-list" *ngFor="let task of tasks">
                  <cok-task-item [task]="task"></cok-task-item>
                </div>
              </ng-container>
              <ng-template #noFilteredTasksTemplate>
                <div class="text-center no-task-content">
                  <img src="../../../../assets/images/no-task.png">
                  <p class="no-task-p">No
                    <span *ngIf="taskCompleted != null">
                      {{ taskCompleted ? 'completed' : 'active' }}
                    </span>
                    <span *ngIf="taskType">
                      {{ taskType | lowercase }}
                    </span>
                    tasks yet!</p>
                  <p class="no-task-p2">You don't have to see the whole staircase, just take the first step!</p>
                </div>
              </ng-template>
            </perfect-scrollbar>
          </div>
          <ng-template #noTasksTemplate>
            <div class="empty-wrapper">
              <img src="../../../../assets/images/no-task.png">
              <div class="empty-title"> No Tasks have been created yet!</div>
              <p>Ready for new tasks? Tap "Add new" to write them down.</p>
            </div>
          </ng-template>
        </ng-container>
        <ng-template #loadingTemplate>
          <div class="loading-wrapper">
            <div class="spinner-svg"></div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
