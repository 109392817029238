import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { concatMap, delay, takeUntil } from 'rxjs/operators';
import { BsModalService } from 'ngx-bootstrap/modal';

export interface SidebarDirtyCheck {
  isDirty(): boolean;
}

@Component({
  selector: 'cok-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  @Input() shorter: boolean = false;

  @Output() clickOutside: EventEmitter<any> = new EventEmitter();
  public isClickOutsideCheckEnabled$: Observable<boolean> = of(false);

  private subscription = new Subject<void>();

  constructor(private ngxModalService: BsModalService) {}

  ngOnInit() {
    this.isClickOutsideCheckEnabled$ = of(false).pipe(
      concatMap(() => of(true).pipe(delay(0))),
      takeUntil(this.subscription)
    );
  }

  ngOnDestroy() {
    this.subscription.next();
    this.subscription.complete();
  }

  public async onClickOutside($event: MouseEvent) {
    // elements that close on click do not get properly excluded by ng-click-outside
    // manually perform click rejection here
    if (
      ($event.target instanceof HTMLElement &&
        $event.target.closest('bs-datepicker-container, ng-dropdown-panel, .ignore-sidebar-click-outside')) ||
        ($event.target as HTMLElement).classList.contains('ignore-sidebar-click-outside') ||
        $event.target instanceof SVGElement ||
        $event.target instanceof HTMLAnchorElement || $event.target instanceof HTMLButtonElement
    ) {
      return;
    }

    if (this.ngxModalService.getModalsCount() === 0) {
      this.clickOutside.emit();
    }
  }
}
