<div class="cok-input" [delayClickOutsideInit]="true" [attachOutsideOnClick]="true" (clickOutside)="onClickOutside()"
  (scroll)="onScrollEvent()">
  <div>
    <input type="text" class="form-control" [ngClass]="{'is-invalid' : formatErrors}" #displayDateElement
      [disabled]="this.disabled" [ngModel]="displayValue"
      (focus)="inputFocus($event)" (blur)="blur($event)" [placeholder]="placeholder" (input)="inputChanged($event)"
      (keydown.tab)="inputTabKeydown()" />
  </div>
  <div class="input-date-hidden">
    <input type="text" class="form-control" [disabled]="this.disabled" [isDisabled]="this.disabled"
      #datePicker="bsDatepicker" bsDatepicker [outsideClick]="false" tabindex="-1"
      (bsValueChange)="bsDateValueChange($event)" [bsValue]="bsDatePickerValue" [bsConfig]="dateConfig"
      [maxDate]="maxDate" [minDate]="minDate" />
  </div>
  <div class="calendar-icon" *ngIf="exactDate">
    <span class="bs-datepicker-icon" (click)="datePicker.toggle()">
      <fa-icon [icon]="faCalendarAlt"></fa-icon>
    </span>
  </div>
</div>
<!-- Errors -->
<div *ngIf="formatErrors">
  <p class="maxsize-error mt-1 mb-1" *ngIf="isOldDate()">Date must be on or after {{ minDate | date: 'MM/dd/yyyy' }}</p>
  <p class="maxsize-error mt-1 mb-1" *ngIf="isFutureDate()">Date must be on or before {{ maxDate | date: 'MM/dd/yyyy' }}
  </p>
  <p class="maxsize-error mt-1 mb-1" *ngIf="isInvalidDate()">Date is invalid. Exact dates must be of format <i>mm/dd/yyyy</i>.</p>
</div>
<!-- Exact date checkbox -->
<div class="form-check float-end mt-1 mb-3">
  <label class="c-checkbox">
    <input type="checkbox" [(ngModel)]="exactDate" (ngModelChange)="emitFormatError()" />
    <span class="checkmark"></span>
    Exact date
  </label>
</div>
