import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService } from '../../auth0/services/user.service';
import { KycQuestionnaireComponent } from '../components/kyc-questionnaire/kyc-questionnaire.component';
import { KycAnswersDetails } from '../../../generated/KycAnswersDetails';
import { KYC_ANSWERS_DETAIL_QUERY, KYC_ANSWERS_SAVE_MUTATION } from '../fragments/kyc-answers';
import { kycAnswers } from '../../../generated/kycAnswers';
import { saveKycAnswers, saveKycAnswersVariables } from '../../../generated/saveKycAnswers';
import { KycStatus, SaveKycAnswersInput } from '../../../generated/globalTypes';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class KycAnswersService {

  private isActionInProgress = false;

  public constructor(
    private userService: UserService,
    private apollo: Apollo,
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.listenToQueryParamsForKycDialogParam();
    this.listenToUserChanges();
  }

  private listenToQueryParamsForKycDialogParam(): void {
    this.route.queryParams
      .subscribe((params) => {
        if (params.openKycDialog) {
          this.openKycQuestionnaireModal();
        }
      });
  }

  public openKycQuestionnaireModal(): void {
    this.isActionInProgress = true;
    this.getKycAnswers().subscribe((answers) => this.showKycQuestionnaireModal(answers));
  }

  public saveKycAnswers(value: SaveKycAnswersInput): Observable<KycAnswersDetails> {
    return this.apollo.mutate<saveKycAnswers, saveKycAnswersVariables>({
      mutation: KYC_ANSWERS_SAVE_MUTATION,
      variables: { value }
    }).pipe(map((result) => result.data!.saveKycAnswers));
  }

  public getKycAnswers(): Observable<KycAnswersDetails> {
    return this.apollo.query<kycAnswers>({
      query: KYC_ANSWERS_DETAIL_QUERY,
      fetchPolicy: 'network-only',
    })
      .pipe(
        map(result => result.data.kycAnswers)
      );
  }

  public isKycEnabled(): boolean {
    const status = this.userService.currentUser!.kycStatus;
    return status != null && status !== KycStatus.KYC_DISABLED;
  }

  private listenToUserChanges(): void {
    this.userService.currentUser$.subscribe((user) => {
      if (!user || this.isActionInProgress || !user.kycStatus || user.kycStatus === KycStatus.KYC_DISABLED) {
        return;
      }

      if (KycStatus.DENIED === user.kycStatus) {
        this.isActionInProgress = true;
        this.router.navigate(['user', 'locked']);
        return;
      }

      this.openKycQuestionnaireModalIfNecessary();
    });
  }

  private openKycQuestionnaireModalIfNecessary(): void {
    this.isActionInProgress = true;
    this.getKycAnswers().subscribe((answers) => {
      if (!answers.submitted || KycStatus.VETTING_REQUIRED === this.userService.currentUser!.kycStatus) {
        this.showKycQuestionnaireModal(answers);
      }
    });
  }

  private showKycQuestionnaireModal(answers: KycAnswersDetails): MatDialogRef<KycQuestionnaireComponent> {
    const shouldDisableCloseButton = KycStatus.VETTING_REQUIRED === this.userService.currentUser?.kycStatus;
    return this.dialog.open(KycQuestionnaireComponent, { data: answers, disableClose: shouldDisableCloseButton,  panelClass: 'app-full-dialog', minWidth: '700px'  });
  }
}
