import { gql } from 'apollo-angular';

export const THEME_FRAGMENT = gql`
  fragment Theme on Theme {
    id
    name
    logo
    headerColor
    headerLinkColor
    headerActiveLinkColor
    headerBorderColor
    primaryColor
    secondaryColor
  }
`;

export const THEMES_QUERY = gql`
  query themes {
    themes {
      ...Theme
    }
  }

  ${THEME_FRAGMENT}
`;
