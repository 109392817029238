import { MixpanelService } from './../../../../shared/services/mixpanel.service';
import { Component, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs';
import { TaskService } from '../../../../../app/user/services/task.service';
import { UpdateTaskInput } from '../../../../../generated/globalTypes';
import { TaskInfo } from '../../../../../generated/TaskInfo';
@Component({
  selector: 'complete-task',
  templateUrl: './complete-task.component.html',
  styleUrls: ['./complete-task.component.scss'],
})
export class CompleteTaskComponent {
  public task: TaskInfo | null;
  public isComplete: boolean;
  public isLoading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: { task: TaskInfo },
    private taskService: TaskService,
    private dialog: MatDialog,
    private messageService: ToastrService,
    private mixpanelService: MixpanelService
  ) {
    this.task = data.task;
    this.isComplete = this.task.isComplete;
  }

  public completeTask(): void {
    if (!this.task) {
      return;
    }

    const value: UpdateTaskInput = {
      isComplete: this.task.isComplete ? false : true,
    };

    this.isLoading = true;

    this.taskService
      .updateTask(this.task?.id, value)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.close();
        })
      )
      .subscribe({
        next: () => {
          this.messageService.success('Successfully updated Task');
          this.mixpanelService.trackAction('task-completion-success', {
            TaskType: this.task!.type,
          });
        },
          error: (e) => {
            this.messageService.error(`Failed to update Task<br/>${e.message}`);
            this.mixpanelService.trackAction('task-completion-failure', this.mixpanelService.parseGraphQlError(e));
          }
      });
  }

  private close(): void {
    this.dialog.closeAll();
  }
}
