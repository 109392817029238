import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IsSidebarDirtyService } from '../../services/is-sidebar-dirty.service';

@Component({
  selector: 'dirty-confirmation-modal',

  template: `
    <div class="modal-header">
      <h5 class="modal-title">Unsaved Changes</h5>
      <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close" (click)="close()">
      </button>
    </div>

    <div class="modal-body">
      <p>You have unsaved changes. Would you like to discard them?</p>
    </div>

    <div class="modal-footer">
      <button class="btn btn-secondary" type="button" (click)="close()">Cancel</button>
      <button class="btn btn-primary" type="submit" (click)="discard()">
        Discard
      </button>
    </div>
  `,
})
export class DirtyConfirmationModalComponent implements OnDestroy {
  public onClose = new Subject<boolean>();

  constructor(
    private bsModalRef: BsModalRef,
    private isSidebarDirtyService: IsSidebarDirtyService,
  ) {}

  ngOnDestroy(): void {
    this.onClose.next(this.shouldDiscard);
    this.onClose.complete();
  }

  private shouldDiscard = false;

  public close() {
    this.bsModalRef.hide();
  }

  public discard() {
    this.isSidebarDirtyService.isSidebarDirty = () => false;
    this.shouldDiscard = true;
    this.close();
  }
}
