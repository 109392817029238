import { map, Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { CREATE_RELATIONSHIP_STATUS_ADMIN, DELETE_RELATIONSHIP_STATUS_ADMIN, RELATIONSHIP_STATUSES_ADMIN, UPDATE_RELATIONSHIP_STATUS_ADMIN } from "./../fragments/relationship-status";
import { RelationshipStatusInput } from "../../../generated/globalTypes";
import { RelationshipStatus } from "../../../generated/RelationshipStatus";
import { createRelationshipStatusAdmin, createRelationshipStatusAdminVariables } from "../../../generated/createRelationshipStatusAdmin";
import { updateRelationshipStatusAdmin, updateRelationshipStatusAdminVariables } from "../../../generated/updateRelationshipStatusAdmin";
import { deleteRelationshipStatusAdmin, deleteRelationshipStatusAdminVariables } from "../../../generated/deleteRelationshipStatusAdmin";
import { relationshipStatusesAdmin } from "../../../generated/relationshipStatusesAdmin";

@Injectable({ providedIn: 'root' })
export class RelationshipStatusAdminService {

  constructor(private apollo: Apollo) {
  }

  public getRelationshipStatuses(forceReload = false): Observable<RelationshipStatus[]> {
    return this.apollo
      .query<relationshipStatusesAdmin>({
        query: RELATIONSHIP_STATUSES_ADMIN,
        fetchPolicy: forceReload ? 'network-only' : undefined,
      })
      .pipe(map((result) => result.data.relationshipStatusesAdmin));
  }

  public createRelationshipStatus(value: RelationshipStatusInput): Observable<RelationshipStatus> {
    return this.apollo
      .mutate<createRelationshipStatusAdmin, createRelationshipStatusAdminVariables>({
        mutation: CREATE_RELATIONSHIP_STATUS_ADMIN,
        variables: { value },
        fetchPolicy: 'network-only',
      })
      .pipe(map((result) => result.data!.createRelationshipStatusAdmin));
  }

  public updateRelationshipStatus(id: number, value: RelationshipStatusInput): Observable<RelationshipStatus> {
    return this.apollo
      .mutate<updateRelationshipStatusAdmin, updateRelationshipStatusAdminVariables>({
        mutation: UPDATE_RELATIONSHIP_STATUS_ADMIN,
        variables: { id, value },
        fetchPolicy: 'network-only',
      })
      .pipe(map((result) => result.data!.updateRelationshipStatusAdmin));
  }

  public deleteRelationshipStatus(id: number): Observable<RelationshipStatus> {
    return this.apollo
      .mutate<deleteRelationshipStatusAdmin, deleteRelationshipStatusAdminVariables>({
        mutation: DELETE_RELATIONSHIP_STATUS_ADMIN,
        variables: { id },
        fetchPolicy: 'network-only',
      })
      .pipe(map((result) => result.data!.deleteRelationshipStatusAdmin));
  }
}
