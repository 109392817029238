import { Injectable } from '@angular/core';
import { Apollo, gql, MutationResult, QueryRef } from 'apollo-angular';
import { getTasksByCase, getTasksByCaseVariables } from '../../../generated/getTasksByCase';
import { getTasksFiltered, getTasksFilteredVariables } from '../../../generated/getTasksFiltered';
import { createTask, createTaskVariables } from '../../../generated/createTask';
import { updateTask, updateTaskVariables } from '../../../generated/updateTask';
import { deleteTask, deleteTaskVariables } from '../../../generated/deleteTask';
import { countUnfinishedTasks } from '../../../generated/countUnfinishedTasks';
import { CreateTaskInput, UpdateTaskInput } from '../../../generated/globalTypes';
import { TaskInfo } from '../../../generated/TaskInfo';
import { DataProxy } from '@apollo/client/cache';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { ApolloQueryResult } from '@apollo/client/core';

export const TASK_INFO_FRAGMENT = gql`
  fragment PersonInfo on Person {
    id
    fullName
  }

  fragment CaseInfo on Case {
    id
    person {
      ...PersonInfo
    }
  }

  fragment RelationshipInfo on Relationship {
    id
    person {
      ...PersonInfo
    }
  }

  fragment TaskInfo on Task {
    id
    createdAt
    title
    description
    isComplete
    dueDate
    type
    case {
      ...CaseInfo
    }
    relationship {
      ...RelationshipInfo
    }
  }
`;

export const TASKS_BY_CASE_QUERY = gql`
  query getTasksByCase($caseId: Int!) {
    tasksByCase(caseId: $caseId) {
      ...TaskInfo
    }
  }
  ${TASK_INFO_FRAGMENT}
`;

export const TASKS_FILTERED_QUERY = gql`
  query getTasksFiltered($type: TaskType, $isComplete: Boolean, $searchValue: String) {
    tasks(type: $type, isComplete: $isComplete, searchValue: $searchValue) {
      ...TaskInfo
    }
  }
  ${TASK_INFO_FRAGMENT}
`;

export const CREATE_TASK_MUTATION = gql`
  mutation createTask($value: CreateTaskInput!) {
    createTask(value: $value) {
      ...TaskInfo
    }
  }
  ${TASK_INFO_FRAGMENT}
`;

export const UPDATE_TASK_MUTATION = gql`
  mutation updateTask($taskId: Int!, $value: UpdateTaskInput!) {
    updateTask(id: $taskId, value: $value) {
      ...TaskInfo
    }
  }
  ${TASK_INFO_FRAGMENT}
`;

export const DELETE_TASK_MUTATION = gql`
  mutation deleteTask($taskId: Int!) {
    deleteTask(id: $taskId) {
      ...TaskInfo
    }
  }
  ${TASK_INFO_FRAGMENT}
`;

export enum TaskType {
  CASE = 'CASE',
  PERSONAL = 'PERSONAL',
}

@Injectable({
  providedIn: 'root',
})
export class TaskService {
  public unfinishedTasks = new BehaviorSubject<boolean | undefined>(undefined);

  constructor(private apollo: Apollo) {}

  public updateUnfinishedTasks(data: boolean): void {
    this.unfinishedTasks.next(data);
  }

  public getTasksByCase(caseId: number): QueryRef<getTasksByCase, getTasksByCaseVariables> {
    return this.apollo.watchQuery<getTasksByCase, getTasksByCaseVariables>({
      query: TASKS_BY_CASE_QUERY,
      variables: { caseId },
    });
  }

  public getTasksFiltered(
    taskType?: TaskType,
    isComplete?: boolean,
    searchValue?: string
  ): QueryRef<getTasksFiltered, getTasksFilteredVariables> {
    return this.apollo.watchQuery<getTasksFiltered, getTasksFilteredVariables>({
      query: TASKS_FILTERED_QUERY,
      variables: { type: taskType, isComplete, searchValue },
      fetchPolicy: 'network-only'
    });
  }

  public hasUnfinishedTasks(forceReload?: boolean): Observable<boolean | undefined> {
    if (forceReload || this.unfinishedTasks.value == null) {
      this.getUnfinishedTasksCount().subscribe({
        next: (response) => {
          const userHasUnfinishedTasks = response.data.countUnfinishedTasks > 0;
          this.updateUnfinishedTasks(userHasUnfinishedTasks);
        }
      }); 
    }

    return this.unfinishedTasks;
  }

  public createTask(value: CreateTaskInput): Observable<MutationResult<createTask>> {
    return this.apollo.mutate<createTask, createTaskVariables>({
      mutation: CREATE_TASK_MUTATION,
      variables: { value },
      update: (cache, result) => {
        if (!result.data) return;
        this.addTaskToCache(result.data.createTask, cache);
      },
    }).pipe(tap(() => this.hasUnfinishedTasks(true)));
  }

  public updateTask(taskId: number, value: UpdateTaskInput): Observable<MutationResult<updateTask>> {
    return this.apollo.mutate<updateTask, updateTaskVariables>({
      mutation: UPDATE_TASK_MUTATION,
      variables: { taskId, value },
    }).pipe(tap(() => this.hasUnfinishedTasks(true)));
  }

  public deleteTask(taskId: number): Observable<MutationResult<deleteTask>> {
    return this.apollo.mutate<deleteTask, deleteTaskVariables>({
      mutation: DELETE_TASK_MUTATION,
      variables: { taskId },
      update: (cache, result) => {
        if (!result.data) return;
        this.deleteTaskFromCache(result.data.deleteTask, cache);
      },
    }).pipe(tap(() => this.hasUnfinishedTasks(true)));
  }

  private getUnfinishedTasksCount(): Observable<ApolloQueryResult<countUnfinishedTasks>> {
    return this.apollo.query<countUnfinishedTasks>({
      query: gql`
        query countUnfinishedTasks {
          countUnfinishedTasks
        }
      `,
      fetchPolicy: 'network-only'
    })
  }

  private addTaskToCache(task: TaskInfo, cache: DataProxy): void {
    const tasks = cache.readQuery<getTasksFiltered, getTasksFilteredVariables>({ query: TASKS_FILTERED_QUERY });
    if (!tasks) return;
    cache.writeQuery<getTasksFiltered, getTasksFilteredVariables>({
      query: TASKS_FILTERED_QUERY,
      data: { tasks: [task, ...tasks.tasks] },
    });
  }

  private deleteTaskFromCache(task: TaskInfo, cache: DataProxy): void {
    const tasks = cache.readQuery<getTasksFiltered, getTasksFilteredVariables>({ query: TASKS_FILTERED_QUERY });
    if (!tasks) return;
    cache.writeQuery<getTasksFiltered, getTasksFilteredVariables>({
      query: TASKS_FILTERED_QUERY,
      data: { tasks: tasks.tasks.filter((t) => t.id !== task.id) },
    });
  }
}
