import gql from 'graphql-tag';

export const KYC_ANSWERS_DETAIL_FRAGMENT = gql`
  fragment KycAnswersDetails on KycAnswers {
    id
    workTitle
    agency
    contactPersonName
    contactPersonContactForm
    plan
    proofOfWorkImage
    submitted
    changesRequested
    createdAt
    updatedAt
  }
`;

export const KYC_ANSWERS_DETAIL_QUERY = gql`
  query kycAnswers {
    kycAnswers {
      ...KycAnswersDetails
    }
  }

  ${KYC_ANSWERS_DETAIL_FRAGMENT}
`;


export const KYC_ANSWERS_SAVE_MUTATION = gql`
  mutation saveKycAnswers($value: SaveKycAnswersInput!) {
    saveKycAnswers(value: $value) {
      ...KycAnswersDetails
    }
  }

  ${KYC_ANSWERS_DETAIL_FRAGMENT}
`;
