import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { UserService } from '../../auth0/services/user.service';
import { UserFull } from '../../../generated/UserFull';
import { Roles } from '../../../generated/globalTypes';

export function roleLevel(role: Roles) {
  switch (role) {
    case Roles.MANAGER:
      return 4;
    case Roles.EDITOR:
      return 3;
    case Roles.VIEWER:
      return 2;
    case Roles.CASE_CREATOR:
      return 1;
    case Roles.NONE:
      return 0;
  }
}

interface UserPermissions {
  teamRoles: Map<number, Roles>;
  caseRoles: Map<number, Roles>;
}

function getUserPermissions(user: UserFull): UserPermissions | undefined {
  return {
    teamRoles: new Map(user.userTeams.map((u) => [u.team.id, u.role])),
    caseRoles: new Map(user.caseRoles.map((c) => [c.caseId, c.role])),
  };
}

@Injectable()
export class PermissionService implements OnDestroy {
  private userPermissions: UserPermissions | undefined;
  private roles = new Map<Roles, number>(); // maps from Roles to Role.level
  private subscription = new Subject<void>();

  constructor(userService: UserService) {
    userService.currentUser$.pipe(takeUntil(this.subscription)).subscribe((user) => {
      this.userPermissions = user ? getUserPermissions(user) : undefined;
    });
  }

  ngOnDestroy() {
    this.subscription.next();
    this.subscription.complete();
  }

  public getTeamRole(teamId: number): Roles | undefined {
    return this.userPermissions?.teamRoles.get(teamId);
  }

  public hasRole({ role, caseId, teamId }: { role: Roles; caseId?: number; teamId?: number }): boolean {
    // ensure user has been set
    if (!this.userPermissions) return false;

    // get role level from role
    const requestedLevel = roleLevel(role);
    if (requestedLevel === undefined) {
      console.warn('Unknown role', role);
      return false;
    }

    // first, check if role matches team level role, if it does, success
    if (teamId) {
      const teamRole = this.userPermissions.teamRoles.get(teamId);
      if (teamRole && requestedLevel <= roleLevel(teamRole)) {
        return true;
      }
    }

    // next, check if role matches for case level, if it does, success
    if (caseId) {
      const caseRole = this.userPermissions.caseRoles.get(caseId);
      if (caseRole && requestedLevel <= roleLevel(caseRole)) {
        return true;
      }
    }

    // not authorized
    return false;
  }
}
