import { NgModule, ErrorHandler, Injectable, APP_INITIALIZER } from '@angular/core';
import { CommonModule, TitleCasePipe, DatePipe } from '@angular/common';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';

import { CoreModule } from './core/core.module';
import { Auth0Module } from './auth0/auth.module';

import { routes } from './routes';

import { AppComponent } from './core/containers/app';
import { environment } from '@env/environment';
import { SmartBannerService } from './shared/services/smart-banner.service';

// Toast
import { ToastrModule, ToastNoAnimation, ToastNoAnimationModule } from 'ngx-toastr';

// Sentry
import * as Sentry from '@sentry/browser';

import { FormsModule } from '@angular/forms';
import { IntercomModule } from './intercom.module';
import { GraphQLModule } from './graphql.module';

import { CountdownModule } from 'ngx-countdown';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { REPOSITORY_VERSION } from '../generated/version';
import { DashboardModule } from './dashboard/dashboard.module';
import { LetsMFAModule } from 'lets-mfa-angular';
import { ErrorModule } from './error/error.module';
import { BillingModule } from './billing/billing.module';

// only register sentry non-localhost
if (window.location.hostname !== 'localhost') {
  // dsn is slightly modified
  // prepend o378845.ingest.sentry.io with ${window.location.host}/_sentry
  // to proxy through backend - this will help prevent ad-blockers from blocking
  Sentry.init({
    dsn: `https://e112232089a14d7684482b130864efa5@${window.location.host}/_sentry/o378845.ingest.sentry.io/5202872`,
    release: `family-connections-web-frontend@${environment.environmentName}-${REPOSITORY_VERSION}`,
    environment: environment.environmentName,
  });
}
const smartBannerInit = (smartBannerService: SmartBannerService) => () => smartBannerService.initSmartBanners();

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {}
  handleError(error: any) {
    // Uncomment the following line to enable console logs
    // console.error(error);
    Sentry.captureException(error.originalError || error);
  }
}

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    TabsModule.forRoot(),
    ToastNoAnimationModule,
    ToastrModule.forRoot({
      toastComponent: ToastNoAnimation,
      closeButton: false,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
      timeOut: 2000,
      extendedTimeOut: 2000,
      enableHtml: true,
    }),
    HttpClientModule,

    IntercomModule,
    RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always' }),

    CoreModule.forRoot(),

    Auth0Module.forRoot(),

    LetsMFAModule.forRoot(),

    GraphQLModule,
    CountdownModule,
    PerfectScrollbarModule,
    DashboardModule,
    FontAwesomeModule,
    ErrorModule
  ],
  providers: [
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    {
      provide: APP_INITIALIZER,
      useFactory: smartBannerInit,
      deps: [SmartBannerService],
      multi: true,
    },
    TitleCasePipe,
    DatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
