import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CreateTaskComponent } from './create-task/create-task.component';
import { DeleteTaskComponent } from './delete-task/delete-task.component';
import { CompleteTaskComponent } from './complete-task/complete-task.component';
import { TaskService, TaskType } from '../../services/task.service';
import { UserService } from '../../../../app/auth0/services/user.service';
import { getTasksFiltered_tasks } from '../../../../generated/getTasksFiltered';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { MixpanelService } from './../../../shared/services/mixpanel.service';

enum SortOptions {
  DueDateAscending,
  DueDateDescending,
  CreatedDateAscending,
  CreatedDateDescending,
}

interface SortBy {
  name: string;
  value: SortOptions;
}

@Component({
  selector: 'tasks',
  templateUrl: './user-tasks.component.html',
  styleUrls: ['./user-tasks.component.scss'],
})
export class UserTasksComponent implements OnInit {
  public isSelected: boolean = true;
  public taskTypes = TaskType;
  public taskCounts = new Map<string, number>();

  private _taskType: TaskType | undefined;
  get taskType() {
    return this._taskType;
  }
  set taskType(value: TaskType | undefined) {
    this._taskType = value;
    this.applyFilters();
  }

  private _taskCompleted: boolean | undefined;
  get taskCompleted() {
    return this._taskCompleted;
  }
  set taskCompleted(value: boolean | undefined) {
    this._taskCompleted = value;
    this.applyFilters();
  }

  private _searchTerm: string;
  set searchTerm(value: string | undefined) {
    this._searchTerm = value ? value.trim().toLocaleLowerCase() : '';
    this.applyFilters();
  }

  private _sortBy: SortBy | undefined;
  set sortBy(value: SortBy | undefined) {
    this._sortBy = value ?? undefined;
    this.applyFilters();
  }

  public sortingItems$ = [
    {
      name: 'Due Date (Ascending)',
      value: SortOptions.DueDateAscending,
    },
    {
      name: 'Due Date (Descending)',
      value: SortOptions.DueDateDescending,
    },
    {
      name: 'Created Date (Ascending)',
      value: SortOptions.CreatedDateAscending,
    },
    {
      name: 'Created Date (Descending)',
      value: SortOptions.CreatedDateDescending,
    },
  ];

  public isLoading: boolean;
  public userName: string;
  public tasks: getTasksFiltered_tasks[] = [];
  public allTasks: getTasksFiltered_tasks[] = [];

  constructor(
    public dialog: MatDialog,
    private taskService: TaskService,
    private userService: UserService,
    private messageService: ToastrService,
    private mixpanelService: MixpanelService,
  ) {}

  ngOnInit(): void {
    this.userName = this.userService.currentUser?.firstName || '';
    this.isLoading = true;
    this.taskService.getTasksFiltered().valueChanges.subscribe((result) => {
      this.allTasks = result.data.tasks;
      this.countTasks();
      this.applyFilters();
    });
  }

  public openAddNewTaskModal(): void {
    this.mixpanelService.trackAction('Task-add-new-task');
    this.dialog.open(CreateTaskComponent, { data: { task: null }, width: '650px', panelClass: 'user-task' });
  }

  public openDeleteTaskModal(): void {
    this.dialog.open(DeleteTaskComponent, { width: '500px', panelClass: 'delete-task' });
  }

  public openCompleteTaskModal(): void {
    this.dialog.open(CompleteTaskComponent, { width: '500px', panelClass: 'complete-task' });
  }

  private applyFilters(): void {
    this.isLoading = true;
    this.taskService.getTasksFiltered(this._taskType, this._taskCompleted, this._searchTerm).valueChanges.subscribe({
      next: (result) => {
        this.tasks = result.data.tasks;
        this.sortTasks();
        this.isLoading = false;
      },
      error: (e) => {
        this.messageService.error(`Failed to get tasks<br/>${e.message}`);
        this.isLoading = false;
      }
    });
  }

  private sortTasks(): void {
    let sortedTasks = this.tasks;
    if (this._sortBy) {
      switch (this._sortBy.value) {
        case SortOptions.DueDateAscending:
          sortedTasks = _.orderBy(
            this.tasks,
            (task) => {
              return moment(task.dueDate);
            },
            'asc'
          );
          break;
        case SortOptions.DueDateDescending:
          sortedTasks = _.orderBy(
            this.tasks,
            (task) => {
              return moment(task.dueDate);
            },
            'desc'
          );
          break;
        case SortOptions.CreatedDateAscending:
          sortedTasks = _.orderBy(
            this.tasks,
            (task) => {
              return moment(task.createdAt);
            },
            'asc'
          );
          break;
        case SortOptions.CreatedDateDescending:
          sortedTasks = _.orderBy(
            this.tasks,
            (task) => {
              return moment(task.createdAt);
            },
            'desc'
          );
          break;
        default:
          break;
      }
    }
    this.tasks = sortedTasks;
  }

  private countTasks(): void {
    this.taskCounts['TypeAll'] = this.allTasks.length;
    this.taskCounts['TypeCase'] = this.allTasks.filter((t) => t.type === TaskType.CASE).length;
    this.taskCounts['TypePersonal'] = this.allTasks.filter((t) => t.type === TaskType.PERSONAL).length;

    this.taskCounts['StatusAll'] = this.allTasks.length;
    this.taskCounts['StatusActive'] = this.allTasks.filter((t) => !t.isComplete).length;
    this.taskCounts['StatusComplete'] = this.allTasks.filter((t) => t.isComplete).length;
  }
}
