import { Component, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs';
import { TaskInfo } from '../../../../../generated/TaskInfo';
import { TaskService } from '../../../services/task.service';

@Component({
  selector: 'delete-task',
  templateUrl: './delete-task.component.html',
  styleUrls: ['./delete-task.component.scss'],
})
export class DeleteTaskComponent {
  public task: TaskInfo | null;
  public isLoading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: { task: TaskInfo },
    private taskService: TaskService,
    private dialog: MatDialog,
    private messageService: ToastrService
  ) {
    this.task = data.task;
  }

  public deleteTask(): void {
    if (!this.task) {
      return;
    }

    this.isLoading = true;

    this.taskService
      .deleteTask(this.task.id)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.close();
        })
      )
      .subscribe({
        next: () => this.messageService.success('Successfully deleted Task'),
        error: (e) => this.messageService.error(`Failed to delete Task<br/>${e.message}`),
      });
  }

  private close(): void {
    this.dialog.closeAll();
  }
}
