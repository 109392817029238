import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name: 'birthday' })
export class birthdayPipe implements PipeTransform {
  transform(date: string) {
    let dateArray: any[] = [];
    if (!date || typeof date !== 'string') return '';
    if (date.includes('-')) {
      dateArray = date.split('-');
    } else if (date.includes('/')) {
      dateArray = date.split('/');
    } else {
      dateArray = [date];
    }

    if (dateArray.length === 3) {
      return moment(date, 'YYYY/MM/DD').format('MMM Do YYYY');
    }

    if (dateArray.length === 2) {
      if (dateArray[0].length === 4) {
        return moment(date, 'YYYY/MM').format('MMM YYYY');
      } else if (dateArray[0].length === 2) {
        return moment(date, 'MM/DD').format('Do MMM');
      }
    }

    if (dateArray.length === 1) {
      if (dateArray[0].length === 4) {
        return moment(date, 'YYYY').format('YYYY');
      } else if (dateArray[0].length === 2) {
        return moment(date, 'MM').format('MMMM');
      }
    }
    return '';
  }
}
