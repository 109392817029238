import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { Observable, ReplaySubject, Subject, catchError, first, iif, map, switchMap, take, tap } from "rxjs";
import { BillingCreditsBundle } from "../../../generated/BillingCreditsBundle";
import { ACTIVATE_TEAM_BUNDLE, ACTIVATE_USER_BUNDLE, GET_ALL_BUNDLES_OF_USER } from "../fragments/billing-credits-bundle";
import { billingCreditBundles } from "../../../generated/billingCreditBundles";
import { activateUserBillingCreditsBundle, activateUserBillingCreditsBundleVariables } from "../../../generated/activateUserBillingCreditsBundle";
import { activateTeamBillingCreditsBundle, activateTeamBillingCreditsBundleVariables } from "../../../generated/activateTeamBillingCreditsBundle";

export interface BillingCreditsBundleWithMetadata extends BillingCreditsBundle {
  active: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class CreditBundleService {
  private bundlesOfUser: Subject<BillingCreditsBundle[]> = new ReplaySubject(1);
  private bundlesLoaded: boolean;

  constructor(private apollo: Apollo) {
  }

  public getBillingCreditBundlesOfUser(forceReload?: boolean): Observable<BillingCreditsBundle[]> {
    if (this.bundlesLoaded && !forceReload) {
      return this.bundlesOfUser.asObservable()
    }

    return this.loadUserBillingCreditsBundles();
  }

  public reloadBillingCreditsBundleOfUser(): void {
    this.reloadBillingCreditsForUser().subscribe();
  }

  public activateBundle(bundle: BillingCreditsBundle): Observable<any> {
    const userOwnded = bundle.userId ? true : false;
    return iif(
      () => userOwnded,
      this.activatePersonalAccountBundle(bundle.id),
      this.activateBillingAccountBundle(bundle.id, bundle.teamId!)
    )
      .pipe(
        switchMap((bundle) =>
          this.reloadBillingCreditsForUser()
            .pipe(
              first(),
              map(() => bundle)
            )
        ));
  }

  private reloadBillingCreditsForUser() {
    return this.getBillingCreditBundlesOfUser(true).pipe(take(1));
  }

  private activatePersonalAccountBundle(bundleId: number): Observable<any> {
    return this.apollo.mutate<activateUserBillingCreditsBundle, activateUserBillingCreditsBundleVariables>({
      mutation: ACTIVATE_USER_BUNDLE,
      fetchPolicy: 'network-only',
      variables: { bundleId }
    }).pipe(
      map(response => response.data!.activateUserBillingCreditsBundle),
    );
  }

  private activateBillingAccountBundle(bundleId: number, teamId: number): Observable<any> {
    return this.apollo.mutate<activateTeamBillingCreditsBundle, activateTeamBillingCreditsBundleVariables>({
      mutation: ACTIVATE_TEAM_BUNDLE,
      fetchPolicy: 'network-only',
      variables: { teamId, bundleId }
    }).pipe(
      map(response => response.data!.activateTeamBillingCreditsBundle),
    );
  }

  private loadUserBillingCreditsBundles(): Observable<BillingCreditsBundle[]> {
    this.bundlesLoaded = true;

    return this.apollo.query<billingCreditBundles>({
      query: GET_ALL_BUNDLES_OF_USER,
      fetchPolicy: 'network-only'
    }).pipe(
      map(response => [...response.data!.billingCreditBundles]),
      catchError(() => []),
      tap(bundles => this.bundlesOfUser.next(bundles)),
      switchMap(() => this.bundlesOfUser.asObservable())
    );
  }
}
