import { Component, Input } from '@angular/core';

import { dashboardCases_dashboardCases, dashboardCases_dashboardCases_case } from '../../../../../generated/dashboardCases';
import { dashboardMembers_dashboardMembers, dashboardMembers_dashboardMembers_teamMember } from '../../../../../generated/dashboardMembers';

export function getCaseStatusPercent(status?: string | null) {
  switch (status) {
    case 'New':
      return 100;
    case 'Discovery' || 'Discover':
      return 87.5;
    case 'Engagement':
      return 62.5;
    case 'Promising Placement':
      return 37.5;
    case 'Finalization':
      return 12.5;
    default:
      return 0;
  }
}

@Component({
  selector: 'cok-activity-person',
  templateUrl: './activity-person.component.html',
  styleUrls: ['./activity-person.component.scss'],
})
export class ActivityPersonComponent {

  public status: string | undefined;

  public teamMember: dashboardMembers_dashboardMembers_teamMember;
  public case: dashboardCases_dashboardCases_case;

  private _data: dashboardMembers_dashboardMembers | dashboardCases_dashboardCases;

  @Input()
  public set data(value: dashboardMembers_dashboardMembers | dashboardCases_dashboardCases) {
    if ('teamMember' in value) {
      this.teamMember = value.teamMember!;
    }

    if ('case' in value) {
      this.status = value.case?.caseStatus.name;
      this.case = value.case!;
    }

    this._data = value;
  }

  public get data() {
    return this._data;
  }

  public get person() {
    return this.teamMember || this.case.person;
  }

  public get name() {
    return this.teamMember?.name || this.case?.person.fullName;
  }

  public get firstName() {
    return this.teamMember.firstName || this.case.person.firstName;
  }

  public get caseFileNumber() {
    return this.case.caseFileNumber;
  }

  public get num() {
    return getCaseStatusPercent(this.status);
  }
}
