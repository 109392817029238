import { Injectable } from '@angular/core';
import { CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';

export const UNSUPPORTED_PATH = 'unsupported';
const UNSUPPORTED_URL = `/${UNSUPPORTED_PATH}`;

@Injectable({
  providedIn: 'root',
})
export class BrowserSupportedGuard implements CanActivateChild {
  constructor(private router: Router) {}

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    // the newest version of Edge that is based on Chromium and is supported reports as 'Edg' and thus passes this check
    const userAgent = window.navigator.userAgent;
    const isInternetExplorer = /MSIE|Trident|Edge/.test(userAgent);
    const isSupported = !isInternetExplorer;

    if (state.url === UNSUPPORTED_URL) {
      // do not navigate to unsupported page when browser is supported - instead route to /
      return isSupported ? this.router.parseUrl('/') : true;
    }

    return isSupported ? true : this.router.parseUrl(UNSUPPORTED_URL);
  }
}
