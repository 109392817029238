import { Pipe, PipeTransform } from '@angular/core';

// https://github.com/angular/angular/issues/16982#issuecomment-589780497
@Pipe({
  name: 'default',
})
export class DefaultPipe implements PipeTransform {
  constructor() {}

  transform<T>(value: T | null | undefined, defaultValue: T): T {
    return value ?? defaultValue;
  }
}
