export const loadFile = (file: Blob): Promise<FileReader> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = (err) => reject(err);
    reader.onload = (_) => resolve(reader);
    reader.readAsDataURL(file);
  });
};

export const loadImage = (src: string): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onerror = (err) => reject(err);
    img.onload = (_) => resolve(img);
    img.src = src;
  });
};

export const toBlob = (canvas: HTMLCanvasElement): Promise<Blob> => {
  return new Promise((resolve, reject) => {
    canvas.toBlob(
      (blob) => {
        if (!blob) return reject(new Error('Failed to generate blob'));
        resolve(blob);
      },
      'image/jpg',
      0.75
    );
  });
};

const MAX_DIMENSION_PX = 500;
export const reduceFileSize = async (originalFile: File, image: HTMLImageElement): Promise<File> => {
  const maxSize = Math.max(image.naturalWidth, image.naturalHeight);
  if (maxSize <= MAX_DIMENSION_PX) return originalFile;

  const canvas = document.createElement('canvas');
  if (image.naturalWidth > image.naturalHeight) {
    canvas.width = MAX_DIMENSION_PX;
    canvas.height = (canvas.width * image.naturalHeight) / image.naturalWidth;
  } else {
    canvas.height = MAX_DIMENSION_PX;
    canvas.width = (canvas.height * image.naturalWidth) / image.naturalHeight;
  }
  const ctx = canvas.getContext('2d')!;
  ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

  const blob = await toBlob(canvas);
  return new File([blob], 'profile-picture.png');
}
