import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'cok-prevent-screen',
  templateUrl: './prevent-screen.component.html',
  styleUrls: ['./prevent-screen.component.scss'],
})
export class PreventScreenComponent {
  public innerWidth: any;
  public isMobileScreen: boolean = false;

  constructor() {}
  ngOnInit() {
    this.innerWidth = window.innerWidth;
    this.isMobileScreen = this.isMobile();
  }

  @HostListener('window:resize', [])
  onResize() {
    this.innerWidth = window.innerWidth;
    this.isMobileScreen = this.isMobile();
  }

  private isTablet() {
    return /\b(iPad|tablet)\b/i.test(navigator.userAgent);
  }

  private isMobile() {
    return !this.isTablet() && /\b(Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone)\b/i.test(navigator.userAgent);
  }
}
