import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { UserService } from '../../../../app/auth0/services/user.service';
import { KycStatus, SaveKycAnswersInput } from '../../../../generated/globalTypes';
import { KycAnswersDetails } from '../../../../generated/KycAnswersDetails';
import { KycAnswersService } from '../../services/kyc-answers.service';
import { VettedUserOrganizationRole, VETTED_USER_ORGANIZATION_ROLE_OPTIONS } from './types';

@Component({
  selector: 'cok-kyc-questionnaire',
  templateUrl: './kyc-questionnaire.component.html',
  styleUrls: ['./kyc-questionnaire.component.scss']
})
export class KycQuestionnaireComponent implements OnInit, AfterViewInit {

  public readonly VettedUserOrganizationRole = VettedUserOrganizationRole;
  public readonly VETTED_USER_ORGANIZATION_ROLE_OPTIONS = VETTED_USER_ORGANIZATION_ROLE_OPTIONS;

  @ViewChild(MatStepper)
  public stepper: MatStepper;

  public selectedRoleOption = VettedUserOrganizationRole.WWK_RECRUITER;
  public otherRole: string;

  public form: FormGroup;

  public vettedUser: boolean = true;
  public limitedAccess: boolean = false;
  public vettingRequired: boolean = false;

  public currentKycStatus: KycStatus;
  public isVerifyingByPicture = false;

  public constructor(
    @Inject(MAT_DIALOG_DATA) public answers: KycAnswersDetails,
    private formBuilder: FormBuilder,
    private router: Router,
    private dialogRef: MatDialogRef<KycQuestionnaireComponent>,
    private kycAnswersService: KycAnswersService,
    private userService: UserService
  ) {
  }

  public ngOnInit(): void {
    this.form = this.formBuilder.group({
      workTitle: [this.answers.workTitle],
      agency: [this.answers.agency],
      contactPersonName: [this.answers.contactPersonName],
      contactPersonContactForm: [this.answers.contactPersonContactForm],
      plan: [this.answers.plan],
      proofOfWorkImage: null
    });

    this.currentKycStatus = this.parseKycStatus(this.userService.currentUser!.kycStatus || '');
    this.updateSelectedRoleValue();
    this.updateKycFields();
  }

  public ngAfterViewInit(): void {
      this.moveToTheRightPage();
  }

  public get isVettingNotRequired(): boolean {
    return this.userService.currentUser!.kycStatus !== KycStatus.VETTING_REQUIRED;
  }

  public get shouldDisableConfirmButton(): boolean {
    if (this.vettedUser) {
      return this.shouldDisableConfirmButtonForVettedUser();
    }

    return false;
  }

  public confirm(): void {
    const kycAnswers = this.form.value;
    if (kycAnswers.proofOfWorkImage) {
      kycAnswers.proofOfWorkImage = { val: kycAnswers.proofOfWorkImage };
    }

    if (VettedUserOrganizationRole.OTHER !== this.selectedRoleOption) {
      kycAnswers.workTitle = this.selectedRoleOption;
    } else {
      kycAnswers.workTitle = this.otherRole;
    }

    this.saveKycAnswers(kycAnswers);
  }

  public goToProfileVerification(): void {
    this.router.navigate(['user', 'verification']);
    this.exitModal();
  }

  public exitModal(): void {
    this.dialogRef.close();
  }

  private updateSelectedRoleValue(): void {
    if (!this.answers.workTitle) {
      return;
    }

    this.selectedRoleOption = VettedUserOrganizationRole[this.answers.workTitle];
    if (!this.selectedRoleOption) {
      this.selectedRoleOption = VettedUserOrganizationRole.OTHER;
      this.otherRole = this.answers.workTitle;
    }
  }

  private shouldDisableConfirmButtonForVettedUser(): boolean {
    if (VettedUserOrganizationRole.OTHER === this.selectedRoleOption) {
      return !this.otherRole || this.otherRole.trim().length === 0;
    }

    return false;
  }

  private parseKycStatus(status: string): KycStatus {
    return KycStatus[status];
  }

  private updateKycFields(): void {
    switch(this.currentKycStatus) {
      case KycStatus.VETTED:
        this.vettedUser = true;
        this.limitedAccess = false;
        this.vettingRequired = false;
        return;
      case KycStatus.LIMITED_ACCESS:
        this.vettedUser = false;
        this.limitedAccess = true;
        this.vettingRequired = false;
      return;
      case KycStatus.VETTING_REQUIRED:
        this.vettedUser = false;
        this.limitedAccess = false;
        this.vettingRequired = true;
      return;
    }
  }

  private moveToTheRightPage(): void {
    if (!this.answers.submitted) {
      return;
    }

    if (KycStatus.VETTING_REQUIRED === this.userService.currentUser!.kycStatus) {
      setTimeout(() => this.stepper.selectedIndex = 2, 1000);
    }
  }

  private saveKycAnswers(value: SaveKycAnswersInput): void {
    this.kycAnswersService.saveKycAnswers(value).subscribe(() => this.stepper.next());
  }
}
