import { Injectable } from "@angular/core";
import FamilySearch from 'fs-js-lite';
import { environment } from '@env/environment';

export function openFamilySearchPersonDetailPage(fsPersonID: string) {
  if (!fsPersonID) return;
  const url = `${environment.familySearch.base}/tree/person/details/${fsPersonID}`;
  window.open(url, '_blank');
}

@Injectable()
export class FamilySearchService {
  public fs: any = null;

  constructor() {
    const origin = window.location.origin;

    // See https://github.com/FamilySearch/fs-js-lite#initialization-options
    this.fs = new FamilySearch({
      environment: environment.familySearch.environment,
      appKey: environment.familySearch.apiKey,
      redirectUri: `${origin}/callback/familysearch`,

      saveAccessToken: true,
      tokenCookie: 'FS_AUTH_TOKEN',
      // Path value of the access token cookie.
      // Defaults to the current path (which is probably not what you want).
      tokenCookiePath: '/',
    });
  }
}