import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'ucword' })
export class UcwordPipe implements PipeTransform {
  transform(value: any) {
    if (value) {
      return value.replace(/\b\w/g, (symbol: string) => symbol.toLocaleUpperCase());
    }

    return value;
  }
}
