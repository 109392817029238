import { Inject, Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { THEMES_QUERY } from "../fragments/themes";
import { themes } from "../../../generated/themes";
import { map, Observable, ReplaySubject, take, tap } from "rxjs";
import { Theme } from "../../../generated/Theme";
import { DOCUMENT } from "@angular/common";

@Injectable({ providedIn: 'root' })
export class ThemeService {

  private readonly THEME_STORAGE_KEY = 'SELECTED_THEME_ID';
  private readonly themes = new ReplaySubject<Theme[]>(1);
  private readonly selectedTheme = new ReplaySubject<Theme>(1);
  private readonly DEFAULT_THEME: Theme;
  private loadedThemesInitially = false;

  constructor(
    private apollo: Apollo,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.DEFAULT_THEME = this.buildDefaultTheme();
    this.listenForSelectedThemeChanges();
  }

  public getThemes(): Observable<Theme[]> {
    if (!this.loadedThemesInitially) {
      this.loadThemes();
      this.loadedThemesInitially = true;
    }

    return this.themes;
  }

  public getSelectedTheme(): Observable<Theme> {
    if (!this.loadedThemesInitially) {
      this.getThemes()
        .pipe(take(1))
        .subscribe((themes) => this.loadSelectedTheme(themes));
    }

    return this.selectedTheme;
  }

  public setSelectedTheme(theme: Theme): void {
    this.selectedTheme.next(theme);
  }

  private loadThemes(): void {
    this.apollo.query<themes>({
      query: THEMES_QUERY,
      fetchPolicy: 'network-only'
    }).pipe(
      map((response) => response.data!.themes || []),
      tap((themes) => {
        const themesIncludingDefaultTheme = [this.DEFAULT_THEME, ...themes];
        this.themes.next(themesIncludingDefaultTheme);
      })
    ).subscribe();
  }

  private loadSelectedTheme(themes: Theme[]): void {
    let theme;

    const themeId = this.getStoredThemeId();
    if (themeId) {
      theme = themes.find((theme) => theme.id === themeId);
    }
    
    if (!theme) {
      const hasAnyCustomThemes = themes.length > 1;
      theme = hasAnyCustomThemes ? themes[1] : this.DEFAULT_THEME;
    }

    this.setSelectedTheme(theme);
  }

  private getStoredThemeId(): number {
    const themeId = localStorage.getItem(this.THEME_STORAGE_KEY);
    return Number(themeId);
  }

  private listenForSelectedThemeChanges(): void {
    this.selectedTheme.subscribe((theme) => {
      localStorage.setItem(this.THEME_STORAGE_KEY, theme.id.toString());
      this.setCssPropertiesFromTheme(theme);
    })
  }

  private setCssPropertiesFromTheme(theme: Theme): void {
    this.document.documentElement.style.setProperty('--header-color', theme.headerColor);
    this.document.documentElement.style.setProperty('--header-link-color', theme.headerLinkColor);
    this.document.documentElement.style.setProperty('--header-active-link-color', theme.headerActiveLinkColor);
    this.document.documentElement.style.setProperty('--header-border-color', theme.headerBorderColor);
    this.document.documentElement.style.setProperty('--color-primary', theme.primaryColor);
    this.document.documentElement.style.setProperty('--color-secondary', theme.secondaryColor);
  }

  private buildDefaultTheme(): Theme {
    return {
      id: -1,
      name: "Default",
      logo: null,
      headerColor: this.document.documentElement.style.getPropertyValue('--header-color'),
      headerLinkColor: this.document.documentElement.style.getPropertyValue('--header-link-color'),
      headerActiveLinkColor: this.document.documentElement.style.getPropertyValue('--header-active-link-color'),
      headerBorderColor: this.document.documentElement.style.getPropertyValue('--header-border-color'),
      primaryColor: this.document.documentElement.style.getPropertyValue('--color-primary'),
      secondaryColor: this.document.documentElement.style.getPropertyValue('--color-secondary'),
    } as Theme;
  }
}
