import { gql } from "apollo-angular";

export const RELATIONSHIP_STATUS_ADMIN = gql`
  fragment RelationshipStatus on RelationshipStatus {
    id
    name
    colorBlind
    color
    notes
    sortOrder
    isTemplate
  }
`;

export const RELATIONSHIP_STATUSES_ADMIN = gql`
  query relationshipStatusesAdmin {
    relationshipStatusesAdmin {
      ...RelationshipStatus
    }
  }

  ${RELATIONSHIP_STATUS_ADMIN}
`;

export const CREATE_RELATIONSHIP_STATUS_ADMIN = gql`
  mutation createRelationshipStatusAdmin($value: RelationshipStatusInputAdmin!) {
    createRelationshipStatusAdmin(value: $value) {
      ...RelationshipStatus
    }
  }

  ${RELATIONSHIP_STATUS_ADMIN}
`;

export const UPDATE_RELATIONSHIP_STATUS_ADMIN = gql`
  mutation updateRelationshipStatusAdmin($id: Int!, $value: RelationshipStatusInputAdmin!) {
    updateRelationshipStatusAdmin(id: $id, value: $value) {
      ...RelationshipStatus
    }
  }

  ${RELATIONSHIP_STATUS_ADMIN}
`;

export const DELETE_RELATIONSHIP_STATUS_ADMIN = gql`
  mutation deleteRelationshipStatusAdmin($id: Int!) {
    deleteRelationshipStatusAdmin(id: $id) {
      ...RelationshipStatus
    }
  }

  ${RELATIONSHIP_STATUS_ADMIN}
`;

export const COPY_RELATIONSHIP_STATUS_TO_TEAM_ADMIN = gql`
  mutation copyRelationshipStatusToTeamAdmin($id: Int!, $teamId: Int!) {
    copyRelationshipStatusToTeamAdmin(id: $id, teamId: $teamId) {
      ...RelationshipStatus
    }
  }

  ${RELATIONSHIP_STATUS_ADMIN}
`;
