import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { UserService } from '../services/user.service';

@Injectable()
export class TeamGuard implements CanActivate {
  constructor(private userService: UserService, public router: Router) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    try {
      const user = await this.userService.ensureUser();
      // TODO: change to check if user contains desired time based on param
      if (user.userTeams.length === 0) {
        return this.router.parseUrl(user.isSiteAdmin ? '/admin' : '/no-team');
      }
    } catch (err) {
      console.error(err);
      return false;
    }

    return true;
  }
}
