import { NgModule } from '@angular/core';

import { AddCommasPipe } from './add-commas';
import { EllipsisPipe } from './ellipsis';
import { UcwordPipe } from './ucword';
import { FileIconPipe } from './file-icon';
import { PrintCasePipe } from './print-case';
import { TimeFormatPipe } from './time-format.pipe';
import { birthdayPipe } from './birthday';
import { googleMapsUrlPipe } from './google-map-url';
import { AsUrlPipe } from './as-url';
import { SelectPipe } from './select';
import { AvatarPipe } from './avatar.pipe';
import { TextAreaPipe } from './textarea.pipe';
import { DefaultPipe } from './default';
import {ReplacePipe} from "./replace";

export const PIPES = [
  AddCommasPipe,
  EllipsisPipe,
  UcwordPipe,
  FileIconPipe,
  PrintCasePipe,
  TimeFormatPipe,
  birthdayPipe,
  googleMapsUrlPipe,
  AsUrlPipe,
  SelectPipe,
  AvatarPipe,
  TextAreaPipe,
  ReplacePipe,
  DefaultPipe,
];

@NgModule({
  providers: PIPES,
  declarations: PIPES,
  exports: PIPES,
})
export class PipesModule {}
